/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Typography, Container, Box } from "@mui/material";
import Footer from "../components/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "../config/Axios";
import { useCustomer } from "../context/customerContext";
import Loader from "../components/Loader";
import OtpInput from "react-otp-input";
import LayoutStyle from "../scss/base.module.scss";
import GainLogo from "../svg/Gainlogo";
import { CustomerAuthentication } from "../services/VerificationServices";

function TwoFactorLogin() {
  const { setToken, customer, setCustomer, sendOtp, getCustomer } =
    useCustomer();
  const navigate = useNavigate();
  const location = useLocation();
  const stateDetails = location?.state;
  const { type } = useParams();
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(false);
  const [loggingWithPin, setLoggingWithPin] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendEnabled(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  const handleResendClick = () => {
    setResendEnabled(false);
    setResendTimeout(true);
    setOtp("");
    setError("");
    setTimeout(() => {
      setResendEnabled(true);
      setResendTimeout(false);
    }, 60000);
    handleSendOtp();
  };

  const handleSubmit = () => {
    setLoading(true);
    setError("");
    if (loggingWithPin) {
      Axios()
        .post("/verify-Pin", {
          idNumber: customer?.customer_id_number,
          pin: pin,
        })
        .then((res) => {
          handleAuthenticateCustomer(type);
        })
        .catch((e) => {
          setLoading(false);
          setError(e?.response?.data?.response?.message);
        });
    } else {
      Axios()
        .post("/verifyOtp", {
          idNumber: customer?.customer_id_number,
          otp: otp,
        })
        .then(async (res) => {
          handleAuthenticateCustomer(type);
        })
        .catch((e) => {
          setLoading(false);
          setError(e?.response?.data?.response?.message);
        });
    }

    // if(pin === )
  };

  const handleAuthenticateCustomer = async (redirectPropType) => {
    let cust = {};
    let data = customer;
    data.enable_2fa = true;
    const verifyCustomer = await CustomerAuthentication(data);
    setToken(verifyCustomer?.token);
    cust = await getCustomer(verifyCustomer?.response?.customer_external_id);
    cust.enable_2fa = verifyCustomer?.response?.enable_2fa;
    cust.not_first_time = verifyCustomer?.response?.not_first_time;
    cust.enablePin = verifyCustomer?.response?.enable_pin;
    if (!cust.CustomerKey) {
      cust.CustomerKey = {};
    }
    cust.CustomerKey.publicKey = verifyCustomer?.publicKey;
    cust.show_guidance_first_time =
      verifyCustomer?.response?.show_guidance_first_time;
    setCustomer({ ...(verifyCustomer?.response || {}), ...(cust || {}) });
    setLoading(false);
    if (data?.contactlessId) {
      navigate(`/goods-services/${data?.contactlessId}`, { replace: true });
    } else if (stateDetails?.data?.storeExternalId) {
      navigate(`/goods-services-qr/${stateDetails?.data?.storeExternalId}`, {
        replace: true,
      });
    } else if (stateDetails?.data?.customerExtId) {
      navigate(`/transfer-money/${stateDetails?.data?.customerExtId}`);
    } else {
      navigate("/dashboard", { replace: true });
    }
  };
  function modifyErrorMessage(errorMessage) {
    const newErrorMessage = errorMessage.replace(
      "Invalid 'To' Phone Number:",
      "Invalid Phone Number:",
    );
    return newErrorMessage;
  }
  const handleSendOtp = () => {
    setLoading(true);
    Axios()
      .post("/create-Otp", {
        idNumber: customer?.customer_id_number,
        ph: customer?.phone,
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        const newErrorMessage = modifyErrorMessage(e?.response?.data?.message);
        setError(newErrorMessage || e?.message || "Something went wrong.");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (sendOtp && !loggingWithPin) {
      handleSendOtp();
    }
  }, [loggingWithPin]);

  return (
    <>
      {loading && <Loader show={loading} />}

      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.loginContainer}`}
        justifyContent="center"
      >
        <Container>
          <Box component={"div"} className={`${LayoutStyle.BrandLogo}`} mb={8}>
            <GainLogo />
          </Box>
          <Typography variant="h3" textAlign={"center"}>
            {loggingWithPin ? "Enter PIN" : "Enter OTP"}
          </Typography>

          <Box mt={5} mb={2}>
            <OtpInput
              value={loggingWithPin ? pin : otp}
              onChange={loggingWithPin ? setPin : setOtp}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} type={"password"} />}
              containerStyle={{
                justifyContent: "space-between",
              }}
              inputStyle={{
                backgroundColor: "#F7F7F7",
                width: "3.5rem",
                height: "3.5rem",
                fontWeight: 700,
                fontSize: "1rem",
                borderRadius: "0.5rem",
                border: "1px solid rgba(0,104,255,.15)",
              }}
              inputType={"number"}
            />
            {error && (
              <>
                <Box className="clear10" />
                <Typography
                  variant="body2"
                  color={"error"}
                  textAlign={"center"}
                >
                  {error}
                </Typography>
              </>
            )}
          </Box>
          <Box mb={5}>
            {!loggingWithPin ? (
              <>
                <Box className="clear10" />
                <Typography variant="body1">{`OTP was sent to ${customer?.phone?.replace(
                  /\d(?=\d{4})/g,
                  "*",
                )}`}</Typography>

                <Typography variant="body1">
                  Did not receive OTP? Login with{" "}
                  {/* <Link
                    onClick={(e) => setLoggingWithPin(true)}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    PIN
                  </Link> */}
                  <span
                    onClick={(e) => setLoggingWithPin(true)}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    PIN
                  </span>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  Login With OTP? &nbsp;
                  <Link
                    onClick={() => {
                      setLoggingWithPin(false);
                      setError("");
                    }}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Click here
                  </Link>
                </Typography>
              </>
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Continue
          </Button>
          {resendEnabled && !loggingWithPin && (
            <Button color="primary" fullWidth onClick={handleResendClick}>
              Resend OTP
            </Button>
          )}
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default TwoFactorLogin;
