import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import OtpInput from "react-otp-input";

function FirstTimeOtp({
  twoFactor,
  setTwoFactor,
  otp,
  setOtp,
  enable2fa,
  error,
  handleSendOtp,
}) {
  const [resendEnabled, setResendEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resendTimeout, setResendTimeout] = useState(false);
  useEffect(() => {
    // Enable the resend button after 1 minute
    const timer = setTimeout(() => {
      setResendEnabled(true);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearTimeout(timer);
  }, []);
  const handleResendClick = () => {
    // Disable the button and set a timeout to re-enable it after 1 minute
    setResendEnabled(false);
    setResendTimeout(true);

    setTimeout(() => {
      setResendEnabled(true);
      setResendTimeout(false);
    }, 60000);
    handleSendOtp();
  };
  return (
    <>
      <Drawer
        anchor="bottom"
        open={twoFactor}
      >
        <IconButton
          onClick={() => setTwoFactor(false)}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            fontSize: 20,
          }}
          size=""
          color="error"
        >
          <HighlightOffOutlinedIcon />
        </IconButton>
        <Typography variant="h2">Enter OTP</Typography>
        {/*<Typography variant="body1">Enter OTP</Typography>*/}

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          // renderSeparator={<span>-</span>}
          renderSeparator={"-"}
          renderInput={(props) => <input {...props} type={"password"} />}
          containerStyle={{
            justifyContent: "space-between",
          }}
          inputStyle={{
            backgroundColor:"#F7F7F7",

            width: "2.375rem",
            height: "2.375rem",
            fontWeight:700,
            fontSize: "1rem",
            borderRadius: "0.5rem",
            border: "1px solid rgba(0,104,255,.15)",
          }}
          inputType={"number"}
        />
        <Typography variant="body2" color={'error'} fontWeight={700} textAlign={'center'}>{error}</Typography>
        {/* <Typography color={"error"}>{error}</Typography> */}
        <Box component={"div"} className="clear20" />
        <Stack spacing={0}>
          <Button
            variant="contained"
            color="primary"
            onClick={enable2fa}
            size={"small"}
            fullWidth
          >
            Continue
          </Button>
          {resendEnabled && (
            <Button
              color="primary"
              onClick={handleResendClick}
              size={"small"}
              fullWidth
            >
              Resend OTP
            </Button>
          )}
        </Stack>
      </Drawer>
    </>
  );
}

export default FirstTimeOtp;
