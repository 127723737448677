import React from "react";
export default function ScanQR() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 36 62" fill="none">
                <path d="M21.893 56.0917V53.9442C21.893 53.9442 20.259 52.322 20.259 49.9177L23.0251 28.8399C23.0834 28.2796 23.3635 27.7661 23.7837 27.3926L23.8187 27.3693C24.8924 26.4123 26.6081 26.5523 27.4834 27.6611L35.0929 35.9709C35.3613 36.321 35.5131 36.7645 35.5131 37.208V56.0917" fill="#EFCDB1"/>
                <path d="M28.0905 4.80965V45.6116L2.42587 46.837C1.35213 46.8837 0.43012 46.0901 0.371765 45.063V4.80965H28.0905Z" fill="#73C9DF"/>
                <path d="M25.3359 31.5129L3.07922 31.2911V8.66101H25.3359V31.5129Z" fill="white"/>
                <path d="M12.0422 13.2007H7.60724V17.6357H12.0422V13.2007ZM11.0385 16.632H8.61095V14.216H11.0385V16.632Z" fill="#565656"/>
                <path d="M16.6762 26.7042H21.1112V22.2692H16.6762V26.7042ZM17.6799 23.2729H20.1075V25.7005H17.6799V23.2729Z" fill="#565656"/>
                <path d="M21.1112 13.2007H16.6762V17.6357H21.1112V13.2007ZM20.1075 16.632H17.6799V14.216H20.1075V16.632Z" fill="#565656"/>
                <path d="M7.60724 26.7042H12.0422V22.2692H7.60724V26.7042ZM8.61095 23.2729H11.0385V25.7005H8.61095V23.2729Z" fill="#565656"/>
                <path d="M14.8553 14.3909V16.7952H13.8515V17.7989H12.8478V18.4174H13.8515V19.3745H12.8595H10.8404V18.3708H9.83671V19.3745H8.82134V20.3782H7.81763V21.3935H8.82134H10.8404H12.8595V20.3782H13.8632V19.4211H14.8553V18.4174V17.7989H15.859V15.3946V14.3909H14.8553Z" fill="#565656"/>
                <path d="M16.8741 19.4215H14.855V21.3939H12.8593V23.4013H13.8513V25.3971H14.855V26.4125H15.8587V25.3971V23.3897H14.8667V22.4443H15.8587L15.8704 20.4369H16.8741V19.4215Z" fill="#565656"/>
                <path d="M19.8974 21.4406V20.4369H20.9012V19.4215H19.8974H17.8784V20.3435H18.8937V21.4406H19.8974Z" fill="#565656"/>
                <path d="M12.8363 25.3967H13.84V26.4004H12.8363V25.3967Z" fill="#565656"/>
                <path d="M6.41693 18.4178H7.42064V19.4216H6.41693V18.4178Z" fill="#565656"/>
                <path d="M20.9004 18.4178H21.9041V19.4216H20.9004V18.4178Z" fill="#565656"/>
                <path d="M12.8482 12.9911H13.8519V13.9948H12.8482V12.9911Z" fill="#565656"/>
                <path d="M16.8747 18.4178H17.8784V19.4216H16.8747V18.4178Z" fill="#565656"/>
                <path d="M13.8514 27.5676H14.8551V28.5713H13.8514V27.5676Z" fill="#565656"/>
                <path d="M4.52655 17.8459H5.5536V11.0884H12.3928V10.0613H4.52655V17.8459Z" fill="#565656"/>
                <path d="M16.3723 10.015V11.042H23.1298V17.8929H24.1568V10.015H16.3723Z" fill="#565656"/>
                <path d="M24.1571 22.0358H23.1301V28.7934H16.2792V29.8204H24.1571V22.0358Z" fill="#565656"/>
                <path d="M12.3111 29.8668V28.8398H5.5536V22.0006H4.52655V29.8668H12.3111Z" fill="#565656"/>
                <path d="M0.359863 15.0336H28.0902V16.2007H0.359863V15.0336Z" fill="#F04930"/>
                <path d="M28.0902 43.4406V46.7202C28.0902 48.9027 26.3162 50.6767 24.1337 50.6767H4.32801C2.14553 50.6767 0.371534 48.9027 0.359863 46.7202V43.4406H28.0902Z" fill="#565656"/>
                <path d="M12.0657 46.6038H17.0143V47.7709H12.0657V46.6038Z" fill="white"/>
                <path d="M28.0902 4.72792V4.80961H0.359863V4.72792C0.359863 2.53376 2.13386 0.759766 4.32801 0.759766H24.1337C26.3162 0.771437 28.0902 2.54543 28.0902 4.72792Z" fill="#565656"/>
                <path d="M28.0551 52.7776C28.0551 52.7776 25.0673 50.3267 24.4954 47.2806C24.0169 44.7713 24.9156 42.2854 24.9156 42.2854L20.9124 37.9321C20.0371 37.0217 20.0487 35.5862 20.9357 34.6992C21.8227 33.8122 23.2583 33.8005 24.1569 34.6642L31.8948 42.2854" fill="#EFCDB1"/>
                <path d="M31.8953 42.8695C31.7436 42.8695 31.5918 42.8111 31.4868 42.6944L28.3006 39.5549C28.0672 39.3332 28.0672 38.9714 28.2773 38.7496C28.2773 38.7496 28.2773 38.7496 28.2889 38.738C28.5107 38.5045 28.8841 38.5045 29.1176 38.7263L32.3038 41.8658C32.5372 42.0875 32.5372 42.461 32.3038 42.6944C32.1987 42.8111 32.047 42.8695 31.8953 42.8695Z" fill="#E5B591"/>
                <path d="M28.0553 53.3608C27.9269 53.3608 27.7985 53.3141 27.6935 53.2324C27.5651 53.1273 24.519 50.6064 23.9121 47.3852C23.4219 44.7475 24.3206 42.2033 24.3673 42.0865C24.484 41.7831 24.8224 41.643 25.1259 41.7598C25.4176 41.8765 25.5577 42.1916 25.4643 42.4834C25.4527 42.5067 24.624 44.8526 25.0675 47.1751C25.5927 49.9762 28.3938 52.2987 28.4288 52.322C28.6739 52.5321 28.7089 52.8939 28.5105 53.1507C28.3938 53.2907 28.2304 53.3608 28.0553 53.3608Z" fill="#E5B591"/>
                <path d="M21.8926 56.0919H35.5244V61.8107H21.8926V56.0919Z" fill="#E87B1E"/>
            </svg>
        </>

    );
}
