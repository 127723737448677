import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import QrButtonPair from "./qrButtonPair";
import QrShareDialogue from "../components/qrShareDialogue";

export default function NoRecordFound({
  title = "No transfer requests found.",
  buttonText = "",
  buttonLink = "",
  handleFileChange = null,
  handleClickOpen = null,
  VisuallyHiddenInput = null,
  openMyQr = false,
  handleClose = null,
  handleBrowserShare = null,
  handleDownload = null,
  qrCodeData = null,
}) {
  return (
    <>
      <Box
        flex={"1 1"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box variant="div" textAlign={"center"} mb={0}>
          <svg
            id="fi_6134116"
            className={`w-14 mx-auto mb-4 block`}
            viewBox="0 0 511.985 511.985"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m344.162 0c-92.537 0-167.822 75.285-167.822 167.822 0 43.577 16.699 83.323 44.028 113.188l-30.978 30.978-.365-.365c-4.444-4.444-10.354-6.893-16.64-6.893s-12.195 2.448-16.64 6.893l-146.52 146.521c-12.3 12.301-12.3 32.315 0 44.616 6.15 6.15 14.229 9.226 22.308 9.226s16.158-3.075 22.308-9.226l146.52-146.52c4.445-4.445 6.893-10.354 6.893-16.64s-2.448-12.195-6.893-16.64l-.365-.365 30.978-30.978c29.865 27.329 69.612 44.028 113.188 44.028 92.538 0 167.823-75.285 167.823-167.823 0-92.537-75.285-167.822-167.823-167.822zm-300.927 492.153c-6.452 6.452-16.951 6.452-23.403 0s-6.452-16.951 0-23.403l123.073-123.073 23.403 23.403zm146.52-146.52-12.841 12.841-23.403-23.403 12.841-12.841c1.611-1.611 3.754-2.499 6.033-2.499s4.422.888 6.033 2.499l11.336 11.337c1.612 1.612 2.499 3.754 2.499 6.033.001 2.279-.886 4.421-2.498 6.033zm154.407-24.987c-84.267 0-152.822-68.556-152.822-152.823s68.556-152.823 152.822-152.823 152.823 68.556 152.823 152.822c0 84.267-68.556 152.824-152.823 152.824z"></path>
              <path d="m440.158 71.827c-48.271-48.271-125.3-53.072-179.172-11.17-3.27 2.543-3.859 7.255-1.315 10.524 2.542 3.27 7.254 3.859 10.524 1.315 47.916-37.269 116.424-32.996 159.357 9.937 42.933 42.934 47.205 111.442 9.937 159.356-2.543 3.27-1.954 7.982 1.315 10.525 1.369 1.064 2.99 1.58 4.599 1.58 2.235 0 4.447-.995 5.925-2.896 41.903-53.872 37.102-130.899-11.17-179.171z"></path>
              <path d="m418.131 263.147c-21.84 16.988-47.954 25.345-73.979 25.344-31.071-.001-62.014-11.916-85.377-35.28-42.933-42.933-47.205-111.442-9.937-159.356 2.543-3.27 1.954-7.982-1.315-10.525-3.271-2.543-7.982-1.954-10.524 1.315-41.904 53.874-37.102 130.901 11.17 179.172 26.271 26.272 61.053 39.667 95.992 39.666 29.257-.001 58.627-9.398 83.18-28.496 3.27-2.543 3.858-7.255 1.315-10.525-2.544-3.268-7.257-3.858-10.525-1.315z"></path>
              <path d="m282.746 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l10.729 10.729-10.729 10.729c-2.929 2.929-2.929 7.678 0 10.606z"></path>
              <path d="m362.908 165.109c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197l10.729-10.729 10.729 10.729c1.464 1.465 3.384 2.197 5.303 2.197s3.839-.732 5.303-2.197c2.929-2.929 2.929-7.678 0-10.606l-10.729-10.729 10.729-10.729c2.929-2.929 2.929-7.677 0-10.606s-7.678-2.929-10.606 0l-10.729 10.729-10.729-10.729c-2.929-2.929-7.678-2.929-10.606 0-2.929 2.929-2.929 7.678 0 10.606l10.729 10.729-10.729 10.729c-2.93 2.929-2.93 7.678 0 10.606z"></path>
              <path d="m298.774 218.637c-2.926 2.932-2.922 7.681.009 10.607 1.464 1.461 3.381 2.192 5.299 2.192 1.921 0 3.843-.734 5.308-2.201 8.774-8.79 21.448-13.831 34.772-13.831s25.999 5.041 34.773 13.831c2.926 2.932 7.675 2.936 10.606.009 2.931-2.926 2.936-7.675.009-10.606-11.567-11.588-28.111-18.233-45.389-18.233-17.276-.002-33.82 6.644-45.387 18.232z"></path>
            </g>
          </svg>
          <div className={`block`}>
            <p className={`block leading-none`}>{title}</p>
          </div>
          {buttonText && (
            <div className={`inline-grid`}>
              <Link
                to={buttonLink ? buttonLink : "/dashboard"}
                className={` w-full mt-4 bg-[#003d67] text-white rounded-lg px-5 py-2`}
                color="primary"
              >
                {buttonText}
              </Link>
            </div>
          )}
          {openMyQr && (
            <div className={`mt-4`}>
              <QrButtonPair
                handleFileChange={handleFileChange}
                handleClickOpen={handleClickOpen}
                VisuallyHiddenInput={VisuallyHiddenInput}
              />
            </div>
          )}
        </Box>
        <QrShareDialogue
          openMyQr={openMyQr}
          handleClose={handleClose}
          handleBrowserShare={handleBrowserShare}
          handleDownload={handleDownload}
          qrCodeData={qrCodeData}
        />
      </Box>
    </>
  );
}
