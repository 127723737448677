import * as React from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import PaymentComponent from './paymentComponent';
import CloseIcon from '@mui/icons-material/Close';

function PaymentDrawer({onOpen,closeDrawer, data}) {
  return (
    <>
        <Drawer anchor={'bottom'} open={onOpen} >
          <IconButton
          color='primary'
            onClick={closeDrawer}
            aria-label='close'
            size='small'
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box component={'div'} pb={2} pt={5}>
            <PaymentComponent signatureData={data}/>
          </Box>
        </Drawer>
    </>
  );
}
export default PaymentDrawer;
