import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  CardContent,
  Card,
  Radio,
  SvgIcon,
  Avatar,
  RadioGroup,
  TextField,
  InputAdornment,
} from "@mui/material";
import HeaderFull from "../components/headerFull";
import { useCustomer } from "../context/customerContext";
import LayoutStyle from "../scss/base.module.scss";
import BottomNav from "../components/bottomNav";
import NewBeneficiary from "../components/newBeneficiary";
import AddIcon from "../Assets/Icons/addIcon";
import { currencyFormat, roundUp } from "../utils/Common";
import {
  createWithdrawalRequest,
  getWithdrawalServices,
} from "../services/WithdrawalServices";
import Loader from "../components/Loader";
import moment from "moment-timezone";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CustomerConfirmModal from "../components/Modal";
import { useNavigate } from "react-router-dom";

function BankList() {
  const { customer } = useCustomer();
  const router = useNavigate();
  const [withdrawalServices, setWithdrawalServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    bank_id: 0,
    amount: 0,
  });
  const [error, setError] = useState(null);
  const withdrawOptions = customer?.bankInfo || [];
  const [beneficiary, setBeneficiary] = useState(false);
  const [addedBeneficiary, setAddedBeneficiary] = useState(false);
  const [withdrawalAdded, setWithdrawalAdded] = useState(false);

  const handleBankSubmit = (status) => {
    setAddedBeneficiary(status);
    status && setBeneficiary(false);
  };

  useEffect(() => {
    if (customer?.customer_external_id || addedBeneficiary || withdrawalAdded) {
      setLoading(true);
      getWithdrawalServices(
        customer?.customer_external_id,
        customer?.token,
        1,
        "PENDING",
        3,
      )
        .then((res) => {
          setLoading(false);
          if (res.records.length > 0) {
            setWithdrawalServices(res.records);
          }
        })
        .catch(() => setLoading(false));
    }
  }, [addedBeneficiary, withdrawalAdded]);

  const handleWithdrawalSubmit = async () => {
    try {
      setError(null);
      if (state.amount <= 0) {
        setError("Please enter a valid amount");
        return;
      }
      if (!state.bank_id) {
        setError("Please select a bank");
        return;
      }
      setLoading(true);
      await createWithdrawalRequest(
        customer?.customer_external_id,
        customer?.token,
        state,
      );
      setWithdrawalAdded(true);
      setLoading(false);
      setState({ ...state, amount: null });
    } catch (e) {
      setError(e.response.data.error);
      setLoading(false);
    }
  };
  const handleWithdrawal = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      <HeaderFull />
      {loading && <Loader show={loading} />}
      {(error || withdrawalAdded) && (
        <CustomerConfirmModal
          type={`${withdrawalAdded ? `Success` : `Error`}`}
          headLine={`${withdrawalAdded ? `Success` : `Error`}`}
          showCancel={`Close`}
          message={error ? error : `Withdrawal request Initiated`}
          isModalOpen={!!error || withdrawalAdded}
          CloseOnClick={() => {
            setError("");
            withdrawalAdded ? router("/dashboard") : setWithdrawalAdded(false);
          }}
        />
      )}
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        <Container>
          <Card elevation={2} className={LayoutStyle.withdrawBox}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                mb={0}
                className={"text-white"}
                fontWeight={400}
                sx={{ opacity: 0.5 }}
              >
                Available Balance
              </Typography>
              <Typography variant="h2" className={"text-white"} mb={0}>
                {currencyFormat(roundUp(customer?.amount))}
              </Typography>
            </CardContent>
          </Card>
          <Box className="clear10" />
          <TextField
            fullWidth
            type="number"
            onChange={handleWithdrawal}
            name={`amount`}
            label={"Enter Amount to withdraw"}
            margin="dense"
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end"> $</InputAdornment>,
            }}
          />
          <Box className="clear10" />
          {withdrawalServices?.length > 0 && (
            <>
              <Typography variant="h4" mb={2}>
                Recent Request(s)
              </Typography>
              <ul className={`max-h-52 overflow-y-auto`}>
                {withdrawalServices?.map((item, index) => (
                  <li key={index}>
                    <Card elevation={3} className={`mb-1`}>
                      <CardContent className={`flex items-center`}>
                        <Box className={`flex items-center`}>
                          <Box className={LayoutStyle.cardLogo}>
                            <Avatar
                              className={`${LayoutStyle.cardAvtar} !bg-green-400`}
                            >
                              <AccountBalanceIcon className={`text-blue-900`} />
                            </Avatar>
                          </Box>
                          <Box className={`ml-2`}>
                            <Typography variant="h4" gutterBottom>
                              {item?.CustomerBankInfo?.bank_name} -{" "}
                              {item?.CustomerBankInfo?.account_no?.slice(-4)}
                            </Typography>
                            <Typography variant="body2" sx={{ opacity: 1 }}>
                              {moment(item?.createdAt).format("ll")}
                            </Typography>
                          </Box>
                        </Box>
                        <Box ml={"auto"}>
                          <Typography
                            variant="h4"
                            fontWeight={500}
                            className={`!mb-1`}
                          >
                            {currencyFormat(item?.amount)}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{ opacity: 1 }}
                            className={`!mb-0`}
                          >
                            {item?.status}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </li>
                ))}
              </ul>
              <Box className="clear20" />
            </>
          )}
          {withdrawOptions?.length > 0 && (
            <>
              <Typography variant="h4" mb={0} mt={2}>
                Select Your Bank
              </Typography>
              <RadioGroup name="bank_id" onChange={handleWithdrawal}>
                <ul className={`${LayoutStyle.cardList} `}>
                  {withdrawOptions.map((bank, index) => (
                    <li key={index}>
                      <label htmlFor={bank?.id}>
                        <Box className={LayoutStyle.cardBox}>
                          <Box className={LayoutStyle.cardLogo}>
                            <Avatar className={LayoutStyle.cardAvtar}>
                              <AccountBalanceIcon className={`text-blue-900`} />
                            </Avatar>
                          </Box>
                          <Box>
                            <Typography variant="h4" gutterBottom>
                              {bank?.bank_name} - {bank?.account_no?.slice(-4)}
                            </Typography>
                            <Typography variant="body2">
                              Routing Number : {bank?.routing_no}
                            </Typography>
                          </Box>
                          <Box ml={"auto"}>
                            <Radio
                              size="small"
                              id={bank?.id}
                              value={bank?.id}
                              name="bank_id"
                            />
                          </Box>
                        </Box>
                      </label>
                    </li>
                  ))}
                </ul>
              </RadioGroup>
            </>
          )}
          <Button
            variant="outlined"
            color="black"
            fullWidth
            size="small"
            className="accentFill"
            startIcon={
              <SvgIcon viewBox="0 0 11 13" sx={{ stroke: "#084975" }}>
                <AddIcon />
              </SvgIcon>
            }
            onClick={() => setBeneficiary(true)}
          >
            Add New Beneficiary
          </Button>
          <Box className="clear20" />

          {withdrawOptions?.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleWithdrawalSubmit}
            >
              Submit
            </Button>
          )}
        </Container>
      </Box>
      <BottomNav />
      <NewBeneficiary
        onOpen={beneficiary}
        closeDrawer={() => setBeneficiary(false)}
        customer={customer}
        onSubmit={handleBankSubmit}
      />
    </>
  );
}

export default BankList;
