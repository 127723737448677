import React, { useEffect, useState } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import alert from "./Assets/svg/alert.svg";

const UpdateNotification = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <>
      {showReload && (
        <div className="bg-opacity-55 fixed z-[9999] bg-black w-full h-full">
          <div className={`update-notification !w-96 h-80 rounded`}>
            <div>
              <div className={`mb-5`}>
                <img className={`w-20 mx-auto`} src={alert} alt="alert" />
              </div>
              <p className={`text-xl font-semibold`}>
                A new version is available!
              </p>
              <div className={`flex gap-6 mt-6 justify-center`}>
                <button
                  className={` text-red-500`}
                  onClick={() => setShowReload(false)}
                >
                  Close
                </button>
                <button
                  className={`underline text-blue-900`}
                  onClick={reloadPage}
                >
                  Reload
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateNotification;
