import React from "react";

export default function WithdrawalIcon(props) {
  return (
    <> 
     <path d="M13.3278 10.0042C13.3278 8.16327 11.8367 6.67222 9.99583 6.67222C8.15493 6.67222 6.66389 8.16327 6.66389 10.0042C6.66389 11.8451 8.15493 13.3361 9.99583 13.3361C11.8367 13.3361 13.3278 11.8451 13.3278 10.0042ZM9.99583 11.6701C9.07955 11.6701 8.32986 10.9205 8.32986 10.0042C8.32986 9.08788 9.07955 8.33819 9.99583 8.33819C10.9121 8.33819 11.6618 9.08788 11.6618 10.0042C11.6618 10.9205 10.9121 11.6701 9.99583 11.6701ZM16.6597 0H3.33194C1.49105 0 0 1.49938 0 3.34028V4.17326C0 6.00583 1.49105 7.50521 3.33194 7.50521V15.8351C3.33194 18.1341 5.19783 20 7.49688 20H12.4948C14.7938 20 16.6597 18.1341 16.6597 15.8351V7.50521C18.5006 7.50521 19.9917 6.01416 19.9917 4.17326V3.34028C19.9917 1.49938 18.5006 0 16.6597 0ZM18.3257 4.16493C18.3257 5.08122 17.576 5.8309 16.6597 5.8309V4.16493C16.6597 3.70679 16.2849 3.33194 15.8267 3.33194C15.3686 3.33194 14.9938 3.70679 14.9938 4.16493V15.8267C14.9938 17.2012 13.8692 18.3257 12.4948 18.3257H7.49688C6.12245 18.3257 4.99792 17.2012 4.99792 15.8267V4.17326C4.99792 3.71512 4.62307 3.34028 4.16493 3.34028C3.70679 3.34028 3.33194 3.71512 3.33194 4.17326V5.83923C2.41566 5.83923 1.66597 5.08955 1.66597 4.17326V3.34028C1.66597 2.42399 2.41566 1.6743 3.33194 1.6743H16.6597C17.576 1.6743 18.3257 2.42399 18.3257 3.34028V4.17326V4.16493ZM14.1608 16.2432C14.1608 16.9346 13.6027 17.4927 12.9113 17.4927C12.2199 17.4927 11.6618 16.9346 11.6618 16.2432C11.6618 15.5519 12.2199 14.9938 12.9113 14.9938C13.6027 14.9938 14.1608 15.5519 14.1608 16.2432ZM8.32986 16.2432C8.32986 16.9346 7.77176 17.4927 7.08038 17.4927C6.389 17.4927 5.8309 16.9346 5.8309 16.2432C5.8309 15.5519 6.389 14.9938 7.08038 14.9938C7.77176 14.9938 8.32986 15.5519 8.32986 16.2432Z" fill={props.fill? (props.fill):"black"}/>
    </>

  );
}
