export const steps = [
  {
    target: ".bottomDeposit",
    content:
      "Deposit funds into your Gain Funds account using any bank account, debit card, or credit card.",
    disableBeacon: true,
  },
  {
    target: ".bottomWithdrawal",
    content: "Withdraw your funds directly to your bank account.",
  },
  {
    target: ".link_another_account_and_card",
    content: "Link your bank account, debit card, or credit card.",
  },
  {
    target: ".bottomContactless",
    content: "Check your transfer requests.",
  },
  {
    target: ".security_2fa",
    content: "To enable 2FA OTP, first set up your PIN.",
  },
  {
    target: ".send_money",
    content: "Send money to friends and family.",
  },
  {
    target: ".QR",
    content: "Send money directly by scanning a QR code.",
  },
];