const getPusherKey = () => {
  if (window.location?.origin === "http://localhost:3331") {
    return "190f1258a9e9ef35fde0";
  } else if (
    window.location?.origin === "https://mirror-customer.gainfunds.com"
  ) {
    return "a52955924a8003dd2b05";
  } else if (window.location?.origin === "https://dev-customer.gainfunds.com") {
    return "190f1258a9e9ef35fde0";
  } else {
    return "d87fcbfbad03d21d2787";
  }
};

export default getPusherKey;
