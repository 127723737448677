import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Container,
  Card,
  CardContent,
  SvgIcon,
  Divider,
} from "@mui/material";
import BottomNav from "../components/bottomNav";
import HeaderFull from "../components/headerFull";
import { getRequestsServices } from "../services/ActivitiesServices";
import { useCustomer } from "../context/customerContext";
import { currencyFormat } from "../utils/Common";
import Loader from "../components/Loader";
import moment from "moment-timezone";
import LayoutStyle from "../scss/base.module.scss";
import RequestIcon from "../Assets/Icons/requestIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { checkLogin } from "../components/helpers/checkLogin";
import NoRecordFound from "../components/NoRecordFound";
import pusherSub from "../utils/Pusher";
import { toString } from "lodash";
// import { getCustomerInfoServices } from "../services/CustomerServices";

function Request() {
  const navigate = useNavigate();
  const { customer, token, setCurrentRequest } = useCustomer();
  const [requests, setRequests] = useState([]);
  const [moneyRequests, setMoneyRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const Payment = (id) => {
    setCurrentRequest(id);
    navigate(`/goods-services/${id}`);
  };
  useEffect(() => {
    checkLogin(customer, navigate);
    if (customer?.customer_external_id) {
      setLoading(true);
      getRequestsServices(customer?.customer_external_id, token)
        .then((res) => {
          setLoading(false);
          setRequests(res?.contactLess);
          setMoneyRequests(res?.fundRequests);
        })
        .catch(() => setLoading(false));
    }
  }, [customer?.customer_external_id, token]);
  // useEffect(() => {
  //   console.log(requests);
  // }, [requests]);
  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p_req",
      () => {
        setLoading(true);
        getRequestsServices(customer?.customer_external_id, token)
          .then((res) => {
            setLoading(false);
            setMoneyRequests(res?.fundRequests);
          })
          .catch(() => setLoading(false));
      },
    );
  }, []);

  return (
    <>
      {loading && <Loader show={loading} />}
      <HeaderFull />
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        {requests && requests?.length > 0 && (
          <Container>
            <Typography variant="h4" gutterBottom>
              All Requests
            </Typography>
            <ul className={LayoutStyle.requestList}>
              {requests?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => Payment(item?.contactLess_external_id)}
                  className={`cursor-pointer`}
                >
                  <Card
                    elevation={3}
                    sx={{ borderRadius: "0.9375rem!important" }}
                  >
                    <CardContent sx={{ pr: "2.5rem!important" }}>
                      <Stack spacing={3}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Box className={`${LayoutStyle.requestIcon}`}>
                            <SvgIcon
                              viewBox="0 0 512 512"
                              sx={{ width: "1.25rem", height: "1.25rem" }}
                            >
                              <RequestIcon />
                            </SvgIcon>
                          </Box>
                          <Typography variant="h4" m={0}>
                            New Contactless Request
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"flex-end"}
                          justifyContent={"space-between"}
                          spacing={1}
                        >
                          <Box>
                            <Typography variant="h4" m={0} fontWeight={500}>
                              {item?.Store?.account_number || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {item?.Store?.city || ""} |{" "}
                              {item?.Store?.state || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {moment(item?.createdAt || new Date()).format(
                                "ll",
                              )}
                            </Typography>
                          </Box>
                          <Box className={LayoutStyle.requestPrice}>
                            {currencyFormat(item?.amount || 0)}
                          </Box>
                        </Stack>
                      </Stack>
                      <Box
                        component={"div"}
                        className={`${LayoutStyle.requestPriceArrow}`}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </Box>
                    </CardContent>
                  </Card>
                </li>
              ))}
            </ul>
            <Divider sx={{ my: 2 }} />
          </Container>
        )}
        {moneyRequests && moneyRequests?.length > 0 && (
          <Container>
            <Typography variant="h4" gutterBottom>
              Fund Requests
            </Typography>
            <ul className={LayoutStyle.requestList}>
              {moneyRequests?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    // console.log(item);
                    navigate(
                      `/fund-request/${item?.friendsandfamily_external_id}`,
                    );
                  }}
                  className={`cursor-pointer`}
                >
                  <Card
                    elevation={3}
                    sx={{ borderRadius: "0.9375rem!important" }}
                  >
                    <CardContent sx={{ pr: "2.5rem!important" }}>
                      <Stack spacing={3}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Box className={`${LayoutStyle.requestIcon}`}>
                            <SvgIcon
                              viewBox="0 0 512 512"
                              sx={{ width: "1.25rem", height: "1.25rem" }}
                            >
                              <RequestIcon />
                            </SvgIcon>
                          </Box>
                          <Typography variant="h4" m={0}>
                            New Fund Request
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"flex-end"}
                          justifyContent={"space-between"}
                          spacing={1}
                        >
                          <Box>
                            <Typography variant="h4" m={0} fontWeight={500}>
                              {item?.receiver_name || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {item?.receiver?.account_number || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {moment(item?.createdAt || new Date()).format(
                                "ll",
                              )}
                            </Typography>
                          </Box>
                          <Box className={LayoutStyle.requestPrice}>
                            {currencyFormat(item?.amount || 0)}
                          </Box>
                        </Stack>
                      </Stack>
                      <Box
                        component={"div"}
                        className={`${LayoutStyle.requestPriceArrow}`}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </Box>
                    </CardContent>
                  </Card>
                </li>
              ))}
            </ul>
          </Container>
        )}
        {!loading && requests?.length === 0 && moneyRequests?.length === 0 && (
          <NoRecordFound />
        )}
      </Box>
      <BottomNav />
    </>
  );
}
export default Request;
