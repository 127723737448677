import React from "react";
import AppRouter from "./routes/AppRouter";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import { CustomerContextProvider } from "./context/customerContext";
import { ProjectGuideContextProvider } from "./context/projectGuideContext";
import UpdateNotification from "./UpdateNotification";

function App() {
  return (
    <CustomerContextProvider>
      <ProjectGuideContextProvider>
        <ThemeProvider theme={theme}>
          <UpdateNotification />
          <Router>
            <AppRouter />
          </Router>
        </ThemeProvider>
      </ProjectGuideContextProvider>
    </CustomerContextProvider>
  );
}

export default App;
