import React from "react";

export default function QrCodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M1.00001 7.99999C0.400012 7.99999 0 7.59998 0 6.99998V2.99999C0 1.29999 1.29999 0 2.99999 0H6.99998C7.59998 0 7.99999 0.400012 7.99999 1.00001C7.99999 1.60001 7.59998 2.00002 6.99998 2.00002H2.99999C2.39999 2.00002 1.99998 2.40003 1.99998 3.00003V7.00002C2.00002 7.59998 1.60001 7.99999 1.00001 7.99999Z"
        fill="white"
      />
      <path
        d="M21 7.99999C20.4 7.99999 20 7.59998 20 6.99998V2.99999C20 2.39999 19.6 1.99998 19 1.99998H15C14.4 1.99998 14 1.59997 14 0.999969C14 0.399971 14.4 0 15 0H19C20.7 0 22 1.29999 22 2.99999V6.99998C22 7.59998 21.6 7.99999 21 7.99999Z"
        fill="white"
      />
      <path
        d="M6.99998 22H2.99999C1.29999 22 0 20.7 0 19V15C0 14.4 0.400012 14 1.00001 14C1.60001 14 2.00002 14.4 2.00002 15V19C2.00002 19.6 2.40003 20 3.00003 20H7.00002C7.60002 20 8.00003 20.4 8.00003 21C7.99999 21.6 7.59998 22 6.99998 22Z"
        fill="white"
      />
      <path
        d="M19 22H15C14.4 22 14 21.6 14 21C14 20.4 14.4 20 15 20H19C19.6 20 20 19.6 20 19V15C20 14.4 20.4 14 21 14C21.6 14 22 14.4 22 15V19C22 20.7 20.7 22 19 22Z"
        fill="white"
      />
      <path
        d="M9.00001 10H5.00001C4.40001 10 4 9.6 4 9.00001V5.00001C4 4.40001 4.40001 4 5.00001 4H9.00001C9.6 4 10 4.40001 10 5.00001V9.00001C10 9.6 9.6 10 9.00001 10ZM6.00002 8H8.00004V5.99998H6.00002V8Z"
        fill="white"
      />
      <path
        d="M9.00001 18H5.00001C4.40001 18 4 17.6 4 17V13C4 12.4 4.40001 12 5.00001 12H9.00001C9.6 12 10 12.4 10 13V17C10 17.6 9.6 18 9.00001 18ZM6.00002 16H8.00004V14H6.00002V16Z"
        fill="white"
      />
      <path
        d="M17 10H13C12.4 10 12 9.6 12 9.00001V5.00001C12 4.40001 12.4 4 13 4H17C17.6 4 18 4.40001 18 5.00001V9.00001C18 9.6 17.6 10 17 10ZM14 8H16V5.99998H14V8Z"
        fill="white"
      />
      <path
        d="M13 15C12.4 15 12 14.6 12 14V13C12 12.4 12.4 12 13 12C13.6 12 14 12.4 14 13V14C14 14.6001 13.6 15 13 15Z"
        fill="white"
      />
      <path
        d="M17 14H16C15.4 14 15 13.6 15 13C15 12.4 15.4 12 16 12H17C17.6 12 18 12.4 18 13C18 13.6 17.6 14 17 14Z"
        fill="white"
      />
      <path
        d="M17 18H14C13.4 18 13 17.6 13 17C13 16.4 13.4 16 14 16H17C17.6 16 18 16.4 18 17C18 17.6 17.6 18 17 18Z"
        fill="white"
      />
    </svg>
  );
}
