import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useCustomer } from "../context/customerContext";

function QrShareDialogue({
  openMyQr,
  handleClose,
  handleDownload,
  qrCodeData,
  handleBrowserShare,
}) {
  const { customer } = useCustomer();

  return (
    <>
      <Dialog
        open={openMyQr}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogContent>
          <Typography
            variant="h4"
            fontWeight={400}
            className={`!text-center`}
            color={"secondary"}
            sx={{ opacity: 0.5 }}
          >
            Contactless Payment
          </Typography>
          <Typography variant="h3" className={`!text-center`}>
            Scan QR Code
          </Typography>
          <Card variant="outlined" onClick={handleDownload}>
            <CardContent>
              <img src={qrCodeData} alt={"qr"} />
            </CardContent>
          </Card>
          <Box className="clear20" />
          <Stack spacing={1}>
            <Stack
              className={`!flex-row`}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Typography variant="h5" fontWeight={500}>
                Account Number:{" "}
              </Typography>
              <Typography variant="h5" fontWeight={500} mt={0}>
                {customer?.account_number}
              </Typography>
            </Stack>
            <Stack
              className={`!flex-row`}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Typography variant="h5" fontWeight={500}>
                Name:
              </Typography>
              <Typography variant="h5" fontWeight={500} mt={0}>
                {customer?.fullName}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={1} mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleBrowserShare}
            startIcon={<ShareOutlinedIcon />}
            size="small"
            
          >
            Share QR
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            
            size="small"
            onClick={handleClose}
            // startIcon={<ShareOutlinedIcon />}
          >
            Close
          </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default QrShareDialogue;
