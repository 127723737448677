import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import LayoutStyle from "../scss/base.module.scss";
import GainLogo from "../svg/Gainlogo";
import SuccessLogo from "../Assets/Svgs/successLogo";
import { currencyFormat } from "../utils/Common";
import moment from "moment-timezone";

function TransactionSuccessfulDrawer({
  completedTransaction,
  totalAmount,
  completedTransactionData,
  fundLoaded,
}) {
  const route = useNavigate();
  return (
    <Dialog fullScreen anchor="bottom" open={completedTransaction}>
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.loginContainer}`}
        justifyContent={"center"}
      >
        <Container>
          <Box
            component={"div"}
            className={`${LayoutStyle.BrandLogo} ${LayoutStyle.BrandLogoScan} `}
            sx={{ mb: { xs: 3, sm: 4 } }}
          >
            <Link to={`/`}>
              <GainLogo />
            </Link>
          </Box>

          <Typography variant="h3" color={"secondary"} textAlign={"center"}>
            {`Delivered Successfully`}
          </Typography>
          <Box component={"div"} className={`${LayoutStyle.successLogo}`}>
            <SuccessLogo />
          </Box>
          <Box className="clear30" />
          <Card elevation={2} sx={{ mx: { xs: 0, sm: 1 } }}>
            <CardContent>
              <p className={`text-center text-xs mb-0`}>Charged</p>
              <Typography variant="h2" gutterBottom textAlign={"center"}>
                {currencyFormat(totalAmount || 0)}
              </Typography>
              <Stack spacing={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography variant="h5">Date: </Typography>
                  <Typography variant="h5">
                    {moment(completedTransactionData?.created_at).format(
                      "LL",
                    ) || ""}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography variant="h5">Name: </Typography>
                  <Typography variant="h5">
                    {completedTransactionData?.name || ""}
                  </Typography>
                </Stack>

                {completedTransactionData?.customer_account_number && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography variant="h5">Account No: </Typography>
                    <Typography variant="h5">
                      {completedTransactionData?.order_id || ""}
                    </Typography>
                  </Stack>
                )}
                {totalAmount && (
                  <>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={2}
                    >
                      <Typography variant="h5">Total Transferred: </Typography>
                      <Typography variant="h5">
                        {currencyFormat(totalAmount || 0)}
                      </Typography>
                    </Stack>
                  </>
                )}
                {fundLoaded?.receiptId && (
                  <Link
                    to={`/receipt/${fundLoaded?.receiptId}`}
                    className="text-center"
                  >
                    View Receipt
                  </Link>
                )}
              </Stack>
            </CardContent>
          </Card>
          <Box className="clear30" />

          <Button
            variant="contained"
            color="primary"
            onClick={() => route(`/dashboard`)}
            fullWidth
          >
            Continue
          </Button>
        </Container>
      </Box>
    </Dialog>
  );
}

export default TransactionSuccessfulDrawer;
