import Axios from "../config/Axios";

export const CheckLicenseService = async (data = {}) => {
  try {
    const checkCustomer = await Axios().post(`/check-licence`, data);
    // console.log(checkCustomer?.data?.response);
    return checkCustomer?.data?.response;
  } catch (e) {
    throw new Error(e?.response?.data?.message || e?.message);
  }
};

export const CustomerAuthentication = async (data) => {
  try {
    const response = await Axios().post("/customer-authentication", data);
    return response?.data?.response;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};

export const UpdateCustomer = async (customerId, token, data = {}) => {
  try {
    const checkCustomer = await Axios(token).post(
      `/customer/${customerId}`,
      data,
    );
    return checkCustomer?.data?.response;
  } catch (e) {
    throw new Error(e?.response?.data?.message || e?.message);
  }
};
