import React from "react";

export default function CardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
        <g>
            <path d="M19.8916 0H2.10842C0.944018 0 8.5937e-05 1.14411 8.5937e-05 2.55557V4.44447H0V11.1111H8.5937e-05V17.4444C8.5937e-05 18.8558 0.943975 20 2.10842 20H19.8917C21.0561 20 22 18.8559 22 17.4444V2.55557C22 1.14411 21.0561 0 19.8916 0ZM1.83342 2.55557C1.83342 2.37145 1.95653 2.22223 2.10842 2.22223H19.8917C20.0436 2.22223 20.1667 2.37145 20.1667 2.55557V4.44447H1.83342V2.55557ZM1.83342 6.66665H20.1666V8.88888H1.83342V6.66665ZM19.8916 17.7777H2.10842C1.95653 17.7777 1.83342 17.6285 1.83342 17.4444V11.1111H20.1667V17.4444C20.1666 17.6285 20.0436 17.7777 19.8916 17.7777Z" fill="black"/>
            <path d="M18.3332 13.334H12.8333V15.5562H18.3332V13.334Z" fill="black"/>
        </g>
    </svg>
  );
}
