import Payment from "payment";
import CryptoJS from "crypto-js";

/**
 * Removes all non-digit characters from a string.
 * @param {string} value - The string to be processed.
 * @returns {string} - The processed string with only digit characters.
 */
export function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

/**
 * Removes all non-alphanumeric characters from a string.
 * @param {string} value - The string to be processed.
 * @returns {string} - The processed string with only alphanumeric characters.
 */
function numericCharacters(value = "") {
  return value.replace(/[^A-Z^ 0-9]+/g, "");
}

/**
 * Formats a credit card number string based on the card issuer.
 * @param {string} value - The credit card number string.
 * @returns {string} - The formatted credit card number string.
 */
export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;
  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }
  return nextValue.trim();
}

/**
 * Formats a CVC string based on the card issuer.
 * @param {string} value - The CVC string.
 * @param {string} prevValue - The previous CVC string.
 * @param {object} allValues - The object containing all form values.
 * @returns {string} - The formatted CVC string.
 */
export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;
  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }
  return clearValue.slice(0, maxLength);
}

/**
 * Formats a ZIP code string.
 * @param {string} value - The ZIP code string.
 * @returns {string} - The formatted ZIP code string.
 */
export function formatZIP(value) {
  const clearValue = numericCharacters(value);
  let maxLength = 5;
  return clearValue.slice(0, maxLength);
}

/**
 * Formats an expiration date string.
 * @param {string} value - The expiration date string.
 * @returns {string} - The formatted expiration date string.
 */
export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);
  if (clearValue > 1 && clearValue.length === 1) {
    return `0${clearValue}/`;
  } else if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }
  return clearValue;
}

/**
 * Formats form data into a string array.
 * @param {object} data - The form data object.
 * @returns {array} - The formatted form data as a string array.
 */
export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

/**
 * Checks if the length of the input value exceeds the maximum length and trims it if necessary.
 * @param {object} object - The input object.
 */
export function maxLengthCheck(object) {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
}

export function maskExceptLast4(input) {
  input = String(input);
  if (input.length <= 4) {
    return input;
  }
  let last4 = input.slice(-4);
  // return "*".repeat(input.length - 4) + last4;
  return last4;
}

export function mapCardAndBank(customer) {
  const customerBankInfo = customer?.bankInfo;
  const customerCard = customer?.card;
  const filterData = [];

  customerBankInfo?.forEach((item) => {
    filterData.push({
      number: item?.account_no,
      type: "Bank",
      name: item?.bank_name,
      external_id: item?.external_id,
    });
  });
  customerCard?.forEach((item) => {
    filterData.push({
      number: item?.card_number,
      type: "Card",
      name: item?.card_type,
      image: item?.image,
      external_id: item?.external_id,
      token: item?.card_token,
      cardNumber: item?.cardNumber
        .split("")
        .filter((char) => char !== " ")
        .join(""),
    });
  });
  return filterData;
}

export async function encryptData(object, key) {
  try {
    return await CryptoJS.AES.encrypt(JSON.stringify(object), key).toString();
  } catch (e) {
    throw new Error(e);
  }
}

export function extractUUIDFromURL(url) {
  const uuidRegex =
    /\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\b/g;
  const match = url.match(uuidRegex);
  return match ? match[0] : null;
}

export function maskCreditCard(cardNumber) {
  const visibleDigits = 4; // Number of visible digits at the end
  const maskedDigits = cardNumber.length - visibleDigits;
  const maskedPart = "*".repeat(maskedDigits);
  const visiblePart = cardNumber.slice(-visibleDigits);
  return maskedPart + visiblePart;
}
