/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {Link, Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import { Box, Button, Container, FormControl } from "@mui/material";
import Footer from "../components/footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {startsWith, toNumber} from "lodash";
import { useCustomer } from "../context/customerContext";
import GainLogo from "../svg/Gainlogo";
import LayoutStyle from "../scss/base.module.scss";
import Axios from "../config/Axios";
import Loader from "../components/Loader";
import {CustomerAuthentication} from "../services/VerificationServices";
import {extractUUIDFromURL} from "../utils/CardUtility";

function Phone() {
  const navigate = useNavigate();
  const { custExtId } = useParams();
  const [phone, setPhone] = useState(null);
  const [dileCode, setDileCode] = useState(null);
  const { setCustomer, customer, token } = useCustomer();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {pathname} = useLocation();
  const handleChanges = (p, a) => {
    setPhone(p);
    setDileCode(a.dialCode);
    setError("");
  };
  const handlePhoneVerification = async () => {
    const genPhone = phone.slice(("" + dileCode).length, ("" + phone).length);
    let data = {
      phone: genPhone,
      dailCode: dileCode,
      customer_id: customer?.customer_external_id,
      p2pId: customer?.p2pId,
    };
    try {
      setLoading(true);
      const checkCustomer = await Axios(token).post(
        `/customer/${custExtId || customer?.customer_external_id}`,
        data,
      );
      const customerData = checkCustomer?.data?.response?.verifyCustomer;
      setLoading(false);
      if (checkCustomer?.data?.message === "Invalid Phone Number") {
        setError("Invalid Phone Number");
      } else {
        const res = await (CustomerAuthentication(customerData));
        const cust = res?.response;
        if (!cust.CustomerKey) {
          cust.CustomerKey = {};
        }
        cust.CustomerKey.publicKey = res?.publicKey;
        setCustomer((prev) => ({
          ...prev,
          ...cust
        }));
        setLoading(false);
        if (res?.response?.enable_2fa) {
          navigate(`/pin-login/${customer?.contactlessId}`, {
            state: {
              verify: cust,
              data: res,
            },
          });
        } else if (customer?.contactlessId) {
          navigate(`/goods-services/${customer?.contactlessId || ""}`);
        }
        navigate( customer?.p2pId ? `/dashboard/${customer?.p2pId}` : `/dashboard`);
      }
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data?.response?.message || "Invalid Phone Number");
    }
  };

  useEffect(() => {
    if(!customer?.customer_external_id) {
      navigate(`/scan${pathname}`);
    }
  }, [customer])
  return (
    <>
      {loading && <Loader />}
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.loginContainer}`}
        justifyContent={"center"}
      >
        <Container>
          <Box component={"div"} className={`${LayoutStyle.BrandLogo}`} mb={5}>
            <Link to={`/`}>
              <GainLogo />
            </Link>
          </Box>
          <FormControl fullWidth variant="outlined" focused margin="normal">
            {/* <InputLabel htmlFor="outlined-adornment-amount">Enter your phone number</InputLabel> */}
            <PhoneInput
              isValid={(inputNumber, country, countries) => {
                return countries.some((country) => {
                  return (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  );
                });
              }}
              inputClass={``}
              inputStyle={{
                width: "100%",
              }}
              containerClass={`phone-validator`}
              inputProps={{
                name: "phone number",
                required: true,
                autoFocus: true,
              }}
              value={phone || ""}
              placeholder={"Enter your phone number"}
              onChange={(phone, country) => handleChanges(phone, country)}
              onlyCountries={["us"]}
              disableDropdown={true}
              enableLongNumbers={true}
              country="us"
              autoFormat={true}
              countryCodeEditable={false}
              enableSearch={true}
            />
            {error && (
              <p className={"text-center mt-2 text-red-500"}>{error}</p>
            )}
          </FormControl>

          <Box className="clear20" />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePhoneVerification}
          >
            Verify Phone
          </Button>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
export default Phone;
