import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import HeaderFull from "../components/headerFull";
import { getActivitiesServices } from "../services/ActivitiesServices";
import { useCustomer } from "../context/customerContext";
import { currencyFormat } from "../utils/Common";
import Loader from "../components/Loader";
import moment from "moment-timezone";
import LayoutStyle from "../scss/base.module.scss";
import { Link, useParams } from "react-router-dom";
import { groupBy, toString } from "lodash";
import Axios from "../config/Axios";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import CallReceivedOutlinedIcon from "@mui/icons-material/CallReceivedOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import KeyboardCapslockIcon from "@mui/icons-material/KeyboardCapslock";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import BottomNav from "../components/bottomNav";
import NoRecordFound from "../components/NoRecordFound";
import pusherSub from "../utils/Pusher";
import SendMoneyPendingModal from "../components/sendMoneyPendingModal";
import SendMoneyReceivedModal from "../components/sendMoneyReceivedModal";
import SendMoneyExpiredModal from "../components/sendMoneyExpiredModal";

function Activity() {
  const { customer, token, load } = useCustomer();
  const [activities, setActivities] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [trans, setTrans] = useState({});
  const [limit, setLimit] = useState(10);
  const [selectedTab, setSelectedTab] = useState("All");
  const [p2pExtId, setP2pExtId] = useState(null);
  const [isMoneyReceivedModal, setMoneyReceivedModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMoneyExpiredModal, setMoneyExpiredModal] = useState(false);
  const [p2pDetails, setP2pDetails] = useState(null);
  const [requestIdCopied, setRequestIdCopied] = React.useState(false);

  const { p2pId } = useParams();
  const getActivities = async (page) => {
    try {
      setLoading(true);
      const activity = await getActivitiesServices(
        customer?.customer_external_id,
        token,
        page,
        10,
      );
      setLoading(false);
      setActivities((prev) => prev.concat(activity?.trans));
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    if (customer?.customer_external_id && activities?.length <= 0) {
      getActivities(page);
    }
  }, [token, page, customer]);
  const hasEffectRun = useRef(false);
  useEffect(() => {
    if (!hasEffectRun.current && p2pId) {
      setLoading(true);
      Axios(token)
        .get(`/fetch-p2p-details/${p2pId}`)

        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  }, []);

  const fetchData = () => {
    if (customer?.customer_external_id && selectedTab === "Transactions") {
      setIsButtonLoading(true);
      getActivitiesServices(
        customer?.customer_external_id,
        token,
        1,
        limit,
      ).then((res) => {
        const transactions = res?.trans;
        const groupByDate = groupBy(transactions, (item) => {
          if (moment(item?.created_at).isSame(moment(), "day")) {
            return "Today";
          } else if (
            moment(item?.created_at).isSame(moment().subtract(1, "day"), "day")
          ) {
            return "Yesterday";
          } else {
            return moment(item?.created_at).format("LL");
          }
        });
        setTrans(groupByDate);
        setIsButtonLoading(false);
      });
    } else if (customer?.customer_external_id && selectedTab === "Requests") {
      Axios(token)
        .get("/fetch-send-money-reqs", {
          params: {
            limit,
          },
        })
        .then((res) => {
          const groupByDate = groupBy(res?.data?.response?.requests, (item) => {
            if (moment(item?.createdAt).isSame(moment(), "day")) {
              return "Today";
            } else if (
              moment(item?.createdAt).isSame(moment().subtract(1, "day"), "day")
            ) {
              return "Yesterday";
            } else {
              return moment(item?.createdAt).format("YYYY-MM-DD");
            }
          });
          setTrans(groupByDate);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let fullArray = [];
      Promise.all([
        getActivitiesServices(customer?.customer_external_id, token, 1),
        Axios(token).get("/fetch-send-money-reqs", {
          params: {
            limit: 10,
          },
        }),
      ])
        .then((res) => {
          // setSkeletonLoader(false);

          fullArray = fullArray.concat(
            res[0]?.trans,
            res[1]?.data?.response?.requests,
          );
          const groupByDate = groupBy(fullArray, (item) => {
            const createdAt = item.createdAt;
            if (moment(createdAt).isSame(moment(), "day")) {
              return "Today";
            } else if (
              moment(createdAt).isSame(moment().subtract(1, "day"), "day")
            ) {
              return "Yesterday";
            } else {
              return moment(createdAt).format("YYYY-MM-DD");
            }
          });

          // Sort the groupByDate object
          const sortedGroupByDate = Object.keys(groupByDate)
            .sort((a, b) => {
              if (a === "Today") return -1;
              if (b === "Today") return 1;
              if (a === "Yesterday") return -1;
              if (b === "Yesterday") return 1;
              return new Date(b) - new Date(a);
            })
            .reduce((obj, key) => {
              obj[key] = groupByDate[key];
              return obj;
            }, {});

          setTrans(sortedGroupByDate);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
    if (p2pExtId) {
      setLoading(true);

      Axios(token)
        .get(`/fetch-single-p2p-req/${p2pExtId}`)
        .then((res) => {
          setLoading(false);

          setP2pDetails(res?.data?.response);
        })
        .catch((e) => {
          setLoading(false);

          console.log(e);
        });
    }
  }, [load, token, limit, selectedTab, p2pExtId]);
  // console.log(load);
  const getTransactionForPusher = () => {
    getActivitiesServices(customer?.customer_external_id, token, 1).then(
      (res) => {
        const transactions = res?.trans;
        const groupByDate = groupBy(transactions, (item) => {
          if (moment(item?.created_at).isSame(moment(), "day")) {
            return "Today";
          } else if (
            moment(item?.created_at).isSame(moment().subtract(1, "day"), "day")
          ) {
            return "Yesterday";
          } else {
            return moment(item?.created_at).format("LL");
          }
        });
        setTrans(groupByDate);
      },
    );
  };
  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p",
      () => {
        getTransactionForPusher();
      },
    );
  }, []);
  //console.log

  return (
    <>
      {loading && <Loader show={loading} />}
      <HeaderFull />
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        <Container>
          <div
            className={
              "sticky top-[3.75rem] z-10 bg-white flex space-x-2 justify-between w-full py-2"
            }
          >
            <div
              className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                selectedTab === "All" ? "text-white bg-[#00365B]" : ""
              } `}
              onClick={() => {
                setTrans([]);
                setSelectedTab("All");
              }}
            >
              <p className={"text-sm"}>All</p>
            </div>
            <div
              className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                selectedTab === "Requests" ? "text-white bg-[#00365B]" : ""
              } `}
              onClick={() => {
                setTrans([]);

                setSelectedTab("Requests");
              }}
            >
              <p className={"text-sm"}>Requests</p>
            </div>
            <div
              className={`border rounded-md border-blue-500 w-1/3 text-center py-1 cursor-pointer ${
                selectedTab === "Transactions" ? "text-white bg-[#00365B]" : ""
              } `}
              onClick={() => {
                setTrans([]);

                setSelectedTab("Transactions");
              }}
            >
              <p className={"text-sm"}>Transactions</p>
            </div>
          </div>
          {loading ? (
            <>
              <ul className={LayoutStyle.transactionsList}>
                <li>
                  <Typography variant="h5" fontWeight={500} mb={1.5}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={20}
                      width={150}
                    />
                  </Typography>
                  <Card elevation={3}>
                    <CardContent>
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 1, opacity: 0.5 }} />
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                      <Divider sx={{ my: 1, opacity: 0.5 }} />
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </li>
                <li>
                  <Typography variant="h5" fontWeight={500} mb={1.5}>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={20}
                      width={150}
                    />
                  </Typography>
                  <Card elevation={3}>
                    <CardContent>
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                      <Grid container direction={"row"} spacing={2}>
                        <Grid item xs="2">
                          <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item xs={7}>
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={20}
                            width={150}
                            sx={{ mb: "0.5rem" }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            height={10}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </li>
              </ul>
            </>
          ) : (
            <>
              {activities?.length > 0 ? (
                <>
                  {/*<Typography variant="h4" fontWeight={600} gutterBottom>*/}
                  {/*  All Activities*/}
                  {/*</Typography>*/}
                  <ul className={LayoutStyle.transactionsList}>
                    {Object.keys(trans).length > 0 ? (
                      <>
                        {/*{Object.keys(trans)?.map((key, index) => (*/}
                        {/*  <li key={index}>*/}
                        {/*    <Typography variant="h5" mb={1.5}>*/}
                        {/*      {key}*/}
                        {/*    </Typography>*/}
                        {/*    <>*/}
                        {/*      <Card elevation={3}>*/}
                        {/*        <CardContent>*/}
                        {/*          {trans[key]?.map((item, index) => (*/}
                        {/*            <>*/}
                        {/*              <Grid*/}
                        {/*                container*/}
                        {/*                direction={"row"}*/}
                        {/*                spacing={2}*/}
                        {/*                key={index}*/}
                        {/*              >*/}
                        {/*                <Grid item xs={2}>*/}
                        {/*                  <Avatar*/}
                        {/*                    color="primary"*/}
                        {/*                    sx={{ padding: "0.625rem" }}*/}
                        {/*                  >*/}
                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "LOAD_FUND" ||*/}
                        {/*                    item?.transactionFor === "TIPS" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "refund_to_card" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "refund_to_wallet" ? (*/}
                        {/*                      <PaymentsOutlinedIcon color="white" />*/}
                        {/*                    ) : (*/}
                        {/*                      <>*/}
                        {/*                        {item?.store*/}
                        {/*                          ?.account_number && (*/}
                        {/*                          <ReceiptLongOutlinedIcon color="white" />*/}
                        {/*                        )}*/}
                        {/*                      </>*/}
                        {/*                    )}*/}

                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "SEND_MONEY" && (*/}
                        {/*                      <CallMadeOutlinedIcon color="white" />*/}
                        {/*                    )}*/}
                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "RECEIVE_MONEY" && (*/}
                        {/*                      <CallReceivedOutlinedIcon color="white" />*/}
                        {/*                    )}*/}
                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "withdrawal_request" && (*/}
                        {/*                      <KeyboardCapslockIcon color="white" />*/}
                        {/*                    )}*/}
                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "deposit_request" && (*/}
                        {/*                      <AssuredWorkloadIcon color="white" />*/}
                        {/*                    )}*/}
                        {/*                  </Avatar>*/}
                        {/*                </Grid>*/}
                        {/*                <Grid item xs={7}>*/}
                        {/*                  <Typography variant="h5" mb={0}>*/}
                        {/*                    <>*/}
                        {/*                      {item?.transactionFor ===*/}
                        {/*                      "LOAD_FUND" ? (*/}
                        {/*                        `Card Charged`*/}
                        {/*                      ) : (*/}
                        {/*                        <>{item?.transaction_type}</>*/}
                        {/*                      )}*/}
                        {/*                    </>*/}
                        {/*                  </Typography>*/}
                        {/*                  {item?.customer_name && (*/}
                        {/*                    <>*/}
                        {/*                      <Typography*/}
                        {/*                        variant="h4"*/}
                        {/*                        gutterBottom*/}
                        {/*                      >*/}
                        {/*                        {item?.transactionFor ===*/}
                        {/*                          "SEND_MONEY" && `Sent to`}*/}
                        {/*                        {item?.transactionFor ===*/}
                        {/*                          "RECEIVE_MONEY" &&*/}
                        {/*                          `Received from`}*/}
                        {/*                      </Typography>*/}
                        {/*                      <Typography*/}
                        {/*                        variant="h6"*/}
                        {/*                        mb={0}*/}
                        {/*                        textTransform={"capitalize"}*/}
                        {/*                      >*/}
                        {/*                        {item?.customer_name}*/}
                        {/*                      </Typography>*/}
                        {/*                    </>*/}
                        {/*                  )}*/}
                        {/*                  <Typography*/}
                        {/*                    variant="body2"*/}
                        {/*                    mb={0}*/}
                        {/*                    mt={0.5}*/}
                        {/*                  >*/}
                        {/*                    {item?.transactionFor ===*/}
                        {/*                      "LOAD_FUND" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "withdrawal_request" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "deposit_request" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "RECEIVE_MONEY" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "SEND_MONEY" ? (*/}
                        {/*                      <Typography*/}
                        {/*                        variant="body2"*/}
                        {/*                        mb={0}*/}
                        {/*                        mt={0.5}*/}
                        {/*                      >*/}
                        {/*                        {moment(*/}
                        {/*                          item?.created_at,*/}
                        {/*                        ).format("lll")}*/}
                        {/*                      </Typography>*/}
                        {/*                    ) : (*/}
                        {/*                      <>*/}
                        {/*                        {item?.store?.city*/}
                        {/*                          ? item?.store?.city + " |"*/}
                        {/*                          : ""}*/}
                        {/*                        {item?.store?.state || ""}*/}
                        {/*                      </>*/}
                        {/*                    )}*/}
                        {/*                  </Typography>*/}
                        {/*                </Grid>*/}
                        {/*                <Grid item xs={3}>*/}
                        {/*                  {item?.transactionFor ===*/}
                        {/*                    "pay_for_goods_and_services" ||*/}
                        {/*                  item?.transactionFor ===*/}
                        {/*                    "withdrawal_request" ||*/}
                        {/*                  item?.transactionFor ===*/}
                        {/*                    "deposit_request" ? (*/}
                        {/*                    <Typography*/}
                        {/*                      textAlign={"right"}*/}
                        {/*                      variant="h5"*/}
                        {/*                      gutterBottom*/}
                        {/*                      color={"error"}*/}
                        {/*                    >*/}
                        {/*                      {currencyFormat(item?.amount)}*/}
                        {/*                    </Typography>*/}
                        {/*                  ) : (*/}
                        {/*                    <Typography*/}
                        {/*                      textAlign={"right"}*/}
                        {/*                      variant="h5"*/}
                        {/*                      gutterBottom*/}
                        {/*                      sx={{ color: "green" }}*/}
                        {/*                    >*/}
                        {/*                      {currencyFormat(*/}
                        {/*                        item?.total_amount ||*/}
                        {/*                          item?.amount,*/}
                        {/*                      )}*/}
                        {/*                    </Typography>*/}
                        {/*                  )}*/}
                        {/*                  {item?.funds != null &&*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "LOAD_FUND" && (*/}
                        {/*                      <Typography*/}
                        {/*                        variant="body2"*/}
                        {/*                        mb={0}*/}
                        {/*                        className={`!text-right`}*/}
                        {/*                      >*/}
                        {/*                        <Link*/}
                        {/*                          underline="none"*/}
                        {/*                          style={{*/}
                        {/*                            color: "green",*/}
                        {/*                            whiteSpace: "nowrap",*/}
                        {/*                          }}*/}
                        {/*                          to={`/receipt/${item?.funds}`}*/}
                        {/*                        >*/}
                        {/*                          {" "}*/}
                        {/*                          View Receipt{" "}*/}
                        {/*                        </Link>*/}
                        {/*                      </Typography>*/}
                        {/*                    )}*/}
                        {/*                  {(item?.transactionFor ===*/}
                        {/*                    "withdrawal_request" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "deposit_request" ||*/}
                        {/*                    item?.transactionFor ===*/}
                        {/*                      "pay_for_goods_and_services") && (*/}
                        {/*                    <Typography*/}
                        {/*                      variant="body2"*/}
                        {/*                      mb={0}*/}
                        {/*                      mt={0.5}*/}
                        {/*                      textAlign={"right"}*/}
                        {/*                    >*/}
                        {/*                      {item?.status}*/}
                        {/*                    </Typography>*/}
                        {/*                  )}*/}
                        {/*                </Grid>*/}
                        {/*              </Grid>*/}
                        {/*              {trans[key].length > 1 && (*/}
                        {/*                <Divider sx={{ my: 1, opacity: 0.5 }} />*/}
                        {/*              )}*/}
                        {/*            </>*/}
                        {/*          ))}*/}
                        {/*        </CardContent>*/}
                        {/*      </Card>*/}
                        {/*    </>*/}
                        {/*  </li>*/}
                        {/*))}*/}
                        {Object.keys(trans)?.map((key, index) => (
                          <li key={index + 1}>
                            <Typography variant="h5" mb={1.5}>
                              {key}
                            </Typography>
                            <>
                              <Card elevation={3}>
                                <CardContent>
                                  {trans[key]?.map((item, index) => (
                                    <div key={index}>
                                      <Grid
                                        container
                                        direction={"row"}
                                        spacing={2}
                                        key={index}
                                        onClick={() => {
                                          setP2pExtId(
                                            item?.friendsandfamily_external_id,
                                          );

                                          if (
                                            item?.type === "SEND_MONEY" &&
                                            item?.payment_status === "RECEIVED"
                                          ) {
                                            setMoneyReceivedModal(true);
                                          }
                                          if (
                                            item?.type === "SEND_MONEY" &&
                                            item?.payment_status === "PENDING"
                                          ) {
                                            setModalOpen(true);
                                          }
                                          if (
                                            item?.type === "SEND_MONEY" &&
                                            item?.payment_status === "EXPIRED"
                                          ) {
                                            setMoneyExpiredModal(true);
                                          }
                                          // if (item?.transactionFor === "SEND_MONEY") {
                                          //   setMoneyReceivedModal(true);
                                          // }
                                        }}
                                      >
                                        <Grid item xs={2}>
                                          <Avatar
                                            color="primary"
                                            sx={{ padding: "0.625rem" }}
                                          >
                                            {(item?.transactionFor ===
                                              "LOAD_FUND" ||
                                              item?.transactionFor === "TIPS" ||
                                              item?.transactionFor ===
                                                "refund_to_card" ||
                                              item?.transactionFor ===
                                                "refund_to_wallet") && (
                                              <PaymentsOutlinedIcon color="white" />
                                            )}

                                            {item?.transactionFor ===
                                              "pay_for_goods_and_services" &&
                                              item?.store?.account_number && (
                                                <ReceiptLongOutlinedIcon color="white" />
                                              )}

                                            {(item?.transactionFor ===
                                              "SEND_MONEY" ||
                                              item?.transactionFor ===
                                                "returned_to_customer" ||
                                              item?.type === "SEND_MONEY") && (
                                              <CallMadeOutlinedIcon color="white" />
                                            )}
                                            {item?.transactionFor ===
                                              "RECEIVE_MONEY" && (
                                              <CallReceivedOutlinedIcon color="white" />
                                            )}
                                            {item?.transactionFor ===
                                              "withdrawal_request" && (
                                              <KeyboardCapslockIcon color="white" />
                                            )}
                                            {item?.transactionFor ===
                                              "deposit_request" && (
                                              <AssuredWorkloadIcon color="white" />
                                            )}
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs={7}>
                                          <Typography variant="h5" mb={0}>
                                            <>
                                              {item?.transactionFor ===
                                              "LOAD_FUND" ? (
                                                `Card Charged`
                                              ) : (
                                                <>{item?.transaction_type}</>
                                              )}
                                            </>
                                          </Typography>
                                          {item?.customer_name && (
                                            <>
                                              <Typography
                                                variant="h4"
                                                gutterBottom
                                              >
                                                {item?.transactionFor ===
                                                  "SEND_MONEY" && `Sent to`}
                                                {item?.transactionFor ===
                                                  "RECEIVE_MONEY" &&
                                                  `Received from`}
                                              </Typography>
                                              <Typography
                                                variant="h6"
                                                mb={0}
                                                textTransform={"capitalize"}
                                              >
                                                {item?.customer_name}
                                              </Typography>
                                            </>
                                          )}
                                          {item?.type === "SEND_MONEY" && (
                                            <>
                                              <Typography
                                                variant="h4"
                                                gutterBottom
                                              >
                                                Funds Transfer Requested
                                              </Typography>
                                              <Typography
                                                variant="h6"
                                                mb={0}
                                                textTransform={"capitalize"}
                                              >
                                                {item?.payment_status ===
                                                "RECEIVED"
                                                  ? item?.receiver?.fullName
                                                  : item?.receiver_name}
                                              </Typography>
                                              <Typography
                                                variant="body2"
                                                mb={0}
                                                mt={0.5}
                                              >
                                                {moment(item?.createdAt).format(
                                                  "lll",
                                                )}
                                              </Typography>
                                            </>
                                          )}
                                          {/*{item?.type === "SEND_MONEY" && (*/}
                                          {/*  <Typography*/}
                                          {/*    variant="body2"*/}
                                          {/*    mb={0}*/}
                                          {/*    mt={0.5}*/}
                                          {/*  >*/}
                                          {/*    <Typography*/}
                                          {/*      variant="body2"*/}
                                          {/*      mb={0}*/}
                                          {/*      mt={0.5}*/}
                                          {/*    >*/}
                                          {/*      {moment(item?.created_at).format(*/}
                                          {/*        "lll",*/}
                                          {/*      )}*/}
                                          {/*    </Typography>*/}
                                          {/*  </Typography>*/}
                                          {/*)}*/}
                                          <Typography
                                            variant="body2"
                                            mb={0}
                                            mt={0.5}
                                          >
                                            {item?.transactionFor ===
                                              "LOAD_FUND" ||
                                            item?.transactionFor ===
                                              "withdrawal_request" ||
                                            item?.transactionFor ===
                                              "deposit_request" ||
                                            item?.transactionFor ===
                                              "RECEIVE_MONEY" ? (
                                              <Typography
                                                variant="body2"
                                                mb={0}
                                                mt={0.5}
                                              >
                                                {moment(
                                                  item?.created_at,
                                                ).format("lll")}
                                              </Typography>
                                            ) : (
                                              <>
                                                {item?.store?.city
                                                  ? item?.store?.city + " |"
                                                  : ""}
                                                {item?.store?.state || ""}
                                              </>
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                          {item?.transactionFor ===
                                            "pay_for_goods_and_services" ||
                                          item?.transactionFor ===
                                            "withdrawal_request" ||
                                          item?.transactionFor ===
                                            "deposit_request" ||
                                          item?.transactionFor ===
                                            "returned_to_customer" ? (
                                            <Typography
                                              textAlign={"right"}
                                              variant="h5"
                                              gutterBottom
                                              color={"error"}
                                            >
                                              {currencyFormat(item?.amount)}
                                            </Typography>
                                          ) : (
                                            <>
                                              <Typography
                                                textAlign={"right"}
                                                variant="h5"
                                                gutterBottom
                                                sx={{ color: "green" }}
                                              >
                                                {currencyFormat(
                                                  item?.total_amount ||
                                                    item?.amount,
                                                )}
                                              </Typography>
                                              {item?.type && (
                                                <div
                                                  className={`rounded-lg ${
                                                    item?.payment_status ===
                                                    "PENDING"
                                                      ? "bg-yellow-200"
                                                      : item?.payment_status ===
                                                        "EXPIRED"
                                                      ? "bg-red-200"
                                                      : "bg-green-200"
                                                  }`}
                                                >
                                                  <p
                                                    className={`text-xs text-center ${
                                                      item?.payment_status ===
                                                      "PENDING"
                                                        ? "text-yellow-600"
                                                        : item?.payment_status ===
                                                          "EXPIRED"
                                                        ? "text-red-600"
                                                        : "text-green-600"
                                                    }`}
                                                  >
                                                    {item?.payment_status}
                                                  </p>
                                                </div>
                                              )}
                                            </>
                                          )}
                                          {item?.funds != null &&
                                            item?.transactionFor ===
                                              "LOAD_FUND" && (
                                              <Typography
                                                variant="body2"
                                                mb={0}
                                                className={`!text-right`}
                                              >
                                                <Link
                                                  underline="none"
                                                  style={{
                                                    color: "green",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  to={`/receipt/${item?.funds}`}
                                                >
                                                  {" "}
                                                  View Receipt{" "}
                                                </Link>
                                              </Typography>
                                            )}
                                          {(item?.transactionFor ===
                                            "withdrawal_request" ||
                                            item?.transactionFor ===
                                              "deposit_request" ||
                                            item?.transactionFor ===
                                              "pay_for_goods_and_services") && (
                                            <Typography
                                              variant="body2"
                                              mb={0}
                                              mt={0.5}
                                              textAlign={"right"}
                                            >
                                              {item?.status}
                                            </Typography>
                                          )}
                                        </Grid>
                                      </Grid>
                                      {trans[key].length > 1 && (
                                        <Divider sx={{ my: 1, opacity: 0.5 }} />
                                      )}
                                    </div>
                                  ))}
                                </CardContent>
                              </Card>
                            </>
                          </li>
                        ))}
                      </>
                    ) : (
                      <>
                        <li>
                          <Typography variant="h5" fontWeight={500} mb={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                            />
                          </Typography>
                          <Card elevation={3}>
                            <CardContent>
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider sx={{ my: 1, opacity: 0.5 }} />
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider sx={{ my: 1, opacity: 0.5 }} />
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </li>
                        <li>
                          <Typography variant="h5" fontWeight={500} mb={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rounded"
                              height={20}
                              width={150}
                            />
                          </Typography>
                          <Card elevation={3}>
                            <CardContent>
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid container direction={"row"} spacing={2}>
                                <Grid item xs="2">
                                  <Skeleton
                                    variant="circular"
                                    width={40}
                                    height={40}
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={20}
                                    width={150}
                                    sx={{ mb: "0.5rem" }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    height={10}
                                    width={50}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </li>
                      </>
                    )}
                  </ul>
                  <SendMoneyPendingModal
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    p2pDetails={p2pDetails}
                    isLoading={loading}
                    requestIdCopied={requestIdCopied}
                    setRequestIdCopied={setRequestIdCopied}
                    callBack={fetchData}
                  />
                  <SendMoneyReceivedModal
                    isMoneyReceivedModal={isMoneyReceivedModal}
                    setMoneyReceivedModal={setMoneyReceivedModal}
                    p2pDetails={p2pDetails}
                    isLoading={loading}
                    requestIdCopied={requestIdCopied}
                    setRequestIdCopied={setRequestIdCopied}
                  />
                  <SendMoneyExpiredModal
                    isMoneyExpiredModal={isMoneyExpiredModal}
                    setMoneyExpiredModal={setMoneyExpiredModal}
                    p2pDetails={p2pDetails}
                    isLoading={loading}
                    requestIdCopied={requestIdCopied}
                    setRequestIdCopied={setRequestIdCopied}
                  />
                  <Button
                    className={`!mt-5`}
                    size="large"
                    name={`Gain Funds`}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setLimit(limit + 10)}
                    disabled={isButtonLoading}
                  >
                    {isButtonLoading ? (
                      <CircularProgress color={"info"} />
                    ) : (
                      "Load more"
                    )}
                  </Button>
                </>
              ) : (
                <NoRecordFound title={`No activities found!`} />
              )}
            </>
          )}
        </Container>
      </Box>
      <BottomNav />
    </>
  );
}

export default Activity;
