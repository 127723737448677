import React from "react";

export default function PayIcon() {
    return (
        <>
            <path d="m423.963 255.569c-26.219-9.501-55.305-7.389-79.898 5.779l-40.09 18.034c-3.239-18.464-17.42-32.339-34.923-32.872-.103-.003-60.11-.68-60.11-.68-48.03-13.175-79.238 3.754-97.041 20.438-3.641 3.412-6.843 6.925-9.661 10.39-4.804-5.174-13.284-6.556-19.468-3.156l-54.471 29.942c-7.065 3.884-10.139 12.405-7.176 19.895l53.234 134.572c3.498 8.842 14.248 12.75 22.636 8.139l54.471-29.942c5.106-2.807 8.112-8.037 8.277-13.548h81.298c13.261 0 26.353-3.483 37.861-10.073 0 0 152.9-87.708 153.763-88.492 21.412-19.479 22.072-57.275-8.702-68.426-26.219-9.501 12.276 4.448 0 0zm-326.481 173.681-41.364-104.566 26.164-14.381 41.364 104.565zm314.723-129.996-149.255 85.473c-6.661 3.815-14.238 5.831-21.912 5.831h-92.547l-31.074-78.554c2.662-5.424 7.905-14.424 16.42-22.403 17.439-16.342 40.425-20.524 68.324-12.43 1.395.405 2.839.618 4.291.635l61.684.694c1.575.136 4.482 3.637 4.482 8.945 0 5.464-3.002 8.948-4.572 8.948h-60.607v32.002h60.607c7.508 0 14.495-2.539 20.311-6.889l69.4-31.218c.368-.166.728-.344 1.082-.537 16.648-9.052 36.4-10.547 54.188-4.101 7.039 2.552 1.954 10.786-.822 13.604z">
            </path>

            <path d="m315.895 230.583c-63.567 0-115.283-51.72-115.283-115.292 0-63.571 51.715-115.291 115.283-115.291s115.283 51.72 115.283 115.291c0 63.572-51.716 115.292-115.283 115.292zm0-198.581c-45.893 0-83.229 37.363-83.229 83.289s37.337 83.289 83.229 83.289c45.894 0 83.23-37.364 83.23-83.289s-37.337-83.289-83.23-83.289z">
            </path>

            <path d="m323.339 102.249c-12.745-5.234-13.731-7.483-13.731-10.09 0-1.665.827-5.536 8.482-5.536 7.003 0 15.729 3.931 21.883 7.982l8.561-22.44c-6.112-3.891-13.54-7.234-20.485-8.303v-14.593h-22.826v16.013c-13.56 4.274-21.916 15.114-21.916 28.608 0 17.872 14.298 25.851 28.264 31.29 11.158 4.489 12.093 7.925 12.093 10.794 0 4.413-4.067 7.264-10.361 7.264-8.207 0-17.75-4.734-24.431-9.914l-8.237 22.8c6.812 5.282 14.698 8.768 23.571 10.069v15.121h22.968v-16.46c13.984-4.453 22.934-16.038 22.934-29.89 0-19.39-14.305-27.668-26.769-32.715z">

            </path>
        </>

    );
}
