/* eslint-disable react/no-unknown-property */
import React from "react";

function ConfirmPayment() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 77 74"
        fill="none"
      >
        <ellipse
          cx="38.3704"
          cy="37"
          rx="38.3704"
          ry="37"
          fill="url(#paint0_linear_9496_2916)"
        />
        <path
          opacity="0.5"
          d="M29.1195 39L26.7655 41.9725L19 54H23.6068L29.0686 45.6009L34.4002 53.9926H39L31.3724 41.9725L29.1188 39H29.1195Z"
          fill="black"
        />
        <path
          d="M41.2667 22.153L29.1759 38.3768C28.9865 38.6015 28.6559 38.5646 28.5104 38.3029L23.7853 30.3065C23.7087 30.1687 23.573 30.0848 23.4268 30.0848H19.4209C19.0812 30.0848 18.882 30.5074 19.0763 30.816L26.5255 42.599L28.4491 45.7927C28.6155 46.0691 28.9824 46.0691 29.1488 45.7927L31.0723 42.599L41.9245 22.7273C42.21 22.2515 41.6189 21.7358 41.2667 22.1538V22.153Z"
          fill="#002863"
        />
        <path
          d="M55.5927 34.2725H49.8621V55H44.8558V34.2725H41V31.7486L44.8558 30.2074V28.4876C44.8558 26.388 45.1683 24.7202 45.7931 23.4844C46.4322 22.2485 47.3412 21.3625 48.5199 20.8264C49.7129 20.2755 51.1331 20 52.7806 20C53.8599 20 54.847 20.0968 55.7418 20.2904C56.6365 20.4691 57.3892 20.6775 58 20.9158L56.7004 24.8915C56.2175 24.7277 55.6779 24.5788 55.0814 24.4448C54.4849 24.2959 53.8458 24.2214 53.1641 24.2214C52.0279 24.2214 51.19 24.5938 50.6502 25.3383C50.1248 26.0828 49.8621 27.1697 49.8621 28.5993V30.2744H55.5927V34.2725Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9496_2916"
            x1="38.3704"
            y1="0"
            x2="38.3704"
            y2="74"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0068FF" />
            <stop offset="1" stop-color="#0045A9" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}

export default ConfirmPayment;
