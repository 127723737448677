import React from "react";

export default function ListEditIcon(props) {
  return (
    <> 
        <path d="m506.143 273.726-56.568-56.569c-3.751-3.751-8.838-5.858-14.143-5.858s-10.392 2.107-14.143 5.858l-172.862 172.863c-14.006 14.006-23.462 31.676-27.347 51.099l-9.392 46.959c-1.312 6.557.741 13.336 5.469 18.064 3.788 3.788 8.891 5.858 14.143 5.858 1.304 0 2.618-.128 3.922-.389l46.959-9.392h.001c19.423-3.885 37.092-13.341 51.099-27.347l172.862-172.862c7.81-7.81 7.81-20.473 0-28.284zm-201.148 172.862c-8.403 8.404-19.005 14.078-30.658 16.408l-17.542 3.508 3.508-17.542c2.331-11.654 8.005-22.256 16.409-30.66l158.72-158.72 28.284 28.284z" fill={props.fill? (props.fill):"black"}>
        </path>
        <path d="m149.729 472h-89.729c-11.028 0-20-8.972-20-20v-392c0-11.028 8.972-20 20-20h180v60c0 33.084 26.916 60 60 60h60v13.602c0 11.046 8.954 20 20 20s20-8.954 20-20v-33.603c0-5.414-2.284-10.605-5.868-14.151l-119.989-119.99c-3.691-3.69-8.86-5.858-14.143-5.858h-200c-33.084 0-60 26.916-60 60v392c0 33.084 26.916 60 60 60h89.729c11.046 0 20-8.954 20-20s-8.955-20-20-20zm150.271-352c-11.028 0-20-8.972-20-20v-31.716l51.716 51.716z" fill={props.fill? (props.fill):"black"}>
        </path>
        <path d="m320 212c0-11.046-8.954-20-20-20h-200c-11.046 0-20 8.954-20 20s8.954 20 20 20h200c11.046 0 20-8.954 20-20z" fill={props.fill? (props.fill):"black"}>
        </path>
        <path d="m100 272c-11.046 0-20 8.954-20 20s8.954 20 20 20h161.602c11.046 0 20-8.954 20-20s-8.954-20-20-20z" fill={props.fill? (props.fill):"black"}>
        </path>
        <path d="m185.972 352h-85.972c-11.046 0-20 8.954-20 20s8.954 20 20 20h85.972c11.046 0 20-8.954 20-20s-8.954-20-20-20z" fill={props.fill? (props.fill):"black"}>
        </path>
    </>

  );
}
