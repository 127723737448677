import React, { createContext, useContext, useState } from 'react';

const ProjectGuideContext = createContext(null);

export function ProjectGuideContextProvider({ children }) {
  const [stepIndex, setStepIndex] = useState(0)

  const value = {
    stepIndex,
    setStepIndex
  }
  return <ProjectGuideContext.Provider value={value}>{children}</ProjectGuideContext.Provider>
}

export const useProjectGuide = () => useContext(ProjectGuideContext);