/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  Button,
  Typography,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Footer from "../components/footer";
import HeaderFull from "../components/headerFull";

import { useNavigate } from "react-router-dom";
import { useCustomer } from "../context/customerContext";
import { checkLogin } from "../components/helpers/checkLogin";
function VerifyBank() {

  const navigate = useNavigate();
  const { customer } = useCustomer();
  useEffect(()=>{
    checkLogin(customer, navigate);
  },[]);
  const VerifyBank = () => {
    navigate("/verify-bank");
  };
  return (
    <>
      <HeaderFull />
      <div className="bodyContent">

        <Typography variant="h3">Verify bank account</Typography>
        <div className="formFiled">
          <Typography variant="body1" className="!mb-2 ">Account 1</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput />
          </FormControl>
        </div>
        <div className="formFiled">
          <Typography variant="body1" className="!mb-2 ">Account 2</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput />
          </FormControl>
        </div>
        <div className="clear20"></div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="small"
          className="fs-14"
          onClick={VerifyBank}
        >
          Verify bank
        </Button>

      </div>
      <Footer />
    </>
  );
}
export default VerifyBank;
