import React from "react";

export default function PinIcon() {
  return (
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 32" fill="none">
    <g opacity="0.5">
        <path d="M37.9698 0H2.03023C0.910781 0 0 0.910781 0 2.03023V7.55937C0 8.67883 0.910781 9.58961 2.03023 9.58961H37.9698C39.0892 9.58961 40 8.67883 40 7.55937V2.03023C40 0.910781 39.0892 0 37.9698 0ZM38.7041 7.55937C38.7041 7.9643 38.3747 8.29375 37.9698 8.29375H2.03023C1.62531 8.29375 1.29586 7.9643 1.29586 7.55937V2.03023C1.29586 1.62531 1.62531 1.29586 2.03023 1.29586H37.9698C38.3747 1.29586 38.7041 1.62531 38.7041 2.03023V7.55937Z" fill="black"/>
        <path d="M7.0934 4.7949L8.01754 3.87076C8.27059 3.61779 8.27059 3.2074 8.01754 2.95451C7.76449 2.70146 7.35418 2.70146 7.10129 2.95451L6.17715 3.87865L5.25301 2.95443C4.99996 2.70139 4.58965 2.70139 4.33676 2.95443C4.08371 3.20748 4.08371 3.61779 4.33676 3.87068L5.2609 4.79482L4.33676 5.71896C4.08371 5.97201 4.08371 6.38232 4.33676 6.63521C4.46324 6.76178 4.6291 6.82506 4.79488 6.82506C4.96066 6.82506 5.12652 6.76178 5.25301 6.63521L6.17715 5.71107L7.10129 6.63521C7.22777 6.76178 7.39363 6.82506 7.55941 6.82506C7.7252 6.82506 7.89105 6.76178 8.01754 6.63521C8.27059 6.38217 8.27059 5.97185 8.01754 5.71896L7.0934 4.7949Z" fill="black"/>
        <path d="M12.6227 4.79482L13.5468 3.87068C13.7999 3.61771 13.7999 3.20732 13.5468 2.95443C13.2938 2.70139 12.8835 2.70139 12.6306 2.95443L11.7064 3.87857L10.7823 2.95443C10.5293 2.70139 10.1189 2.70139 9.86605 2.95443C9.61301 3.20748 9.61301 3.61779 9.86605 3.87068L10.7902 4.79482L9.86605 5.71896C9.61301 5.97201 9.61301 6.38232 9.86605 6.63521C9.99254 6.7617 10.1584 6.82506 10.3242 6.82506C10.49 6.82506 10.6558 6.76178 10.7823 6.63521L11.7064 5.71107L12.6306 6.63521C12.7571 6.7617 12.9229 6.82506 13.0887 6.82506C13.2545 6.82506 13.4204 6.76178 13.5468 6.63521C13.7999 6.38217 13.7999 5.97185 13.5468 5.71896L12.6227 4.79482Z" fill="black"/>
        <path d="M18.1515 4.79482L19.0756 3.87068C19.3287 3.61771 19.3287 3.20732 19.0756 2.95443C18.8226 2.70139 18.4123 2.70139 18.1594 2.95443L17.2353 3.87857L16.3111 2.95443C16.0581 2.70139 15.6478 2.70139 15.3949 2.95443C15.1418 3.20748 15.1418 3.61779 15.3949 3.87068L16.319 4.79482L15.3949 5.71896C15.1418 5.97201 15.1418 6.38232 15.3949 6.63521C15.5213 6.7617 15.6872 6.82506 15.853 6.82506C16.0188 6.82506 16.1846 6.76178 16.3111 6.63521L17.2353 5.71107L18.1594 6.63521C18.2859 6.7617 18.4517 6.82506 18.6175 6.82506C18.7833 6.82506 18.9492 6.76178 19.0756 6.63521C19.3287 6.38217 19.3287 5.97185 19.0756 5.71896L18.1515 4.79482Z" fill="black"/>
        <path d="M23.6808 4.79482L24.6049 3.87068C24.858 3.61771 24.858 3.20732 24.6049 2.95443C24.3519 2.70139 23.9416 2.70139 23.6887 2.95443L22.7646 3.87857L21.8404 2.95443C21.5874 2.70139 21.1771 2.70139 20.9242 2.95443C20.6711 3.20748 20.6711 3.61779 20.9242 3.87068L21.8483 4.79482L20.9242 5.71896C20.6711 5.97201 20.6711 6.38232 20.9242 6.63521C21.0506 6.7617 21.2165 6.82506 21.3823 6.82506C21.5481 6.82506 21.7139 6.76178 21.8404 6.63521L22.7646 5.71107L23.6887 6.63521C23.8152 6.7617 23.981 6.82506 24.1468 6.82506C24.3126 6.82506 24.4785 6.76178 24.6049 6.63521C24.858 6.38217 24.858 5.97185 24.6049 5.71896L23.6808 4.79482Z" fill="black"/>
        <path d="M30.1342 5.71896L29.2101 4.79482L30.1342 3.87068C30.3873 3.61771 30.3873 3.20732 30.1342 2.95443C29.8812 2.70139 29.4709 2.70139 29.218 2.95443L28.2938 3.87857L27.3697 2.95443C27.1167 2.70139 26.7063 2.70139 26.4535 2.95443C26.2004 3.20748 26.2004 3.61779 26.4535 3.87068L27.3776 4.79482L26.4535 5.71896C26.2004 5.97201 26.2004 6.38232 26.4535 6.63521C26.5799 6.7617 26.7458 6.82506 26.9116 6.82506C27.0774 6.82506 27.2432 6.76178 27.3697 6.63521L28.2938 5.71107L29.218 6.63521C29.3445 6.7617 29.5103 6.82506 29.6761 6.82506C29.8419 6.82506 30.0078 6.76178 30.1342 6.63521C30.3873 6.38217 30.3873 5.97193 30.1342 5.71896Z" fill="black"/>
        <path d="M34.7389 4.79482L35.663 3.87068C35.9161 3.61771 35.9161 3.20732 35.663 2.95443C35.41 2.70139 34.9997 2.70139 34.7468 2.95443L33.8227 3.87857L32.8985 2.95443C32.6455 2.70139 32.2352 2.70139 31.9823 2.95443C31.7292 3.20748 31.7292 3.61779 31.9823 3.87068L32.9064 4.79482L31.9823 5.71896C31.7292 5.97201 31.7292 6.38232 31.9823 6.63521C32.1087 6.7617 32.2746 6.82506 32.4404 6.82506C32.6062 6.82506 32.772 6.76178 32.8985 6.63521L33.8227 5.71107L34.7468 6.63521C34.8733 6.7617 35.0391 6.82506 35.2049 6.82506C35.3707 6.82506 35.5366 6.76178 35.663 6.63521C35.9161 6.38217 35.9161 5.97185 35.663 5.71896L34.7389 4.79482Z" fill="black"/>
        <path d="M37.9698 11.0581H2.03023C0.910781 11.0581 0 11.9689 0 13.0883V18.6175C0 19.7369 0.910781 20.6477 2.03023 20.6477H37.9698C39.0892 20.6477 40 19.7369 40 18.6175V13.0883C40 11.9689 39.0892 11.0581 37.9698 11.0581ZM38.7041 18.6176C38.7041 19.0225 38.3747 19.3519 37.9698 19.3519H2.03023C1.62531 19.3519 1.29586 19.0225 1.29586 18.6176V13.0884C1.29586 12.6835 1.62531 12.354 2.03023 12.354H37.9698C38.3747 12.354 38.7041 12.6835 38.7041 13.0884V18.6176Z" fill="black"/>
        <path d="M37.9699 22.1162H26.9116C25.7921 22.1162 24.8813 23.027 24.8813 24.1464V29.6756C24.8813 30.795 25.7921 31.7058 26.9116 31.7058H37.9699C39.0893 31.7058 40.0001 30.795 40.0001 29.6756V24.1464C40.0002 23.0269 39.0894 22.1162 37.9699 22.1162ZM38.7043 29.6756C38.7043 30.0805 38.3749 30.41 37.9699 30.41H26.9116C26.5067 30.41 26.1772 30.0806 26.1772 29.6756V24.1464C26.1772 23.7415 26.5066 23.4121 26.9116 23.4121H37.9699C38.3748 23.4121 38.7042 23.7414 38.7042 24.1464V29.6756H38.7043Z" fill="black"/>
        <path d="M7.0934 15.8529L8.01754 14.9288C8.27059 14.6758 8.27059 14.2654 8.01754 14.0125C7.76449 13.7595 7.35418 13.7595 7.10129 14.0125L6.17715 14.9367L5.25301 14.0125C4.99996 13.7595 4.58965 13.7595 4.33676 14.0125C4.08371 14.2656 4.08371 14.6759 4.33676 14.9288L5.2609 15.8529L4.33676 16.7771C4.08371 17.0301 4.08371 17.4404 4.33676 17.6933C4.46324 17.8199 4.6291 17.8832 4.79488 17.8832C4.96066 17.8832 5.12652 17.8199 5.25301 17.6933L6.17715 16.7692L7.10129 17.6933C7.22777 17.8199 7.39363 17.8832 7.55941 17.8832C7.7252 17.8832 7.89105 17.8199 8.01754 17.6933C8.27059 17.4403 8.27059 17.03 8.01754 16.7771L7.0934 15.8529Z" fill="black"/>
        <path d="M12.6228 15.8529L13.5469 14.9288C13.8 14.6758 13.8 14.2654 13.5469 14.0125C13.2939 13.7595 12.8836 13.7595 12.6307 14.0125L11.7065 14.9367L10.7824 14.0125C10.5293 13.7595 10.119 13.7595 9.86613 14.0125C9.61309 14.2656 9.61309 14.6759 9.86613 14.9288L10.7902 15.8529L9.86605 16.7771C9.61301 17.0301 9.61301 17.4404 9.86605 17.6933C9.99254 17.8198 10.1584 17.8832 10.3242 17.8832C10.49 17.8832 10.6558 17.82 10.7823 17.6933L11.7064 16.7692L12.6306 17.6933C12.7571 17.8198 12.9229 17.8832 13.0887 17.8832C13.2545 17.8832 13.4204 17.82 13.5468 17.6933C13.7999 17.4403 13.7999 17.03 13.5468 16.7771L12.6228 15.8529Z" fill="black"/>
        <path d="M18.1516 15.8529L19.0757 14.9288C19.3288 14.6758 19.3288 14.2654 19.0757 14.0125C18.8227 13.7595 18.4124 13.7595 18.1595 14.0125L17.2353 14.9367L16.3112 14.0125C16.0581 13.7595 15.6478 13.7595 15.3949 14.0125C15.1419 14.2656 15.1419 14.6759 15.3949 14.9288L16.319 15.8529L15.3949 16.7771C15.1418 17.0301 15.1418 17.4404 15.3949 17.6933C15.5213 17.8198 15.6872 17.8832 15.853 17.8832C16.0188 17.8832 16.1846 17.82 16.3111 17.6933L17.2353 16.7692L18.1594 17.6933C18.2859 17.8198 18.4517 17.8832 18.6175 17.8832C18.7833 17.8832 18.9492 17.82 19.0756 17.6933C19.3287 17.4403 19.3287 17.03 19.0756 16.7771L18.1516 15.8529Z" fill="black"/>
        <path d="M23.6809 15.8529L24.605 14.9288C24.8581 14.6758 24.8581 14.2654 24.605 14.0125C24.352 13.7595 23.9417 13.7595 23.6888 14.0125L22.7646 14.9367L21.8405 14.0125C21.5874 13.7595 21.1771 13.7595 20.9242 14.0125C20.6712 14.2656 20.6712 14.6759 20.9242 14.9288L21.8483 15.8529L20.9242 16.7771C20.6711 17.0301 20.6711 17.4404 20.9242 17.6933C21.0506 17.8198 21.2165 17.8832 21.3823 17.8832C21.5481 17.8832 21.7139 17.82 21.8404 17.6933L22.7645 16.7692L23.6887 17.6933C23.8152 17.8198 23.981 17.8832 24.1468 17.8832C24.3126 17.8832 24.4785 17.82 24.6049 17.6933C24.858 17.4403 24.858 17.03 24.6049 16.7771L23.6809 15.8529Z" fill="black"/>
        <path d="M30.3671 24.8809C29.2477 24.8809 28.3369 25.7916 28.3369 26.9111C28.3369 28.0305 29.2477 28.9413 30.3671 28.9413C31.4866 28.9413 32.3974 28.0305 32.3974 26.9111C32.3974 25.7916 31.4866 24.8809 30.3671 24.8809ZM30.3671 27.6455C29.9622 27.6455 29.6328 27.3161 29.6328 26.9111C29.6328 26.5061 29.9621 26.1767 30.3671 26.1767C30.7721 26.1767 31.1015 26.5062 31.1015 26.9111C31.1015 27.316 30.7721 27.6455 30.3671 27.6455Z" fill="black"/>
        <path d="M35.4302 26.9111L36.3543 25.987C36.6074 25.734 36.6074 25.3236 36.3543 25.0707C36.1013 24.8177 35.691 24.8177 35.4381 25.0707L34.5139 25.9948L34.4707 25.9516V25.5288C34.4707 25.171 34.1807 24.8809 33.8228 24.8809C33.465 24.8809 33.1748 25.1709 33.1748 25.5288V28.2934C33.1748 28.6512 33.4649 28.9414 33.8228 28.9414C34.1806 28.9414 34.4707 28.6513 34.4707 28.2934V27.7842L35.4381 28.7516C35.5646 28.878 35.7304 28.9414 35.8962 28.9414C36.062 28.9414 36.2279 28.8782 36.3543 28.7516C36.6074 28.4985 36.6074 28.0882 36.3543 27.8353L35.4302 26.9111Z" fill="black"/>
    </g>
    </svg>
  );
}
