import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import { useCustomer } from "../context/customerContext";
import Phone from "../pages/phone";
import DashboardTransation from "../pages/dashboardTransation";
import Dashboard from "../pages/dashboard";
import QrCodePage from "../pages/qrCode";
import QRCodeExample from "../pages/QRCodeExample";
import TwoFactor from "../pages/two-factor";
import Confirmation from "../pages/confirmation";
import TwoFactorLogin from "../pages/2fa-pin";
import WithdrawalRequestList from "../pages/withdrawalRequest";
import BankList from "../pages/bankList";
import VerifyBank from "../pages/verifyBank";
import GoodsServices from "../pages/goods-services";
import FriendsAndFamily from "../pages/friendsAndFamily";
import LoadFund from "../pages/load-fund";
import Activity from "../pages/activity";
import FundRequests from "../pages/fundRequests";
import CompleteTransfer from "../pages/completeTransfer";
import AddFundingMethod from "../pages/AddFundingMethod";
import Request from "../pages/request";
import Payment from "../pages/payment";

const Loader = React.lazy(() => import("../components/Loader"));
const Splash = React.lazy(() => import("../pages/splash"));
const Login = React.lazy(() => import("../pages/login"));
const Scan = React.lazy(() => import("../pages/scan"));
const LicenseScanner = React.lazy(() => import("../pages/licenseScanner"));
const PassportScanner = React.lazy(() => import("../pages/passportScanner"));
const Landing = React.lazy(() => import("../pages/landing"));
const TermsConditions = React.lazy(() => import("../pages/termsConditions"));
const PrivacyPolicy = React.lazy(() => import("../pages/privacyPolicy"));
const ESign = React.lazy(() => import("../pages/eSign"));
const Contact = React.lazy(() => import("../pages/contact"));
const ContactNew = React.lazy(() => import("../pages/contactNew"));
const Invoice = React.lazy(() => import("../components/MainReceipt"));
const OnlyMobile = React.lazy(() => import("../components/OnlyMobile"));
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));

function AppRouter() {
  const { customer } = useCustomer();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<Landing />} />
          <Route path={"/splash"} element={<Splash />} />
          <Route path={"/receipt/:memoid?"} element={<Invoice />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"/contact"} element={<ContactNew />} />
          <Route path={"/contact-new"} element={<Contact />} />
          <Route path={"/terms-conditions"} element={<TermsConditions />} />
          <Route path={"/e-sign"} element={<ESign />} />
          {isMobile && !isTablet && (
            <>
              <Route path={"/login"} element={<Login />} />
              <Route
                path={"/scan/:contactlessId?"}
                element={<Scan p2p={false} />}
              />
              <Route path={"/p2p/:p2pId?"} element={<Scan p2p={true} />} />
              <Route path={"/p2p-qr/:customerExtId"} element={<Scan />} />
              <Route
                path={"qrpay/:storeExternalId"}
                element={<Scan p2p={false} qr={true} />}
              />
              <Route
                path={"qr-license-scanner/:storeExternalId"}
                element={<LicenseScanner p2p={false} qr={true} />}
              />
              <Route
                path={"/license-scanner/:contactlessId?"}
                element={<LicenseScanner p2p={false} />}
              />
              <Route
                path={"/p2p-license-scanner/:p2pId?"}
                element={<LicenseScanner p2p={true} />}
              />
              <Route
                path={"/p2p-qr-license-scanner/:customerExtId"}
                element={<LicenseScanner />}
              />
              <Route
                path={"/p2p-qr-passport-scanner/:customerExtId"}
                element={<PassportScanner />}
              />
              <Route
                path={"/passport-scanner/:contactlessId?"}
                element={<PassportScanner />}
              />
              <Route
                path={"/p2p-passport-scanner/:p2pId?"}
                element={<PassportScanner />}
              />
              <Route
                path={"/qr-passport-scanner/:storeExternalId?"}
                element={<PassportScanner />}
              />
              <Route path={"/verify-phone/:custExtId?"} element={<Phone />} />
              <Route element={<ProtectedRoute />}>
                <Route
                  path={"/account-details"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <DashboardTransation />
                    </Suspense>
                  }
                />
                <Route
                  path={"/dashboard/:p2pId?"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Dashboard />
                    </Suspense>
                  }
                />
                <Route
                  path={"/qrCode"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <QrCodePage />
                    </Suspense>
                  }
                />
                <Route
                  path={"/qr"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <QRCodeExample />
                    </Suspense>
                  }
                />
                <Route
                  path={"/two-factor"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <TwoFactor />
                    </Suspense>
                  }
                />
                <Route
                  path={"/confirmation"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Confirmation />
                    </Suspense>
                  }
                />
                <Route
                  path={"/pin-login/:type?"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <TwoFactorLogin />
                    </Suspense>
                  }
                />
                <Route
                  path={"/withdrawal-list"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <WithdrawalRequestList customer={customer} />
                    </Suspense>
                  }
                />
                <Route
                  path={"/withdrawal"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <BankList />
                    </Suspense>
                  }
                />
                <Route
                  path={"/verify-bank"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <VerifyBank />
                    </Suspense>
                  }
                />
                <Route
                  path={"/goods-services/:externalId?/:custIdForDoobie?"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <GoodsServices />
                    </Suspense>
                  }
                />
                <Route
                  path={"/goods-services-qr/:storeExternalId"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <GoodsServices />
                    </Suspense>
                  }
                />
                <Route
                  path={"/transfer-money/:customerId?"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <FriendsAndFamily />
                    </Suspense>
                  }
                />
                <Route
                  path={"/payment"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Payment />
                    </Suspense>
                  }
                />
                <Route
                  path={"/deposit-funds"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <LoadFund />
                    </Suspense>
                  }
                />
                <Route
                  path={"/activity"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Activity />
                    </Suspense>
                  }
                />
                <Route
                  path={"/requests"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Request />
                    </Suspense>
                  }
                />
                <Route
                  path={"/fund-request/:extId"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <FundRequests />
                    </Suspense>
                  }
                />
                <Route
                  path={"/complete-transfer"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <CompleteTransfer />
                    </Suspense>
                  }
                />
                <Route
                  path={"/add-funding-method"}
                  element={
                    <Suspense fallback={<Loader />}>
                      <AddFundingMethod />
                    </Suspense>
                  }
                />
              </Route>
            </>
          )}
          <Route path={"*"} element={<OnlyMobile />} exct={true} />
        </Routes>
      </Suspense>
    </>
  );
}

export default AppRouter;
