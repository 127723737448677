import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import LayoutStyle from "../scss/base.module.scss";
import { Mousewheel, Pagination } from "swiper/modules";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import security from "../Assets/security.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ScanQR from "../Assets/Svgs/scanQr";
import Withdraw from "../Assets/Svgs/withdraw";
import { useNavigate } from "react-router-dom";

export default function App() {
  const router = useNavigate();
  return (
    <>
      <Box
        component={"div"}
        pt={2.5}
        pb={1.875}
        className={LayoutStyle.informationSlider}
      >
        <Swiper
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          spaceBetween={20}
          modules={[Mousewheel, Pagination]}
          className="infoSlider"
        >
          <SwiperSlide onClick={() => router(`/confirmation`)}>
            <Card elevation={4}>
              <CardContent>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Grid
                    container
                    item
                    xs
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Grid item xs={12}>
                      <Typography m={0} variant="h4">
                        Security Instructions
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box component={"div"}>
                        <img src={security} alt="security" />
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" gutterBottom>
                        2FA Verification
                      </Typography>
                      <Typography variant="h6" sx={{ opacity: 0.5 }} m={0}>
                        Please enable a pin or 2-factor authentication
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <KeyboardArrowRightIcon color="primary" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </SwiperSlide>
          <SwiperSlide onClick={() => router(`/qr`)}>
            <Card elevation={4}>
              <CardContent>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Grid
                    container
                    item
                    xs
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Grid item xs={12}>
                      <Typography m={0} variant="h4">
                        Easy To Pay
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{ height: "2.5rem" }}>
                        <ScanQR />
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" gutterBottom>
                        Pay By QR Code
                      </Typography>
                      <Typography variant="h6" sx={{ opacity: 0.5 }} m={0}>
                        Scan the code using a this app and pay
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <KeyboardArrowRightIcon color="primary" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </SwiperSlide>
          <SwiperSlide onClick={() => router(`/withdrawal`)}>
            <Card elevation={4}>
              <CardContent>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Grid
                    container
                    item
                    xs
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Grid item xs={12}>
                      <Typography m={0} variant="h4">
                        Withdrawal Facility
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box component={"div"} sx={{ height: "2.5rem" }}>
                        <Withdraw />
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="h5" gutterBottom>
                        {" "}
                        Any Time Withdrawal
                      </Typography>
                      <Typography variant="h6" sx={{ opacity: 0.5 }} m={0}>
                        You can withdraw balance to any of your linked accounts
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <KeyboardArrowRightIcon color="primary" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </SwiperSlide>
        </Swiper>
      </Box>
    </>
  );
}
