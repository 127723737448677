import React, { useEffect, useState } from "react";
import Joyride, { EVENTS } from "react-joyride";
import {steps} from "./tourSteps";
import { useProjectGuide } from "../../context/projectGuideContext";
import { useNavigate } from "react-router-dom";
import Axios from "../../config/Axios";
import { useCustomer } from "../../context/customerContext";
import { getCustomerInfoServices } from "../../services/CustomerServices";

const tour = ({
  runTour
}) => {
  const { token, setCustomer, customer } = useCustomer();
  const {stepIndex, setStepIndex} = useProjectGuide();
  const [stepStatus, setStepStatus] = useState(false);
  const [run, setRun] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    if (stepStatus) {
      if (customer?.customer_external_id) {
        getCustomerInfoServices(customer?.customer_external_id)
          .then((res) => {
            if (res) {
              setCustomer((prev) => ({ ...prev, ...res }));
            }
          })
      }
    } else {
      setRun(runTour);
    }
    // Redirect to dashboard when reload app;
    if (runTour && stepIndex <= 0) {
      navigate('/dashboard')
    }
  },[stepStatus, setCustomer, runTour]);
  
  const handleCallback = async (data) => {
    const { index, type, action, status } = data;

    // Call function after event
    if (type === EVENTS.STEP_AFTER) {
      // Close Step
      if (action === 'close') {
        setRun(false);
        handleProjectGuideStep('skip', index + 1);
      }
      // Next Step
      if (action === 'next') {
        setStepIndex(parseInt(index + 1));
        if (index === 3) {
          navigate('/two-factor');
        }
        if (index === 4) {
          navigate('/account-details');
        }
        if (index === 5) {
          navigate('/dashboard');
        }
      }

      // Preview Step
      if (action === 'prev') {
        setStepIndex(parseInt(index - 1));
        if (index === 5) {
          navigate('/two-factor');
        }
        if (index === 4) {
          navigate('/dashboard');
        }
        if (index === 6) {
          navigate('/account-details');
        }
      }
    }

    // Skip step and Finished
    if (type === EVENTS.TOUR_END) {
      if (action === 'next' && status === 'finished') {
        handleProjectGuideStep('finish', stepIndex);
      }
      if (action === 'skip') {
        handleProjectGuideStep('skip', index + 1);
      }
    }
  };

  const handleProjectGuideStep = async(status, totalShowGuidanceStep) => {
    const data = {
      status,
      totalShowGuidanceStep,
      totalSteps: steps.length,
    }
    await Axios(token)
      .post('update-project-visited-guide',data)
      .then(async (response) =>{
        if (response.status === 200 && response?.data?.statusCode === 200) {
          setCustomer((prevCustomer) => ({ ...prevCustomer, show_guidance_first_time: false }));
          setStepStatus(true);
          setStepIndex(0);
        }
      });
  }

  return (
    <Joyride
      run={run}
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      stepIndex={stepIndex}
      callback={handleCallback}
      styles={{
        options: {
          zIndex: 999999,
          primaryColor: '#011b44'
        },
      }}
    />
  )
}

export default tour;