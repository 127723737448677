import React from "react";

export default function AddIcon() {
  return (
    <> 
        <path d="M1 5.44434H11.7431" strokeWidth="2" strokeLinecap="round"/>
        <path d="M6.37158 1L6.37158 9.88889" strokeWidth="2" strokeLinecap="round"/>
    </>

  );
}
