import React from "react";

export default function SecurityIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22 22" fill="none">
        <mask id="mask0_10015_1199" maskUnits="userSpaceOnUse" x="0" y="0" >
            <path d="M0 0H22V22H0V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_10015_1199)">
            <path d="M11 0.859375L2.57812 4.35604V8.60673C2.57812 14.1078 5.90687 19.0618 11 21.1406C16.0931 19.0618 19.4219 14.1078 19.4219 8.60673V4.35604L11 0.859375Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.64844 10.3171L10.0501 12.7188L14.3516 8.41736" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </svg>
    

  );
}
