import React, { useRef, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  SwipeableDrawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { useNavigate} from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import FlashIcon from "../Assets/Icons/fIashIcon";
import QrCodeIcon from "../svg/qr";
import QrScanner from "qr-scanner";
import ScanQR from "../Assets/Svgs/scanQr";
import { useCustomer } from "../context/customerContext";
import QRCode from "qrcode";
import { checkLogin } from "../components/helpers/checkLogin";
import CustomerConfirmModal from "../components/Modal";
import {QRURL} from "../utils/Common";

const drawerBleeding = 80;

const Root = styled("main")(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const StyledBox = styled("div")(() => ({
  position: "absolute",
  top: -drawerBleeding,
  borderRadius: "1.25rem 1.25rem 0 0",
  visibility: "visible",
  backgroundColor: "#fff",
  right: 0,
  left: 0,
  paddingBlockStart: "2.5rem",
  paddingBlockEnd: "2.5rem",
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 16,
  left: "calc(50% - 15px)",
}));
const ScanerBg = styled("div")(({ theme }) => ({
  width: "100%",
  height: "calc(100% - 3.25rem)",
  backgroundColor: theme.palette.mode === "light" ? grey[500] : grey[900],
  position: "absolute",
  top: 0,
  left: 0,
}));
const Backdrop = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 3.25rem)",
  left: 0,
  top: 0,
  backgroundColor: "rgba(0,0,0,.4)",
  clipPath:
    "polygon(0% 0%, 0% 100%, 17% 100%, 16% 12%, 84% 12%, 84% 45%, 16% 45%, 17% 102%, 100% 100%, 100% 0%)",
  zIndex: 2,
}));
const ContainerBox = styled("div")(() => ({
  position: "relative",
  zIndex: 2,
  paddingInline: "1.25rem",
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));
const ScanerQr = styled("div")(() => ({
  position: "absolute",
  width: "71%",
  height: "35%",
  left: "14.5%",
  top: "11%",
}));
const RadiusTopLeft = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "1.875rem",
  height: "1.875rem",
  // borderTopLeftRadius: 20,
  border: "#fff solid 0.1875rem",
  left: -1,
  top: -1,
  borderColor: "#fff transparent transparent #fff",
  transition: "all .3s",
  "&.qrDone": {
    transform: "scale(1.25)",
    borderColor: "#00dc2c transparent transparent #00dc2c",
  },
}));
const RadiusTopRight = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "1.875rem",
  height: "1.875rem",
  // borderTopRightRadius: 20,
  border: "#fff solid 0.1875rem",
  right: -1,
  top: -1,
  borderColor: "#fff #fff transparent  transparent ",
  transition: "all .3s",
  "&.qrDone": {
    transform: "scale(1.25)",
    borderColor: "#00dc2c #00dc2c transparent  transparent ",
  },
}));
const RadiusBottomRight = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "1.875rem",
  height: "1.875rem",
  // borderBottomRightRadius: 20,
  border: "#fff solid 0.1875rem",
  right: -1,
  bottom: -1,
  borderColor: " transparent #fff #fff transparent  ",
  transition: "all .3s",
  "&.qrDone": {
    transform: "scale(1.25)",
    borderColor: " transparent #00dc2c #00dc2c transparent  ",
  },
}));
const RadiusBottomLeft = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "1.875rem",
  height: "1.875rem",
  // borderBottomLeftRadius: 20,
  border: "#fff solid 0.1875rem",
  left: -1,
  bottom: -1,
  borderColor: "  transparent transparent   #fff #fff",
  transition: "all .3s",
  "&.qrDone": {
    transform: "scale(1.25)",
    borderColor: "  transparent transparent   #00dc2c #00dc2c",
  },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function QrCodePage(props) {
  const navigate = useNavigate();
  const { customer } = useCustomer();
  const videoEl = useRef(null);
  const videoEl1 = useRef(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkLogin(customer, navigate);
    QRCode.toDataURL(
      `${QRURL()}/p2p/${customer?.customer_external_id}`,
      {
        version: 10,
      },
    )
      .then((qr) => {
        setQrCodeData(qr);
      })
      .catch((e) => {
        setError(e?.data?.response?.message || e?.message || "Something went wrong.")
      });
  }, []);
  const videoRender = () => {
    return (
      <video
        ref={videoEl}
        style={{
          width: "100%",
          height: "100%",
          aspectRatio: 9/16,
          // position: "absolute",
          objectFit: "cover",
          // left: "0",
          // top: "0",
        }}
      />
    );
  };

  const [openMyQr, setOpenMyQr] = useState(false);

  const handleClickOpen = () => {
    setOpenMyQr(true);
  };

  const handleClose = () => {
    setOpenMyQr(false);
  };

  useEffect(() => {
    const qrScanner = new QrScanner(
      videoEl.current,
      (result) => {
        qrScanner.stop();
        const segments = result.split("/");
        const uuid = segments[segments.length - 1];
        const type = segments[segments.length - 2];
        if (type === "qrpay") {
          navigate(`/goods-services-qr/${uuid}`);
        }
        if (type === "p2p") {
          navigate(`/transfer-money/${uuid}`);
        }
        if (type === "scan") {
          navigate(`/goods-services/${uuid}`);
        }
      }, { video: { facingMode: "environment" }, returnDetailedScanResult: true, preferredCamera: "environment", overlay: true}
    );

    qrScanner.start().catch((error) => {
      console.error("Failed to start QR scanner:", error);
    });

    return () => {
      qrScanner.stop();
    };
  }, []);

  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        QrScanner.scanImage(base64String)
          .then((result) => {
            const segments = result.split("/");
            const uuid = segments[segments.length - 1];
            const type = segments[segments.length - 2];
            if (type === "qrpay") {
              navigate(`/goods-services-qr/${uuid}`);
            }
            if (type === "p2p") {
              navigate(`/transfer-money/${uuid}`);
            }
            if (type === "scan") {
              navigate(`/goods-services/${uuid}`);
            }
          })
          .catch(() => {
            setError("QR code not supported.");
          });
      };
      reader.readAsDataURL(file);
    }
  };
  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => { setError(null)}}
        />
      )}
      <Root>
        <Global
          styles={{
            ".MuiDrawer-root > .MuiPaper-root": {
              // height: `calc(50% - ${drawerBleeding}px)`,
              overflow: "visible",
            },
          }}
        />
        <ScanerBg>{videoRender()}</ScanerBg>
        <Backdrop>
          <ScanerQr>
            <RadiusTopLeft className="qrDone" />
            <RadiusTopRight className="qrDone" />
            <RadiusBottomRight className="qrDone" />
            <RadiusBottomLeft className="qrDone" />
            <Box
              width={"100%"}
              component={"div"}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"1.25rem"}
              overflow={"hidden"}
              position={"relative"}
              ref={videoEl1}
            ></Box>
          </ScanerQr>
        </Backdrop>
        <ContainerBox>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            py={2}
          >
            <IconButton color="white" onClick={() => navigate("/dashboard")}>
              <WestIcon color="white" />
            </IconButton>
            <Button
              size="small"
              variant="contained"
              color="white"
              className="MuiButton-box"
            >
              <SvgIcon
                viewBox=" 0 0 13 20"
                sx={{ fill: "none", width: 13, height: 20 }}
              >
                <FlashIcon />
              </SvgIcon>
            </Button>
          </Stack>
          <Box
            sx={{
              width: "90%",
              marginInline: "auto",
              marginBlockEnd: "1.25rem",
            }}
          >
            <Box sx={{ width: "100%", paddingBlockStart: "100%" }}></Box>
          </Box>
          <Stack px={3} spacing={3} alignItems={"center"} mb={10}>
            <Button
              variant="contained"
              component="label"
              color="white"
              tabIndex={-1}
              sx={{ paddingInline: "1.25rem!important", width: "13.75rem" }}
            >
              Upload from Gallery
              <VisuallyHiddenInput
                onChange={(e) => {
                  handleFileChange(e);
                }}
                type="file"
              />
            </Button>
            <Button
              variant="contained"
              color="white"
              sx={{ paddingInline: "1.25rem!important", width: "13.75rem" }}
              onClick={handleClickOpen}
              startIcon={
                <SvgIcon viewBox="0 0 22 22">
                  <QrCodeIcon />
                </SvgIcon>
              }
            >
              Share QR Code
            </Button>
          </Stack>
        </ContainerBox>
        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          className="asdada"
        >
          <StyledBox>
            <Puller />
            <Typography variant="h4" textAlign={"center"} mb={0}>
              Scan any QR code to Pay
            </Typography>
          </StyledBox>
          <Box component={"div"} p={5} pt={1}>
            <Stack spacing={2.5} alignItems={"center"}>
              <Avatar
                sx={{ width: "5rem", height: "5rem", p: 1, bgcolor: "#ECECEC" }}
              >
                <ScanQR />
              </Avatar>
              <Typography textAlign={"center"} variant="body1">
                Position your phone to make sure the QR code is within the frame
              </Typography>
            </Stack>
          </Box>
        </SwipeableDrawer>
      </Root>
      <Dialog
        open={openMyQr}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="sm"
        maxWidth="sm"
      >
        <DialogContent>
          <Typography
            variant="h4"
            fontWeight={400}
            textAlign={"center"}
            color={"secondary"}
            sx={{ opacity: 0.5 }}
          >
            Contactless Payment
          </Typography>
          <Typography variant="h3" textAlign={"center"}>
            Scan QR Code
          </Typography>
          <Card variant="outlined">
            <CardContent>
              <img src={qrCodeData} alt={"qr"} />
            </CardContent>
          </Card>
          <Box className="clear20" />
          <Stack spacing={1}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Typography variant="h5" fontWeight={500}>
                Account Number:{" "}
              </Typography>
              <Typography variant="h5" fontWeight={500}>
                {customer?.account_number}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Typography variant="h5" fontWeight={500}>
                Name: Name:
              </Typography>
              <Typography variant="h5" fontWeight={500}>
                {customer?.fullName}
              </Typography>
            </Stack>
            {/*<Stack*/}
            {/*  direction={"row"}*/}
            {/*  alignItems={"center"}*/}
            {/*  justifyContent={"space-between"}*/}
            {/*  spacing={2}*/}
            {/*>*/}
            {/*  <Typography variant="h5" fontWeight={500}>*/}
            {/*    Order Amount:{" "}*/}
            {/*  </Typography>*/}
            {/*  <Typography variant="h5" fontWeight={500}>*/}
            {/*    $100.00*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
            {/*<Stack*/}
            {/*  direction={"row"}*/}
            {/*  alignItems={"center"}*/}
            {/*  justifyContent={"space-between"}*/}
            {/*  spacing={2}*/}
            {/*>*/}
            {/*  <Typography variant="h5" fontWeight={500}>*/}
            {/*    Order Amount:{" "}*/}
            {/*  </Typography>*/}
            {/*  <Typography variant="h5" fontWeight={500}>*/}
            {/*    $100.00*/}
            {/*  </Typography>*/}
            {/*</Stack>*/}
          </Stack>
          <Box className="clear20" />
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  color="primary"*/}
          {/*  fullWidth*/}
          {/*  onClick={handleClickOpen}*/}
          {/*  startIcon={<ShareOutlinedIcon />}*/}
          {/*>*/}
          {/*  Share QR Code*/}
          {/*</Button>*/}
        </DialogContent>
      </Dialog>
    </>
  );
}

QrCodePage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default QrCodePage;
