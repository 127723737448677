import React from "react";

export default function RemoveIcon() {
  return (
    <>    
    <path d="M10.2127 5.31631C9.98617 5.05786 9.98617 4.63887 10.2127 4.38042C10.4393 4.12197 10.8065 4.12197 11.033 4.38042L12.9472 6.56417L14.8614 4.38042C15.0879 4.12197 15.4551 4.12197 15.6817 4.38042C15.9082 4.63882 15.9082 5.05786 15.6817 5.31631L13.7675 7.5L15.6817 9.68375C15.9082 9.94214 15.9082 10.3612 15.6817 10.6196C15.4551 10.878 15.0879 10.878 14.8614 10.6196L12.9472 8.43588L11.033 10.6196C10.8065 10.878 10.4393 10.878 10.2128 10.6196C9.98617 10.3611 9.98617 9.94214 10.2128 9.68369L12.1269 7.50005L10.2127 5.31631Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.25809 0.77529L0.363525 7.5L6.25809 14.2247C6.69323 14.7211 7.28341 15 7.89879 15H18.7969C20.0784 15 21.1172 13.8149 21.1172 12.3529V2.64706C21.1172 1.18512 20.0784 0 18.7969 0H7.89879C7.28341 0 6.69323 0.278913 6.25809 0.77529ZM18.7969 13.853H7.89879C7.55003 13.853 7.21562 13.6949 6.96901 13.4136L1.78547 7.5L6.96901 1.58639C7.21562 1.30511 7.55003 1.14705 7.89879 1.14705H18.7969C19.523 1.14705 20.1117 1.81861 20.1117 2.64706V12.3529C20.1117 13.1814 19.523 13.853 18.7969 13.853Z" />
    </>

  );
}
