import Axios from "../config/Axios";

export const getActivitiesServices = async (customerId, token, page, limits = 10) => {
  const getCustomer = await Axios(token).get(`/customer/${customerId}/activities`, {
    params: {
      page: page,
      limit: limits,
    }
  })
  return getCustomer?.data?.response
}
export const getRequestsServices = async (customerId, token, page) => {
  const getCustomer = await Axios(token).get(`/customer/${customerId}/requests`, {
    params: {
      page: page,
      limit: 10,
    }
  })
  return getCustomer?.data?.response
}

export const getSingleRequestServices = async (requestId, token) => {
  const getRequestDetails = await Axios(token).get(`/customer/requests/${requestId}`)
  return getRequestDetails?.data?.response
}
