import React from "react";

export default function OtpIcon() {
  return (
    
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="40" viewBox="0 0 45 40" fill="none">
    <g opacity="0.5">
      <path d="M41.078 21.48H31.4911V12.4497C31.4911 11.2834 30.5456 10.338 29.3793 10.338H28.1953H16.8677V6.98918C16.8677 4.10868 19.2092 1.76717 22.0897 1.76717C24.9702 1.76717 27.3117 4.10868 27.3117 6.98918C27.3117 7.47515 27.7093 7.87276 28.1953 7.87276C28.6812 7.87276 29.0789 7.47515 29.0789 6.98918C29.0789 3.13673 25.9421 0 22.0897 0C18.2372 0 15.1005 3.13673 15.1005 6.98918V10.338H14.8001C13.6338 10.338 12.6883 11.2834 12.6883 12.4497V21.48H3.10139C1.38723 21.48 0 22.8761 0 24.5814V36.8986C0 38.6039 1.38723 40 3.10139 40H41.078C42.7833 40 44.1794 38.6128 44.1794 36.8986V24.5814C44.1794 22.8761 42.7921 21.48 41.078 21.48ZM14.4555 12.4497C14.4555 12.2554 14.6145 12.1051 14.8001 12.1051H15.9841H28.1953H29.3793C29.5737 12.1051 29.7239 12.2642 29.7239 12.4497V22.7877C29.7239 22.9821 29.5648 23.1323 29.3793 23.1323H14.8001C14.6057 23.1323 14.4555 22.9733 14.4555 22.7877V12.4497ZM42.4122 36.8986C42.4122 37.632 41.8114 38.2328 41.078 38.2328H3.10139C2.36801 38.2328 1.76717 37.632 1.76717 36.8986V24.5814C1.76717 23.848 2.36801 23.2472 3.10139 23.2472H12.7413C12.9534 24.1926 13.7928 24.8995 14.8001 24.8995H29.3793C30.3866 24.8995 31.226 24.1926 31.438 23.2472H41.078C41.8114 23.2472 42.4122 23.848 42.4122 24.5814V36.8986Z" fill="black"/>
      <path d="M12.9005 29.8563H11.0892L11.9904 28.2924C12.2378 27.8683 12.0876 27.3293 11.6635 27.0819C11.2394 26.8345 10.7004 26.9847 10.453 27.4088L9.55172 28.9728L8.65046 27.4088C8.40306 26.9847 7.86407 26.8433 7.43995 27.0819C7.01582 27.3205 6.87445 27.8683 7.11302 28.2924L8.01428 29.8563H6.20292C5.71695 29.8563 5.31934 30.254 5.31934 30.7399C5.31934 31.2259 5.71695 31.6235 6.20292 31.6235H8.01428L7.11302 33.1875C6.86561 33.6116 7.01582 34.1506 7.43995 34.398C7.58132 34.4775 7.73153 34.5129 7.88174 34.5129C8.19099 34.5129 8.48258 34.3538 8.65046 34.0711L9.55172 32.5071L10.453 34.0711C10.6209 34.3538 10.9124 34.5129 11.2217 34.5129C11.3719 34.5129 11.5221 34.4775 11.6635 34.398C12.0876 34.1506 12.229 33.6116 11.9904 33.1875L11.0892 31.6235H12.9005C13.3865 31.6235 13.7841 31.2259 13.7841 30.7399C13.7841 30.254 13.3865 29.8563 12.9005 29.8563Z" fill="black"/>
      <path d="M25.4298 29.8563H23.6184L24.5197 28.2924C24.7671 27.8683 24.6169 27.3293 24.1927 27.0819C23.7686 26.8345 23.2296 26.9847 22.9822 27.4088L22.0898 28.9728L21.1885 27.4088C20.9411 26.9847 20.4022 26.8433 19.978 27.0819C19.5539 27.3293 19.4125 27.8683 19.6511 28.2924L20.5524 29.8563H18.741C18.255 29.8563 17.8574 30.254 17.8574 30.7399C17.8574 31.2259 18.255 31.6235 18.741 31.6235H20.5524L19.6511 33.1875C19.4037 33.6116 19.5539 34.1506 19.978 34.398C20.1194 34.4775 20.2696 34.5129 20.4198 34.5129C20.7291 34.5129 21.0207 34.3538 21.1885 34.0711L22.0898 32.5071L22.9911 34.0711C23.1589 34.3538 23.4505 34.5129 23.7598 34.5129C23.91 34.5129 24.0602 34.4775 24.2016 34.398C24.6257 34.1506 24.7671 33.6116 24.5285 33.1875L23.6272 31.6235H25.4386C25.9246 31.6235 26.3222 31.2259 26.3222 30.7399C26.3222 30.254 25.9157 29.8563 25.4298 29.8563Z" fill="black"/>
      <path d="M37.959 29.8563H36.1477L37.0489 28.2924C37.2963 27.8683 37.1461 27.3293 36.722 27.0819C36.2979 26.8345 35.7589 26.9847 35.5115 27.4088L34.6102 28.9728L33.709 27.4088C33.4616 26.9847 32.9226 26.8433 32.4984 27.0819C32.0743 27.3293 31.9329 27.8683 32.1715 28.2924L33.0728 29.8563H31.2791C30.7931 29.8563 30.3955 30.254 30.3955 30.7399C30.3955 31.2259 30.7931 31.6235 31.2791 31.6235H33.0904L32.1892 33.1875C31.9418 33.6116 32.092 34.1506 32.5161 34.398C32.6575 34.4775 32.8077 34.5129 32.9579 34.5129C33.2672 34.5129 33.5587 34.3538 33.7266 34.0711L34.6279 32.5071L35.5291 34.0711C35.697 34.3538 35.9886 34.5129 36.2979 34.5129C36.4481 34.5129 36.5983 34.4775 36.7397 34.398C37.1638 34.1506 37.3052 33.6116 37.0666 33.1875L36.1653 31.6235H37.9767C38.4627 31.6235 38.8603 31.2259 38.8603 30.7399C38.8603 30.254 38.445 29.8563 37.959 29.8563Z" fill="black"/>
    </g>
  </svg>
  );
}
