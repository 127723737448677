import Pusher from "pusher-js";
import getPusherKey from "./getPusherKey";
const pusherSub = (channelString, event, cb1, cb2, cb3) => {
  const appKey = getPusherKey();
  Pusher.logToConsole = false;
  const pusher = new Pusher(appKey, {
    cluster: "ap2",
  });
  const channel = pusher?.subscribe(channelString);
  channel?.bind(event, function (data) {
    if (cb1) {
      cb1();
    }
    if (cb2) {
      cb2(data);
    }
    if (cb3) {
      cb3();
    }
  });
};

export default pusherSub;
