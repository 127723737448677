import axios from "axios";
import {getAPIUrl} from "../utils/Common";

let URL = getAPIUrl();

const Axios = (token = null) => {
  return axios.create({
    baseURL: URL,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      timeout: 1000,
    },
    withCredentials: true,
  });
};

export default Axios;
