/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Footer from "../components/footer";
import HeaderFull from "../components/headerFull";
import SuccessIcon from "../svg/success";
import { useNavigate } from "react-router";
import { useCustomer } from "../context/customerContext";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../utils/CardUtility";
import { isEmpty } from "lodash";
import Axios from "../config/Axios";
import Loader from "../components/Loader";
import { currencyFormat, roundUp } from "../utils/Common";
import CustomerConfirmModal from "../components/Modal";
import PlusIcon from "../svg/plus";
import LoadingIcon from "../svg/loader";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { checkLogin } from "../components/helpers/checkLogin";
function Payment() {
  const [open, setOpen] = React.useState(false);
  const { customer, token, loadFunActivities, setCustomer, setInvoiceData } =
    useCustomer();
  const [showCard, setShowCard] = useState(false);
  const [cardType, setCardType] = useState();
  const [card, setCard] = useState(null);
  const [debitVsCredit, setDebitVsCredit] = useState();
  const [state, setState] = useState(null);
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [maskingCVV, setMaskingCVV] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState(
    customer && customer.fullName ? customer.fullName : "",
  );
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [orderId, setOrderID] = useState("");
  const [apiCalcDetails, setApiCalcDetails] = useState();

  const [errorType, setErrorType] = useState({
    name: false,
    cardNumber: false,
    cvv: false,
    cardExpire: false,
    zipCode: false,
  });
  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };
  const navigate = useNavigate();
  const Receipt = () => {
    navigate("/dashboard");
  };

  const DebitVsCreditCalculation = (val) => {
    let values = {};
    if (val) {
      values.convenience = val?.convenience_charge || 0;
      values.total = val?.order_amount || 0;
      values.orderAmount = loadFunActivities?.amount || 0;
      values.cardType = val?.CardType || "";
      values.total_amount = val?.total_amount || "";
      setDebitVsCredit(values);
    }
  };

  const checkCreditCardType = (cardNumber, cardType = "") => {
    setLoading(true);
    Axios(token)
      .post(`/account/fee/${customer?.customer_external_id}`, {
        card: cardNumber,
        card_type: cardType,
        amount: loadFunActivities?.amount,
        customer_id: customer?.id,
        type: "REVISED",
        //store_id: info?.store?.id,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.response) {
          setCardType(res?.data?.response);
          DebitVsCreditCalculation(res?.data?.response);
          setState((prevState) => ({
            ...prevState,
            feesInfo: res?.data?.response,
          }));
          setApiCalcDetails(res?.data?.response);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.data?.response?.message || err?.message || "Something went wrong.")

      });
  };

  const handleInputChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setNumber(target.value);
      let cardNumber = number?.replace(/\D/g, "");

      if (cardNumber?.length > 14 && isEmpty(cardType)) {
        checkCreditCardType(cardNumber);
      } else if (cardNumber?.length < 16 && !isEmpty(cardType)) {
        setDebitVsCredit(null);
        setCardType(null);
      }
      setState({ ...state, number: cardNumber });
      setErrorType({ ...errorType, cardNumber: false });
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      setState({ ...state, expiry: target.value });
      setErrorType({ ...errorType, cardExpire: false });
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      let str = target.value;
      str = str?.replace(/.(?=.{4})/g, "*");
      setMaskingCVV(str);
      setCvc(target.value);
      setState({ ...state, cvc: target.value });
      setErrorType({ ...errorType, cvv: false });
    } else if (target.name === "name") {
      setName(target.value);
      setState({ ...state, name: target.value });
      setErrorType({ ...errorType, name: false });
    } else if (target.name === "zip") {
      setZip(target.value);
      setErrorType({ ...errorType, zipCode: false });
      setState({ ...state, zip: target.value });
    }
  };
  const handleAddNewCard = (e, item = null, type) => {
    if (type === "new") {
      setShowCard(true);
      setCard(null);
      setDebitVsCredit(null);
    } else if (type === "existing") {
      setCard(item);
      setShowCard(false);
    }
  };

  useEffect(() => {
    if (card?.card_type) {
      checkCreditCardType(card?.cardNumber, card?.card_type);
    }
  }, [card]);

  useEffect(() => {
    checkLogin(customer, navigate);
    if (customer?.customer_external_id) {
      setLoading(true);
      Axios(token)
        .get(`/get-cards/${customer?.customer_external_id}`)
        .then((res) => {
          setLoading(false);
          customer.card = res?.data?.response?.card;
        })
        .catch(() => setLoading(false));
    }
  }, []);

  const handleSubmit = () => {
    if (isEmpty(state) && isEmpty(card)) {
      if (isEmpty(state?.number)) {
        return setErrorType({ ...errorType, cardNumber: true });
      }
      setError("Please select from existing card or load using new card.");
    } else {
      setLoading(true);
      let data;
      if (card?.external_id) {
        data = {
          card_token: card?.external_id,
          amount: loadFunActivities?.amount,
          activities: loadFunActivities?.id,
          // store_id: info?.store?.id,
          ...state?.feesInfo,
        };
      } else {
        setLoading(true);
        if (isEmpty(state?.number)) {
          setLoading(false);
          setErrorType({ ...errorType, cardNumber: true });
          return setError("Please Enter Card Number.");
        }
        if (isEmpty(state?.expiry)) {
          setLoading(false);
          setErrorType({ ...errorType, cardExpire: true });
          return setError("Please Enter Expiry Date.");
        }
        if (isEmpty(state?.cvc)) {
          setLoading(false);
          setErrorType({ ...errorType, cvv: true });
          return setError("Please Enter CVV.");
        }
        if (isEmpty(name)) {
          setLoading(false);
          setErrorType({ ...errorType, name: true });
          return setError("Please Enter Card Holder Name.");
        }
        if (isEmpty(state?.zip)) {
          setLoading(false);
          setErrorType({ ...errorType, zipCode: true });
          return setError("Please Enter ZIP Code.");
        }
        let expDate = state?.expiry.split("/");
        data = {
          ...state?.feesInfo,
          amount: loadFunActivities?.amount,
          CardNumber: state?.number,
          CardExpiryMonth: expDate[0],
          CardExpiryYear: expDate[1],
          CVV2: cvc,
          CardHolderName: name,
          Zip: state?.zip,
          activities: loadFunActivities?.id,
          saveCard: isChecked,
        };
      }
      // let expDate = state?.expiry.split("/");
      //  data = {
      //   CardNumber: state?.number,
      //   CardExpiryMonth: expDate[0],
      //   CardExpiryYear: expDate[1],
      //   CVV2: cvc,
      //
      //   Zip: state?.zip,
      //   amount: loadFunActivities?.amount,
      //   activities: loadFunActivities?.id,
      //   /*convenience_charge,
      //   fee,
      //   card_token,
      //   saveCard,*/
      //   CustomerId: customer?.id,
      // };

      Axios(token)
        .post(`/request/load/${customer?.customer_external_id}`, data)
        .then(function (response) {
          setLoading(false);
          setOrderID(response?.data?.response?.order_id);
          let data2 = response?.data?.response;
          setInvoiceData(response?.data?.response);
          setCustomer((prev) => ({ ...prev, ...data2 }));
          setOpen(true);
          /*if (onComplete){
                      onComplete(data)
                  }*/
        })
        .catch(function (error) {
          let errorMessage;
          setLoading(false);
          if (error.response.status === 400) {
            if (
              error.response?.data &&
              error.response?.data.message &&
              error.response?.data.message.status === "FAILED"
            ) {
              errorMessage = error.response.data.message.transaction_response;
            } else if (
              error.response.data &&
              error.response.data.message &&
              typeof error.response.data.message === "object"
            ) {
              const errorMessages = error.response.data.message;
              for (const prop in errorMessages) {
                if (!isEmpty(errorMessages[prop][0])) {
                  errorMessage = errorMessages[prop][0];
                }
              }
            }
            if (error.response.data.message === "cvv_invalid") {
              setErrorType({ ...errorType, cvv: true });
              return setError("Invalid CVV");
            }
            if (
              error.response.data.message ===
              "payment.number must be a valid credit card number"
            ) {
              setErrorType({ ...errorType, cardNumber: true });
              return setError("Invalid card number");
            }
            if (error.response.data.message === "card_expired") {
              setErrorType({ ...errorType, cardExpire: true });
              return setError("Card is expired");
            }
            setError(
              errorMessage
                ? errorMessage
                : error.response.data.message.transaction_response
                ? error.response.data.message.transaction_response
                : error.response.data.message || error.response.statusText,
            );
          } else {
            setError(
              error.response.data ? error.response.data.message : error.message,
            );
          }
        });
    }
  };
  return (
    <>
      <Loader show={loading} />
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => setError("")}
        />
      )}
      <>
        <HeaderFull />
        <div className="bodyContent">
          <Typography variant="h4">Funding methods</Typography>
          {customer?.card?.length > 0 && (
            <ul className="cardList">
              {customer?.card?.map((item, index) => (
                <li key={index}>
                  <input
                    className="cardRadio"
                    checked={item?.external_id === card?.external_id}
                    onClick={(e) => handleAddNewCard(e, item, "existing")}
                    /*onClick={(e) => console.log(item)}*/
                    type="radio"
                    name="cardRadio"
                  />
                  <div className="cardBox">
                    <div className="cardLogo">
                      <img src={item?.image} alt="card name" />
                    </div>
                    <div className="cardno">{item?.card_number}</div>
                  </div>
                </li>
              ))}
              <li>
                {/* <Button
                  className={`!py-0.5`}
                  onClick={(e) => handleAddNewCard(e, null, "new")}
                  variant="contained"
                  color="primary"
                >
                  +
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="BtnIcon"
                  onClick={(e) => handleAddNewCard(e, null, "new")}
                >
                  <PlusIcon />
                </Button>
              </li>
            </ul>
          )}
          {(showCard || customer?.card?.length === 0) && (
            <>
              <label htmlFor="">Card number</label>
              <div className="formFiled">
                <TextField
                  fullWidth
                  defaultValue={number || ""}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  type="text"
                  name={"number"}
                  error={errorType?.cardNumber}
                  placeholder="Enter 16 digit card number"
                  className=" text-sm font-semibold border border-solid rounded border-primary placeholder:text-sm"
                />
              </div>
              <label htmlFor="">Card holder name </label>
              <div className="formFiled">
                <TextField
                  fullWidth
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  name={"name"}
                  defaultValue={name || ""}
                />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    name={"expiry"}
                    defaultValue={expiry || ""}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size="small"
                    placeholder="MM/YY"
                    error={errorType?.cardExpire}
                    className="text-center text-sm font-semibold border border-solid rounded border-primary placeholder:text-sm"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    defaultValue={maskingCVV || ""}
                    name={"cvc"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size="small"
                    placeholder="CVV"
                    type="password"
                    error={errorType?.cvv}
                    className="text-center text-sm font-semibold border border-solid rounded border-primary placeholder:text-sm"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    defaultValue={zip || ""}
                    name={"zip"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size="small"
                    error={errorType?.zipCode}
                    placeholder="ZIP Code"
                    className="text-center"
                  />
                </Grid>
              </Grid>
              <div className="clear20"></div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                }
                className="normal-case"
                label="Do you want to save this card for future transactions"
              />
            </>
          )}

          {debitVsCredit && (
            <div className={`mb-5`}>
              <div className="clear20"></div>
              <ul className="transactions">
                <li>
                  <div className="transactionsDetails">
                    <Typography variant="h5">Total charged amount </Typography>
                  </div>
                  <div className="transactionsprice">
                    <Typography variant="h5">
                      {currencyFormat(apiCalcDetails?.total_amount || 0)}{" "}
                    </Typography>
                  </div>
                </li>

                <li>
                  <div className="transactionsDetails">
                    <Typography variant="h5">KYC compliance fee </Typography>
                  </div>
                  <div className="transactionsprice">
                    <Typography variant="h5" style={{ color: "Red" }}>
                      -{" "}
                      {currencyFormat(apiCalcDetails?.convenience_charge || 0)}{" "}
                    </Typography>
                  </div>
                </li>
                <li>
                  <div className="transactionsDetails">
                    <Typography variant="h3" className={`!mb-2`}>
                      Load amount{" "}
                    </Typography>
                  </div>
                  <div className="transactionsprice">
                    <Typography variant="h5">
                      {currencyFormat(
                        roundUp(apiCalcDetails?.total_amount || 0) -
                          roundUp(apiCalcDetails?.convenience_charge || 0),
                      )}{" "}
                    </Typography>
                  </div>
                </li>
                {/*<li>*/}
                {/*  <div className="transactionsDetails">*/}
                {/*    <Typography variant="h5">KYC compliance fee </Typography>*/}
                {/*  </div>*/}
                {/*  <div className="transactionsprice">*/}
                {/*    <Typography variant="h5">*/}
                {/*      {currencyFormat(debitVsCredit?.convenience || 0)}{" "}*/}
                {/*    </Typography>*/}
                {/*  </div>*/}
                {/*</li>*/}
              </ul>
              <Typography variant="body2" className={`!mt-3`}>
                This nominal bank fee helps protect you against fraud, Identity
                theft & ensures the integrity & security of the transaction{" "}
              </Typography>
            </div>
          )}
          <div className="clear10"></div>

          {loading ? (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="small"
                className="fs-14"
                disabled
                autoFocus
              >
                <LoadingIcon />
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Charge {currencyFormat(debitVsCredit?.total_amount || 0)}
              </Button>
            </>
          )}
        </div>
        <Footer />
      </>

      <Drawer
        anchor="bottom"
        open={open}
        // onClose={toggleDrawer(false)}
        // onOpen={toggleDrawer(true)}
      >
        <Grid container justifyContent={"center"}>
          <Grid item xs={2}>
            <SuccessIcon />
          </Grid>
        </Grid>
        <div className="clear20"></div>
        <Typography variant="h2" className="text-center !normal-case">
          Funds added successfully{" "}
        </Typography>
        <ul className="transactions">
          <li>
            <div className="transactionsDetails">
              <Typography variant="h5">Reference number: </Typography>
            </div>
            <div className="transactionsprice">
              <Typography variant="h5">{orderId}</Typography>
            </div>
          </li>
          <li>
            <div className="transactionsDetails">
              <Typography variant="h5">Amount added:</Typography>
            </div>
            <div className="transactionsprice">
              <Typography variant="h5">
                {currencyFormat(loadFunActivities?.amount)}{" "}
              </Typography>
            </div>
          </li>
        </ul>
        <div className="clear20"></div>
        <Button variant="contained" color="primary" fullWidth onClick={Receipt}>
          OK
        </Button>
      </Drawer>
    </>
  );
}
export default Payment;
