/* eslint-disable */
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import HeaderFull from "../components/headerFull";
import LoadDailog from "../components/loadDailog";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleRequestServices } from "../services/ActivitiesServices";
import { useCustomer } from "../context/customerContext";
import Loader from "../components/Loader";
import { currencyFormat, onlyNumber, roundUp } from "../utils/Common";
import Axios from "../config/Axios";
import CustomerConfirmModal from "../components/Modal";
import SignatureCanvas from "react-signature-canvas";
import { CustomerAuthentication } from "../services/VerificationServices";
import LayoutStyle from "../scss/base.module.scss";
import RemoveIcon from "../Assets/Icons/removeIcon";
import BottomNav from "../components/bottomNav";
import GainLogo from "../svg/Gainlogo";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RequestIcon from "../Assets/Icons/requestIcon";
import VerifyPINDrawer from "../components/VerifyPINDrawer";
import LoadingIcon from "../svg/loader";
import TransactionSuccessfulDrawer from "../components/transactionSuccessfulDrawer";

const moment = require("moment");

function GoodsServices() {
  const route = useNavigate();
  let padRef = useRef();

  const { externalId, custIdForDoobie, storeExternalId } = useParams();
  const {
    token,
    customer,
    setCurrentRequest,
    setCustomer,
    setSigData,
    setToken,
    getCustomer,
  } = useCustomer();
  const [requestInfo, setRequestInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [requiredFund, setRequiredFund] = useState(null);
  const [selectedTipValue, setSelectedTipValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [signError, setSignError] = useState("");

  const [completedTransaction, setCompletedTransaction] = useState(false);
  const [completedTransactionData, setCompletedTransactionData] =
    useState(null);
  const [state, setState] = useState(null);
  const [selectedTip, setSelectedTip] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fundLoaded, setFundLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [maxTips, setMaxTips] = useState(0);
  const [customTips, setCustomTips] = useState(null);
  const [vendorDetails, setVendorDetails] = useState();
  const [customTipsError, setCustomTipsError] = useState(null);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(null);
  const [qrAccNum, setQrAccNum] = useState(null);
  const [showPinDrawer, setShowPinDrawer] = useState(false);
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [dailog, setDailog] = useState(false);
  const [invalidLinkError, setInvalidLinkError] = useState(false);
  const [banError, setBanError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (custIdForDoobie) {
        try {
          const response = await Axios().get(
            `/getCustomerDetails/${custIdForDoobie}`,
          );
          const verify = await CustomerAuthentication(response?.data?.response);
          setToken(verify?.token);
          const cust = await getCustomer(
            verify?.response?.customer_external_id,
          );
          setCustomer({ ...(verify?.response || {}), ...(cust || {}) });
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [custIdForDoobie]);

  useEffect(() => {
    if (storeExternalId) {
      setLoading(true);
      Axios(token)
        .get(`/customer/getStoreDetails/${storeExternalId}`)
        .then((res) => {
          setLoading(false);
          // console.log(res?.data?.response?.store?.status === 'BAN');
          if (res?.data?.response?.store?.status === "BAN") {
            return setBanError("The store is inactive please try again later.");
          }
          setVendorDetails(res?.data?.response?.store?.VendorMerchant);
          setQrAccNum(res?.data?.response?.store?.account_number);
          setState((prev) => ({
            ...prev,
            account_number: res?.data?.response?.store?.account_number,
          }));
          setRequestInfo(res?.data?.response);
          const getTips = [];
          res?.data?.response?.tips?.map((item) => {
            if (item?.amountType === "percentage") {
              item.afterPercentage = item.afterPercentage =
                (roundUp(res?.contactless?.amount) * roundUp(item?.amount)) /
                100;
            }
            getTips.push(item);
          });
          setTips(getTips);
        })
        .catch((e) => {
          setLoading(false);
          setErrors(e?.response?.data?.message);
        });
    }
  }, [storeExternalId]);
  function isMoreThanFourHoursOld(timestamp) {
    const currentTime = moment();
    const givenTime = moment(timestamp);
    const hoursDifference = currentTime.diff(givenTime, "hours");
    return hoursDifference > 4;
  }
  useEffect(() => {
    if (externalId) {
      setLoading(true);
      getSingleRequestServices(externalId)
        .then((res) => {
          // if (res?.loadFund?.external_id) {
          //   route(`/receipt/${res?.loadFund?.external_id}`);
          //   return;
          // }
          if (
            isMoreThanFourHoursOld(res?.contactless?.createdAt) ||
            res?.contactless?.status !== "PENDING"
          ) {
            setInvalidLinkError(true);
          }
          setVendorDetails(res?.store?.VendorMerchant);
          setLoading(false);
          setCurrentRequest(externalId);
          setRequestInfo(res);
          setTotalAmount(roundUp(res?.contactless?.amount || 0));
          setState({ amount: roundUp(res?.contactless?.amount || 0) });
          const getTips = [];
          res?.tips?.map((item) => {
            if (item?.amountType === "percentage") {
              item.afterPercentage =
                (roundUp(res?.contactless?.amount) * roundUp(item?.amount)) /
                100;
            }
            getTips.push(item);
          });
          setTips(getTips);
        })
        .catch((e) => {
          setErrors(e?.response?.data?.message);
          setLoading(false);
        });
    }
  }, [token, externalId]);

  const handleClose = () => {
    setDailog(false);
  };
  const renderTipsText = ({ amount, amountType }) => {
    const prefix = amountType !== "percentage" ? "$" : "";
    const end = amountType === "percentage" ? "%" : "";
    const value = roundUp(amount || 0);

    return prefix + value + end;
  };
  const handleTips = (e, item = null, type) => {
    setCustomTipsError(null);
    setSelectedTip(item);
    if (type === "radio") {
      if (item?.amountType === "percentage") {
        const calculatePercentage =
          (roundUp(state?.amount || requestInfo?.contactless?.amount || 0) *
            roundUp(item?.amount)) /
          100;
        setSelectedTipValue(roundUp(calculatePercentage || 0));
        setTotalAmount(
          roundUp(calculatePercentage || 0) +
            roundUp(requestInfo?.contactless?.amount || state?.amount || 0),
        );
      } else {
        const calculatePercentage = roundUp(
          item?.amount || requestInfo?.contactless?.amount || 0,
        );
        setSelectedTipValue(roundUp(calculatePercentage || 0));
        setTotalAmount(
          roundUp(calculatePercentage || 0) +
            roundUp(requestInfo?.contactless?.amount || state?.amount || 0),
        );
      }

      setCustomTips(null);
    } else if (type === "text") {
      setSelectedTipValue(roundUp(e?.target?.value || 0));
      setTotalAmount(
        roundUp(e?.target?.value) +
          roundUp(requestInfo?.contactless?.amount || state?.amount || 0),
      );
      setCustomTips(roundUp(e?.target?.value) || 0);
    } else if (type === "reset") {
      setSelectedTipValue(0);
      setTotalAmount(
        roundUp(0) +
          roundUp(requestInfo?.contactless?.amount || state?.amount || 0),
      );
      setCustomTips(null);
      setCustomTip(false);
    }
  };
  function addOrSetQueryParameters(url, newParameters) {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    const parsedUrl = new URL(url);
    if (parsedUrl.search !== "") {
      const existingParams = new URLSearchParams(parsedUrl.search);
      for (const [key, value] of Object.entries(newParameters)) {
        existingParams.set(key, value);
      }
      parsedUrl.search = existingParams.toString();
    } else {
      const newParams = new URLSearchParams(newParameters);
      parsedUrl.search = newParams.toString();
    }

    return parsedUrl.toString();
  }
  const finalHandleSubmit = async () => {
    if (!state?.amount && !requestInfo?.contactless?.amount) {
      setErrors("Please enter a valid amount");
      return;
    }
    if (onlyNumber(state?.amount) <= 0) {
      setErrors("Enter a valid amount");
      return;
    }
    if (onlyNumber(state?.amount) > 2000) {
      setErrors("Amount can not be greater than 2000");
      return;
    }
    if (requestInfo?.contactless?.amount) {
      if (
        onlyNumber(customTips) > onlyNumber(requestInfo?.contactless?.amount)
      ) {
        setErrors("Tips can not be greater than transaction amount");
        return;
      }
    } else {
      if (onlyNumber(customTips) > onlyNumber(state?.amount)) {
        setErrors("Tips can not be greater than transaction amount");
        return;
      }
    }
    if (
      customer?.enablePin &&
      onlyNumber(customer.amount) > onlyNumber(totalAmount)
    ) {
      setShowPinDrawer(true);
    } else {
      await handleSubmit();
    }
  };
  const handleSubmit = async () => {
    let sign;
    if (
      !requestInfo?.contactless?.contactLess_external_id &&
      !requestInfo?.store?.account_number
    ) {
      return setErrors("Invalid Account Number");
    }
    if (!requestInfo?.contactless?.contactLess_external_id && !totalAmount) {
      return setErrors("Enter Your Amount");
    }
    if (padRef?.current?.isEmpty()) {
      return setErrors("Please Sign to Continue.");
    }
    sign = padRef?.current?.toDataURL();
    setSigData(sign);
    if (roundUp(totalAmount) > roundUp(customer?.amount || 0)) {
      const requiredFunds =
        roundUp(totalAmount) - roundUp(customer?.amount || 0);
      setRequiredFund(roundUp(requiredFunds));
      return setDailog(true);
    } else {
      await handleTransfer();
    }
  };
  const handleTransfer = async (loadData) => {
    const data = {
      customer_external_id: customer.customer_external_id,
      paymentFor: externalId || storeExternalId ? "CONTACTLESS" : "STANDARD",
      is_test: requestInfo?.store?.is_test,
      amount: roundUp(requestInfo?.contactless?.amount || state?.amount || 0),
      customTips: selectedTipValue,
      tip: selectedTipValue,
      contactless: !!requestInfo?.contactless?.contactLess_external_id,
      contactLess_external_id:
        requestInfo?.contactless?.contactLess_external_id,
      storeId: requestInfo?.store?.store_id || requestInfo?.store?.id,
      fund_id: loadData?.fund_id || customer?.fund_id || null,
      loadFund: !!fundLoaded?.fund_id,
    };
    try {
      setLoading(true);
      const response = await Axios(token).post(
        `/transfer/${data?.customer_external_id}`,
        data,
      );
      setLoading(false);
      let responseData = response?.data?.response;
      setCurrentWalletBalance(responseData?.balance);
      if (responseData?.redirect_url) {
        window.location.href = addOrSetQueryParameters(
          responseData?.redirect_url,
          {
            transaction_id: responseData?.transaction_id,
            order_id: responseData?.order_id,
            contactless_id: responseData?.contactless_id,
            tip: selectedTipValue,
          },
        );
        return;
      }
      responseData.amount = roundUp(responseData.amount);
      setCustomer((prev) => ({
        ...prev,
        amount: roundUp(responseData?.balance || 0),
        payableAmount: roundUp(totalAmount || 0),
      }));
      setCompletedTransaction(true);
      setCompletedTransactionData(responseData);
      setDailog(false);
    } catch (e) {
      setLoading(false);
      if (e?.response?.data?.message === "cvv_invalid") {
        return setErrors("Invalid CVV");
      }
      if (
        e?.response?.data?.message ===
        "payment.number must be a valid credit card number"
      ) {
        return setErrors("Invalid card number");
      }
      if (e?.response?.data?.message === "card_expired") {
        return setErrors("Card is expired");
      }
      setErrors(e?.response?.data?.response?.message);
    }
  };
  const handleChange = ({ target }) => {
    setState((prev) => ({
      ...prev,
      [target.name]: onlyNumber(target.value),
    }));
    if (target?.name === "account_number") {
      setRequestInfo(null);
      setErrors(null);
      if (target?.value?.length === 10) {
        setLoading(true);
        Axios(token)
          .get(`/account/${target.value}`)
          .then((res) => {
            setLoading(false);
            if (res?.data?.response) {
              if (!res?.data?.response?.store?.account_number) {
                setErrors("Account not found");
                return;
              }
              setRequestInfo((prev) => ({
                ...prev,
                ...res?.data?.response,
              }));
              const getTips = [];
              res?.data?.response?.tips?.map((item) => {
                if (item?.amountType === "percentage") {
                  item.afterPercentage =
                    (Number(state?.amount || 0) * Number(item?.amount)) / 100;
                }
                if (item?.tipsType === "inputtext") {
                  setMaxTips(item?.amount);
                }
                getTips.push(item);
              });
              setTips(getTips);
              setSelectedTip(null);
              setTotalAmount(state.amount);
            } else {
              setErrors("Account not found");
            }
          })
          .catch((e) => {
            setLoading(false);
            setErrors(e?.response?.data?.response?.message);
          });
      }
    } else if (target?.name === "amount") {
      setState((prev) => ({
        ...prev,
        amount: onlyNumber(target.value),
      }));
      tips?.map((item) => {
        if (item?.amountType === "percentage") {
          item.afterPercentage =
            (Number(target?.value || 0) * Number(item?.amount)) / 100;
        } else {
          item.afterPercentage = Number(item?.amount);
        }
      });
      const calculateTip =
        selectedTip?.amountType === "percentage" &&
        selectedTip?.tipsType === "radio"
          ? percentage(
              roundUp(selectedTip?.amount || 0),
              roundUp(target?.value),
            )
          : roundUp(selectedTip?.amount);
      let calculateTips = 0;
      if (customTips > 0) {
        calculateTips =
          target?.value > 0 && roundUp(target?.value) + Number(customTips);
      } else {
        calculateTips = roundUp(target?.value) + calculateTip;
      }
      setTotalAmount(calculateTips);
      setState((prev) => ({ ...prev, tip: calculateTip }));
    }
  };
  const percentage = (partialValue, totalValue) => {
    let calculatePercentage =
      (parseFloat(partialValue) / 100) * parseFloat(totalValue);
    return Number(calculatePercentage.toFixed(2));
  };
  const handleClear = (e) => {
    e.preventDefault();
    padRef.current.clear();
  };
  const [isCustomTip, setCustomTip] = useState(false);
  const toggleCustomTip = () => {
    setCustomTip(!isCustomTip);
  };
  const [isActive, setActive] = useState(false);
  const toggleAccount = () => {
    setActive(!isActive);
  };
  const handleCompletedLoadFunds = async (data) => {
    setDailog(false);
    await handleTransfer(data);
  };
  useEffect(() => {
    if (isPinVerified) {
      setShowPinDrawer(false);
      handleTransfer();
    }
  }, [isPinVerified]);
  return (
    <>
      <>
        {errors && (
          <CustomerConfirmModal
            type={`Error`}
            headLine={`Error`}
            showCancel={`Close`}
            message={errors}
            isModalOpen={!!errors}
            CloseOnClick={() => {
              setErrors("");
            }}
          />
        )}

        {(invalidLinkError || banError) && (
          <CustomerConfirmModal
            type={`Error`}
            headLine={`Error`}
            showCancel={`Close`}
            message={banError ? banError : "Invalid Contactless Link"}
            CloseOnClick={() => {
              if (banError) {
                setBanError("");
                route("/dashboard");
              } else {
                setInvalidLinkError(false);
                route("/dashboard");
              }
            }}
            isModalOpen={!!banError || !!invalidLinkError}
          />
        )}
        {loading && <Loader show={loading} />}
        <HeaderFull />
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
        >
          <Container>
            {!requestInfo?.contactless?.contactLess_external_id && (
              <>
                <TextField
                  fullWidth
                  type="number"
                  onChange={handleChange}
                  name={`amount`}
                  disabled={!!requestInfo?.contactless?.amount}
                  label={"Enter amount"}
                  margin="dense"
                  value={
                    state?.amount || requestInfo?.contactless?.amount || ""
                  }
                  placeholder="0.00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> $</InputAdornment>
                    ),
                  }}
                  required
                />
                <TextField
                  fullWidth
                  placeholder="XXXX XXXX XXXX"
                  label="Account number"
                  margin="dense"
                  onChange={handleChange}
                  name={`account_number`}
                  type="number"
                  disabled={
                    !!requestInfo?.contactless?.contactLess_external_id ||
                    qrAccNum
                  }
                  value={
                    state?.account_number ||
                    requestInfo?.store?.account_number ||
                    qrAccNum ||
                    ""
                  }
                  required
                />
              </>
            )}

            {!!requestInfo?.contactless?.contactLess_external_id && (
              <>
                <ul className={`${LayoutStyle.cardList} `}>
                  <li onClick={toggleAccount}>
                    <Box className={`${LayoutStyle.cardBox}`} bgColor="sky">
                      <Box className={LayoutStyle.cardLogo}>
                        <Avatar color="sky">
                          <SvgIcon
                            viewBox="0 0 512 512"
                            sx={{ width: "1.25rem!important" }}
                          >
                            <RequestIcon fill="black" />
                          </SvgIcon>
                        </Avatar>
                      </Box>
                      <Box>
                        <Typography variant="h5" mb={0}>
                          You are transferring
                          <Box
                            component={"span"}
                            mx={0.5}
                            color={"primary"}
                            fontWeight={600}
                          >
                            {currencyFormat(totalAmount || 0)}
                          </Box>{" "}
                          to
                          <Box
                            component={"span"}
                            mx={0.5}
                            color={"primary"}
                            fontWeight={600}
                          >
                            {isActive
                              ? state?.account_number ||
                                requestInfo?.store?.account_number
                              : "*****" +
                                (state?.account_number?.slice(-4) ||
                                  requestInfo?.store?.account_number?.slice(
                                    -4,
                                  ))}
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  </li>
                </ul>
              </>
            )}
            {tips?.length > 0 && (
              <>
                <Box className={"clear10"} />
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h4">Enter tip amount</Typography>
                </Stack>
                <>
                  <ul className={LayoutStyle.tipList}>
                    {tips?.map((item, index) => (
                      <Fragment key={index}>
                        {item?.amountType === "percentage" &&
                          item?.tipsType === "radio" &&
                          roundUp(item?.amount) > 0 && (
                            <li key={index}>
                              <input
                                className={LayoutStyle.tipRadio}
                                checked={
                                  roundUp(selectedTip?.amount) ===
                                  roundUp(item?.amount)
                                }
                                type="radio"
                                name="cardRadio"
                                onChange={(e) => handleTips(e, item, "radio")}
                              />
                              <div className={LayoutStyle.tipBox}>
                                <Typography variant="h3" mb={0}>
                                  {renderTipsText(item)}
                                </Typography>
                                {(state?.show_tip_amount ||
                                  requestInfo?.store?.show_tip_amount) && (
                                  <Typography variant="body2">
                                    {currencyFormat(item?.afterPercentage || 0)}
                                  </Typography>
                                )}
                              </div>
                            </li>
                          )}
                        {item?.amountType === "flat" &&
                          item?.tipsType === "radio" && (
                            <li key={`${index}-${item?.amount}`}>
                              <input
                                className={LayoutStyle.tipRadio}
                                checked={
                                  roundUp(selectedTip?.amount) ===
                                  roundUp(item?.amount)
                                }
                                type="radio"
                                name="cardRadio"
                                onChange={(e) => handleTips(e, item, "radio")}
                              />
                              <div className={LayoutStyle.tipBox}>
                                <Typography variant="h3" m={0}>
                                  {renderTipsText(item)}
                                </Typography>
                              </div>
                            </li>
                          )}
                      </Fragment>
                    ))}
                  </ul>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <Box
                        className={`${LayoutStyle.tipCustom} ${
                          isCustomTip ? LayoutStyle.active : ""
                        }`}
                        onClick={toggleCustomTip}
                      >
                        Custom Tip
                        {isCustomTip ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box
                        textAlign={"center"}
                        justifyContent={"center"}
                        className={LayoutStyle.tipCustom}
                        onClick={(e) => handleTips(e, null, "reset")}
                      >
                        Reset Tip
                      </Box>
                    </Grid>
                  </Grid>
                </>
                {isCustomTip && (
                  <TextField
                    fullWidth
                    type="number"
                    name={`customTips`}
                    value={customTips > 0 && customTips}
                    onChange={(e) => handleTips(e, null, "text")}
                    placeholder="Enter custom tip amount"
                    // label="Enter Custom Tip"
                    margin="dense"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> $</InputAdornment>
                      ),
                    }}
                  />
                )}
                <span style={{ color: "red" }}>
                  {customTipsError && customTipsError}
                </span>
              </>
            )}
            <Box className="clear10" />

            <Typography
              variant="h4"
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              marginY={1}
              justifyContent={"space-between"}
              gutterBottom
            >
              Accept authorization
              <Box component={"div"} width={"5rem"} height={"1.25rem"} mr={0}>
                <GainLogo />
              </Box>
            </Typography>
            <Typography variant="body2" lineHeight={1.25}>
              {`You understand that your funds in your Gain Funds Account are being received by Gain Federal Credit Union. You represent that all of the information you have provided, including information provided as part of the "Know-Your-Customer" or KYC process, is correct and up to date. Accordingly, your account statement will show your credit/debit card account funding is being made to your Gain Account held in the name of Gain Federal Credit Union as the Merchant of Record.`}
            </Typography>
            <Box className="clear10" />
            <>
              <Box
                component={"fieldset"}
                className={LayoutStyle.signatureWrapper}
              >
                <legend>Your Signature</legend>
                <SignatureCanvas
                  canvasProps={{
                    className: LayoutStyle.signatureBox,
                  }}
                  minWidth={2}
                  ref={padRef}
                  penColor="darkblue"
                  clearOnResize={false}
                />

                <Box className={LayoutStyle.signatureClear}>
                  <IconButton color="error" onClick={handleClear}>
                    <SvgIcon viewBox="0 0 22 15">
                      <RemoveIcon />
                    </SvgIcon>
                  </IconButton>
                </Box>
              </Box>
              {signError && (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "red" }}
                >
                  {signError}
                </FormHelperText>
              )}
            </>
            <Box className="clear10" />
            {loading ? (
              <Button variant="contained" color="primary" fullWidth disabled>
                <SvgIcon viewBox="0 0 200 200" className="loader">
                  <LoadingIcon />
                </SvgIcon>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={finalHandleSubmit}
                fullWidth
              >
                Transfer {currencyFormat(onlyNumber(totalAmount))}
              </Button>
            )}
          </Container>
        </Box>
        <BottomNav />
      </>
      <TransactionSuccessfulDrawer
        completedTransaction={completedTransaction}
        totalAmount={totalAmount}
        completedTransactionData={completedTransactionData}
        fundLoaded={fundLoaded}
      />

      <VerifyPINDrawer
        onSubmit={() => {
          setIsPinVerified(true);
          setShowPinDrawer(false);
        }}
        showPinDrawer={showPinDrawer}
        token={token}
        onClose={() => setShowPinDrawer(false)}
      />

      <Drawer open={dailog} anchor="bottom" className="grayBg">
        <IconButton
          color="primary"
          onClick={handleClose}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
            zIndex: 1000,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box component={"div"} pb={2} pt={5}>
          <LoadDailog
            onComplete={handleCompletedLoadFunds}
            cust={customer}
            info={requestInfo}
            amount={requiredFund}
          />
        </Box>
      </Drawer>
    </>
  );
}
export default GoodsServices;
