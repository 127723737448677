import React from "react";

export default function HomeIcon() {
  return (
    <> 
        <path d="M1.22655 7.54421L7.3853 1.21875C8.14754 0.438275 9.18002 0 10.2564 0C11.3328 0 12.3653 0.438275 13.1275 1.21875L19.2863 7.54421C19.5133 7.77583 19.6933 8.05143 19.8158 8.35501C19.9383 8.6586 20.0009 8.98414 20 9.31277V17.4985C20 18.1619 19.7434 18.7982 19.2865 19.2673C18.8297 19.7365 18.2101 20 17.5641 20H2.94873C2.30269 20 1.68311 19.7365 1.22629 19.2673C0.76947 18.7982 0.512833 18.1619 0.512833 17.4985V9.31277C0.511921 8.98414 0.574537 8.6586 0.697054 8.35501C0.819569 8.05143 0.999544 7.77583 1.22655 7.54421ZM7.82052 18.3323H12.6923V15.052C12.6923 14.3886 12.4357 13.7523 11.9788 13.2832C11.522 12.8141 10.9025 12.5505 10.2564 12.5505C9.61037 12.5505 8.99079 12.8141 8.53397 13.2832C8.07715 13.7523 7.82052 14.3886 7.82052 15.052V18.3323ZM2.13676 17.4985C2.13676 17.7196 2.22231 17.9317 2.37458 18.0881C2.52685 18.2445 2.73338 18.3323 2.94873 18.3323H6.19659V15.052C6.19659 13.9463 6.62432 12.8859 7.38568 12.104C8.14705 11.3221 9.17968 10.8829 10.2564 10.8829C11.3331 10.8829 12.3658 11.3221 13.1271 12.104C13.8885 12.8859 14.3162 13.9463 14.3162 15.052V18.3323H17.5641C17.7794 18.3323 17.986 18.2445 18.1382 18.0881C18.2905 17.9317 18.3761 17.7196 18.3761 17.4985V9.31277C18.3753 9.09178 18.2898 8.87999 18.1382 8.72325L11.9794 2.40029C11.5217 1.93245 10.9022 1.66981 10.2564 1.66981C9.61063 1.66981 8.99113 1.93245 8.53342 2.40029L2.37467 8.72575C2.22357 8.88188 2.13815 9.09265 2.13676 9.31277V17.4985Z" />
    </>

  );
}
