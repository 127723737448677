import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader({ show = true }) {
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1111111,
        }}
        open={show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
