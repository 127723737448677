/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Grid,
  Typography,
  Box,
  Drawer,
  IconButton,
  Stack,
  Container,
} from "@mui/material";
import HeaderFull from "../components/headerFull";
import ConfirmationImg from "../svg/confirmationImg";
import PinIcon from "../svg/pinIcon";
import TwoFactor from "../svg/twoFactor";
import OtpIcon from "../svg/otpIcon";
import TwoFAIcon from "../svg/twoFAIcon";
import LayoutStyle from "../scss/base.module.scss";
import { useCustomer } from "../context/customerContext";
function Confirmation() {
  const navigate = useNavigate();
  const { customer } = useCustomer();

  return (
    <>
      <HeaderFull />
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} `}
        justifyContent={"center"}
      >
        <Container>
          <Box px={4} pb={2} className={LayoutStyle.confirmationImg}>
            <ConfirmationImg />
          </Box>
          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={2}>
              <PinIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" gutterBottom>
                Enable 6 Digits PIN
              </Typography>
              <Typography variant="body2">
                Enabling a 6-digit PIN for your account adds an extra layer of
                security to prevent unauthorized access.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TwoFactor />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" gutterBottom>
                Enable two factor authentication
              </Typography>
              <Typography variant="body2">
                Enabling two-factor authentication (2FA) provides an additional
                layer of security, making it significantly harder for
                unauthorized users to access your accounts.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <OtpIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" gutterBottom>
                Why OTP is important
              </Typography>
              <Typography variant="body2">
                Enabling OTP (One-Time Password) enhances security by providing
                a unique, time-sensitive code for each login attempt, reducing
                the risk of unauthorized access.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TwoFAIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" gutterBottom>
                Why enabling 2FA is important
              </Typography>
              <Typography variant="body2">
                Enabling 2FA (Two-Factor Authentication) is important because it
                significantly increases account security by requiring a second
                form of verification beyond just a password.
              </Typography>
            </Grid>
          </Grid>
          <Box className="clear40" />
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              size="small"
              onClick={() => navigate("/dashboard")}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              onClick={() => {
                if (!customer?.phone) {
                  return navigate(
                    `/verify-phone/${customer?.customer_external_id}`,
                  );
                }
                return navigate("/two-factor");
              }}
            >
              PROCEED
            </Button>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
export default Confirmation;
