

import React from "react";
export default function Withdraw() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" height="601pt" version="1.1" viewBox="-5 1 601 601.8" width="601pt">
                <g id="surface1">
                <path d="M 428.945312 122.441406 L 0.402344 122.441406 L 0.402344 81.628906 L 214.671875 0 L 428.945312 81.628906 Z M 428.945312 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 347.316406 122.441406 L 388.128906 122.441406 L 388.128906 306.101562 L 347.316406 306.101562 Z M 347.316406 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 245.28125 122.441406 L 286.097656 122.441406 L 286.097656 306.101562 L 245.28125 306.101562 Z M 245.28125 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 143.25 122.441406 L 184.0625 122.441406 L 184.0625 306.101562 L 143.25 306.101562 Z M 143.25 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 41.214844 122.441406 L 82.027344 122.441406 L 82.027344 306.101562 L 41.214844 306.101562 Z M 41.214844 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 347.316406 122.441406 L 388.128906 122.441406 L 388.128906 142.847656 L 347.316406 142.847656 Z M 347.316406 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(20.784314%,50.980392%,74.117647%)',fillOpacity:1,}} />
                <path d="M 245.28125 122.441406 L 286.097656 122.441406 L 286.097656 142.847656 L 245.28125 142.847656 Z M 245.28125 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(20.784314%,50.980392%,74.117647%)',fillOpacity:1,}} />
                <path d="M 143.25 122.441406 L 184.0625 122.441406 L 184.0625 142.847656 L 143.25 142.847656 Z M 143.25 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(20.784314%,50.980392%,74.117647%)',fillOpacity:1,}} />
                <path d="M 41.214844 122.441406 L 82.027344 122.441406 L 82.027344 142.847656 L 41.214844 142.847656 Z M 41.214844 122.441406 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(20.784314%,50.980392%,74.117647%)',fillOpacity:1,}} />
                <path d="M 20.808594 306.101562 L 408.539062 306.101562 L 408.539062 346.914062 L 20.808594 346.914062 Z M 20.808594 306.101562 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(24.313725%,54.901961%,78.039216%)',fillOpacity:1,}} />
                <path d="M 0.402344 346.914062 L 428.945312 346.914062 L 428.945312 387.730469 L 0.402344 387.730469 Z M 0.402344 346.914062 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(26.27451%,59.607843%,81.960784%)',fillOpacity:1,}} />
                <path d="M 235.078125 61.21875 C 235.078125 72.492188 225.941406 81.628906 214.671875 81.628906 C 203.402344 81.628906 194.265625 72.492188 194.265625 61.21875 C 194.265625 49.949219 203.402344 40.8125 214.671875 40.8125 C 225.941406 40.8125 235.078125 49.949219 235.078125 61.21875 Z M 235.078125 61.21875 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(20.784314%,50.980392%,74.117647%)',fillOpacity:1,}} />
                <path d="M 326.910156 244.882812 L 530.976562 244.882812 L 530.976562 591.796875 L 326.910156 591.796875 Z M 326.910156 244.882812 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(53.333333%,70.196078%,21.568627%)',fillOpacity:1,}} />
                <path d="M 326.910156 530.578125 L 326.910156 591.796875 L 530.976562 591.796875 L 530.976562 326.507812 Z M 326.910156 530.578125 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(50.196078%,65.882353%,20.392157%)',fillOpacity:1,}} />
                <path d="M 469.757812 550.984375 L 388.128906 550.984375 C 388.128906 539.707031 379 530.578125 367.722656 530.578125 L 367.722656 306.101562 C 379 306.101562 388.128906 296.96875 388.128906 285.695312 L 469.757812 285.695312 C 469.757812 296.96875 478.890625 306.101562 490.164062 306.101562 L 490.164062 530.578125 C 478.890625 530.578125 469.757812 539.707031 469.757812 550.984375 Z M 469.757812 550.984375 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(41.960784%,58.823529%,16.470588%)',fillOpacity:1,}} />
                <path d="M 490.164062 418.339844 C 490.164062 390.164062 462.753906 367.320312 428.945312 367.320312 C 395.132812 367.320312 367.722656 390.164062 367.722656 418.339844 C 367.722656 446.515625 395.132812 469.355469 428.945312 469.355469 C 462.753906 469.355469 490.164062 446.515625 490.164062 418.339844 Z M 490.164062 418.339844 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(53.333333%,70.196078%,21.568627%)',fillOpacity:1,}} />
                <path d="M 418.742188 316.304688 L 439.148438 316.304688 L 439.148438 336.710938 L 418.742188 336.710938 Z M 418.742188 316.304688 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(53.333333%,70.196078%,21.568627%)',fillOpacity:1,}} />
                <path d="M 418.742188 499.964844 L 439.148438 499.964844 L 439.148438 520.371094 L 418.742188 520.371094 Z M 418.742188 499.964844 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(53.333333%,70.196078%,21.568627%)',fillOpacity:1,}} />
                <path d="M 143.25 459.152344 L 163.65625 459.152344 L 163.65625 479.558594 L 143.25 479.558594 Z M 143.25 459.152344 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 143.25 418.339844 L 163.65625 418.339844 L 163.65625 438.746094 L 143.25 438.746094 Z M 143.25 418.339844 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 143.25 499.964844 L 163.65625 499.964844 L 163.65625 520.371094 L 143.25 520.371094 Z M 143.25 499.964844 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 143.25 540.78125 L 163.65625 540.78125 L 163.65625 561.1875 L 143.25 561.1875 Z M 143.25 540.78125 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 184.0625 540.78125 L 204.46875 540.78125 L 204.46875 561.1875 L 184.0625 561.1875 Z M 184.0625 540.78125 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 224.875 540.78125 L 245.28125 540.78125 L 245.28125 561.1875 L 224.875 561.1875 Z M 224.875 540.78125 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 265.691406 540.78125 L 286.097656 540.78125 L 286.097656 561.1875 L 265.691406 561.1875 Z M 265.691406 540.78125 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(99.215686%,71.372549%,18.431373%)',fillOpacity:1,}} />
                <path d="M 568.292969 414.839844 L 530.976562 377.527344 L 530.976562 459.152344 L 479.960938 408.136719 C 468.6875 396.859375 450.421875 396.859375 439.148438 408.136719 C 427.871094 419.410156 427.871094 437.675781 439.148438 448.949219 L 510.570312 520.371094 C 490.96875 539.972656 479.960938 566.554688 479.960938 594.277344 L 479.960938 602 L 592.199219 602 L 592.199219 472.558594 C 592.199219 450.90625 583.597656 430.144531 568.292969 414.839844 Z M 568.292969 414.839844 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(100%,71.764706%,50.980392%)',fillOpacity:1,}} />
                <path d="M 551.382812 489.761719 C 548.679688 489.761719 546.078125 488.691406 544.171875 486.773438 L 513.5625 456.164062 C 509.644531 452.113281 509.753906 445.652344 513.804688 441.734375 C 517.765625 437.917969 524.03125 437.917969 527.988281 441.734375 L 558.597656 472.34375 C 562.578125 476.335938 562.578125 482.792969 558.597656 486.773438 C 556.679688 488.691406 554.089844 489.761719 551.382812 489.761719 Z M 551.382812 489.761719 " style={ {stroke:'none',fillRule:'nonzero',fill:'rgb(94.901961%,64.313725%,43.529412%)',fillOpacity:1,}} />
                </g>
                </svg>
        </>

    );
}
