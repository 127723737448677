/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Drawer,
  SvgIcon,
  Typography,
} from "@mui/material";
import HeaderFull from "../components/headerFull";
import { useCustomer } from "../context/customerContext";
import { getCustomerInfoServices } from "../services/CustomerServices";
import Loader from "../components/Loader";
import {
  getActivitiesServices,
  getRequestsServices,
} from "../services/ActivitiesServices";
import PriceCard from "../components/priceCard";
import P2PCancelModal from "../components/Modal3";
import Axios from "../config/Axios";
import P2PModal from "../components/Modal2";
import FirstTime2fa from "../components/firstTime2fa";
import FirstTimeOtp from "../components/firstTimeOtp";
import PinComponent from "../components/pinComponent";
import DisableOtpModal from "../components/DisableOtpModal";
import LayoutStyle from "../scss/base.module.scss";
import InformationSlider from "../components/informationSlider";
import BottomNav from "../components/bottomNav";
import AddIcon from "../Assets/Icons/addIcon";
import { mapCardAndBank, maskExceptLast4 } from "../utils/CardUtility";

import BankIcon from "../Assets/Icons/BankIcon";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CustomerConfirmModal from "../components/Modal";
import pusherSub from "../utils/Pusher";
import { toString } from "lodash";
import Tour from "../components/projectGuide/tour";
import { currencyFormat } from "../utils/Common";

function Dashboard() {
  const { customer, setCustomer, token, handleRefresh, getCustomer } =
    useCustomer();
  let { p2pId } = customer;
  const [customerInfo, setCustomerInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [trans, setTrans] = useState();
  const [failedModal, setFailedModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [p2pDetails, setP2pDetails] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const hasEffectRun = useRef(false);
  const [sameCustomer, setSameCustomer] = useState(false);
  const [firstTime2fa, setFirstTime2fa] = useState(!customer?.not_first_time);
  const [showModal, setShowModal] = useState(false);
  const [openPinDrawer, setOpenPinDrawer] = useState(false);
  const [twoFactor, setTwoFactor] = React.useState(false);
  const [otp, setOtp] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [requestMoneyModal, setRequestMoneyModal] = useState(false);
  const [requestP2PDetailsModal, setRequestP2PDetailsModal] = useState();
  const [pusherDialogue, setPusherDialogue] = useState();
  // const [showDrawer, setShowDrawer] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setFirstTime2fa(!customer?.not_first_time);
  }, [customer?.not_first_time]);
  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p",
      null,
      (data) => {
        setPusherDialogue(data?.key);
      },
    );
  }, []);

  const enable2fa = () => {
    setLoading(true);
    Axios(token)
      .post("/enable-2fa", {
        otp: otp,
      })
      .then((res) => {
        setLoading(false);
        setTwoFactor(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.data?.response?.message || e?.message);
      });
  };

  useEffect(() => {
    if (customer?.show_guidance_first_time === true) {
      setRunTour(customer?.show_guidance_first_time);
    }
    if (customer?.customer_external_id) {
      const filterData = mapCardAndBank(customer);
      setCustomerInfo(filterData);
    }
  }, [customer]);

  const handleSendOtp = () => {
    setLoading(true);
    Axios(token)
      .post("/createOtp", {
        ph: customer?.phone,
      })
      .then((res) => {
        // console.log(res?.data);
        setLoading(false);
        setFirstTime2fa(false);
        setTwoFactor(true);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const handleSetPin = () => {
    if (pin !== confirmPin) {
      setError("PIN does not match");
    } else {
      setLoading(true);
      setError("");
      Axios(token)
        .post("/setCustPin", { pin: pin, enable_pin: true })
        .then((res) => {
          setLoading(false);
          // setTwoFactor(true);
          setOpenPinDrawer(false);
          handleSendOtp();
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };
  const disableOtp = () => {
    setLoading(true);
    Axios(token)
      .get("/disable2fa")
      .then((res) => {
        setLoading(false);
        setShowModal(false);
        handleRefresh();
        // console.log(res?.data);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (!hasEffectRun.current && p2pId) {
      setLoading(true);
      Axios(token)
        .get(`/fetch-p2p-details/${p2pId}`)
        .then((res) => {
          // console.log(res?.data?.response);
          if (res?.data?.response?.type === "REQUEST") {
            setRequestP2PDetailsModal(res?.data?.response);
            if (!res?.data?.response?.is_opened) {
              setRequestMoneyModal(true);
            }
            setLoading(false);
          } else {
            if (res?.data?.response?.sender?.id === customer?.id) {
              setSameCustomer(true);
            } else {
              setP2pDetails(res?.data?.response);
              setSuccessModal(true);
              setLoading(false);
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  }, []);
  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p_req",
      null,
      (data) => {
        // console.log(data?.key?.friendsandfamily_external_id);
        p2pId = data?.key?.friendsandfamily_external_id;
      },
      () => {
        setLoading(true);
        Axios(token)
          .get(`/fetch-p2p-details/${p2pId}`)
          .then((res) => {
            // console.log(res?.data?.response);
            if (res?.data?.response?.type === "REQUEST") {
              setRequestP2PDetailsModal(res?.data?.response);
              setRequestMoneyModal(true);
              setLoading(false);
            } else {
              if (res?.data?.response?.sender?.id === customer?.id) {
                setSameCustomer(true);
              } else {
                setP2pDetails(res?.data?.response);
                setSuccessModal(true);
                setLoading(false);
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
      },
    );
  }, []);

  const receiveMoney = () => {
    setLoading(true);
    Axios(token)
      .get(`/receive-money/${p2pId}`)
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        getCustomerInfoServices(customer?.customer_external_id, token)
          .then((data) => {
            setLoading(false);
            setCustomer((prev) => ({ ...prev, ...data }));
            setSuccessModal(false);
            // setOpenDrawer(true);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleDeleteCard = (external_id, isBank = false) => {
    // console.log(external_id);
    setLoading(true);
    const data = customer?.customer_external_id;
    Axios(token)
      .post(`/delete-card/${external_id}`, {
        customerId: data,
        type: isBank ? "bank" : "card",
      })
      .then(() => {
        getCustomerInfoServices(customer?.customer_external_id)
          .then((res) => {
            setLoading(false);
            if (res) {
              const filterData = mapCardAndBank(customer);
              setCustomerInfo(filterData);
              setCustomer((prev) => ({ ...prev, ...res }));
            }
            getActivitiesServices(
              customer?.customer_external_id,
              token,
              1,
            ).then((res) => {
              setTrans(res?.trans);
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((e) => {
        setLoading(false);
        console.log(e?.response);
        setError(
          e?.response?.data?.response?.message ||
            e?.response?.data?.message ||
            e?.message,
        );
      });
  };

  const handleCancel = () => {
    setLoading(true);
    Axios(token)
      .get(`/reject-money/${p2pId}`)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleDeleteConfirm = (external_id, isBank = false) => {
    setDeleteConfirmDialog(true);
    setDeleteConfirm({ external_id, isBank });
  };

  const handleDelete = () => {
    handleDeleteCard(deleteConfirm?.external_id, deleteConfirm?.isBank);
    setDeleteConfirmDialog(false);
  };

  const requestViewHandler = () => {
    setLoading(true);
    Axios(token)
      .post("/update-request-money", {
        reqId: requestP2PDetailsModal?.friendsandfamily_external_id,
      })
      .then((res) => {
        setLoading(false);
        setRequestMoneyModal(false);
        setRequestP2PDetailsModal({});
        navigate("/requests");
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  return (
    <>
      {loading && <Loader show={loading} />}
      <Tour runTour={runTour} />
      <HeaderFull />
      {deleteConfirmDialog && (
        <CustomerConfirmModal
          noHeadline={true}
          type={"Error"}
          isModalOpen={deleteConfirmDialog}
          message={`Are you sure you want to delete this ${
            deleteConfirm?.isBank ? "account" : "card"
          }?`}
          showOk={true}
          showOkText={"Yes"}
          showCancel={true}
          cancelBtnText={"No"}
          modalCallBack={handleDelete}
          cancelHandler={() => {
            setDeleteConfirmDialog(false);
            setDeleteConfirm(null);
          }}
          CloseOnClick={() => {
            setDeleteConfirmDialog(false);
            setDeleteConfirm(null);
          }}
        />
      )}

      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => {
            setError("");
          }}
        />
      )}
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        <Container>
          <PriceCard
            className="send_money"
            data={customer}
            setFailedModal={setFailedModal}
            handleClick={() => setFailedModal(true)}
            arrow={true}
            accountNo={true}
            accountTop={false}
          />
          <InformationSlider />
          {customerInfo?.length > 0 && (
            <>
              <Typography variant="h4" mb={0}>
                Linked Accounts & Cards
              </Typography>

              <Box
                component={"ul"}
                pb={0.75}
                className={`${LayoutStyle.cardList} ${LayoutStyle.cardScroll}`}
              >
                {customerInfo?.map((item, index) => (
                  <li key={index}>
                    <Box className={LayoutStyle.cardBox}>
                      <Box className={LayoutStyle.cardLogo}>
                        {item?.type === "Bank" ? (
                          <SvgIcon
                            sx={{ width: 20, height: 20 }}
                            viewBox="0 0 20 20"
                          >
                            <BankIcon />
                          </SvgIcon>
                        ) : (
                          <img src={item?.image} alt="card name" />
                        )}
                      </Box>
                      <Box
                        className={LayoutStyle.cardNo}
                        justifyContent={"center"}
                      >
                        {maskExceptLast4(item?.number)}
                      </Box>
                      <Box
                        className={LayoutStyle.cardAction}
                        textAlign={"center"}
                        onClick={() =>
                          handleDeleteConfirm(
                            item?.external_id,
                            item?.type === "Bank",
                          )
                        }
                      >
                        <DeleteOutlineOutlinedIcon
                          fontSize="small"
                          color="error"
                        />
                      </Box>
                    </Box>
                  </li>
                ))}
              </Box>
              <Typography variant="h6" mb={2.5}>
                Tap to delete a linked bank or card.
              </Typography>
            </>
          )}
          <Button
            variant="outlined"
            color="black"
            fullWidth
            onClick={() => navigate("/add-funding-method")}
            sx={{
              fontSize: "0.875rem!important",
              fontWeight: 500,
              paddingBlock: "0.5rem!important",
            }}
            className="accentFill link_another_account_and_card"
            startIcon={
              <SvgIcon viewBox="0 0 11 13" sx={{ stroke: "#000" }}>
                <AddIcon />
              </SvgIcon>
            }
          >
            Link another Account / Card
          </Button>
        </Container>
      </Box>
      <BottomNav
        payRound={true}
        // badge={true}
      />

      <Drawer anchor="bottom" open={openDrawer}>
        <Typography variant="h2" className="text-center" marginTop={5}>
          Hi, {customer?.firstName || customer?.lastName || customer?.fullName}
        </Typography>
        <Typography variant="h4" className="text-center">
          {`$${p2pDetails?.amount} received successfully`}
        </Typography>
        <div className="clear40"></div>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            await getCustomer(customer?.customer_external_id);
            setOpenDrawer(false);
            navigate(`/dashboard`);
          }}
          fullWidth
        >
          Done
        </Button>
      </Drawer>
      {pusherDialogue && (
        <P2PModal
          type={"Succes"}
          headLine={`Friends and Family`}
          showOk={true}
          showCancel={false}
          message={`${pusherDialogue?.sender} has sent you $${pusherDialogue?.amount}`}
          isModalOpen={pusherDialogue}
          disableHeadline={true}
        />
      )}
      {successModal && (
        <P2PModal
          type={"Succes"}
          headLine={`Friends and Family`}
          showOk={false}
          showCancel={true}
          message={`${p2pDetails?.sender?.firstName} ${
            p2pDetails?.sender?.lastName
          } wants to send you ${currencyFormat(p2pDetails?.amount)}`}
          isModalOpen={successModal}
          confirmHandler={receiveMoney}
          confirmButtonCallback={true}
          cancelHandler={handleCancel}
          disableHeadline={true}
        />
      )}
      {requestMoneyModal && (
        <P2PModal
          type={"Succes"}
          headLine={`Friends and Family`}
          showCancel={false}
          message={`${
            requestP2PDetailsModal?.receiver_name
          } requested ${currencyFormat(requestP2PDetailsModal?.amount)}.`}
          isModalOpen={requestMoneyModal}
          confirmHandler={requestViewHandler}
          confirmButtonCallback={false}
          showOk={true}
          showOkText={"View All"}
          confirmIsOkHandler={true}
          disableHeadline={true}
          isOutSideClose={false}
        />
      )}
      {sameCustomer && (
        <P2PCancelModal
          type={"Error"}
          isModalOpen={sameCustomer}
          message={"You can not send fund to yourself"}
          cancelBtnText={"Cancel"}
          showCancel={true}
          cancelHandler={() => setSameCustomer(false)}
        />
      )}
      {customer?.not_first_time === false ? (
        <FirstTime2fa
          // firstTime2fa={!customer?.not_first_time}
          firstTime2fa={firstTime2fa}
          setFirstTime2fa={setFirstTime2fa}
          setShowModal={setShowModal}
          setOpenPinDrawer={setOpenPinDrawer}
        />
      ) : null}

      <FirstTimeOtp
        twoFactor={twoFactor}
        setTwoFactor={setTwoFactor}
        otp={otp}
        setOtp={setOtp}
        enable2fa={enable2fa}
        error={error}
        handleSendOtp={handleSendOtp}
      />

      <PinComponent
        openPinDrawer={openPinDrawer}
        pin={pin}
        setPin={setPin}
        setConfirmPin={setConfirmPin}
        setConfirm={setConfirm}
        setOpenPinDrawer={setOpenPinDrawer}
        confirm={confirm}
        confirmPin={confirmPin}
        error={error}
        setError={setError}
        handleSetPin={handleSetPin}
      />
      {showModal && (
        <DisableOtpModal
          message={"Are you sure you want to cancel Two Factor Authentication?"}
          isModalOpen={showModal}
          showOk
          showCancel
          cancelBtnText={"NO"}
          confirmHandler={() => disableOtp()}
          // confirmHandler={() => setShowModal(false)}
          cancelHandler={() => {
            // setOpenPinDrawer(true);
            setShowModal(false);
            navigate("/confirmation");
          }}
          isLoading={loading}
        />
      )}
    </>
  );
}
export default Dashboard;
