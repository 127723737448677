/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { toString } from "lodash";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { currencyFormat } from "../utils/Common";
import { Link, useNavigate } from "react-router-dom";
import { useCustomer } from "../context/customerContext";
import PriceCardStyle from "../scss/priceCard.module.scss";
import BankIcon from "../Assets/Icons/BankIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SendMoneyIcon from "../Assets/Icons/sendMoneyIcon";
import PayBillIcon from "../Assets/Icons/payBillIcon";
import QrCodeIcon from "../Assets/Icons/qrCodeIcon";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import pusherSub from "../utils/Pusher";
import { getCustomerInfoServices } from "../services/CustomerServices";

export default function PriceCard(props) {
  const navigate = useNavigate();
  const { customer, token, setCustomer } = useCustomer();

  const [isActive, setActive] = useState(false);
  const toggleAccount = () => {
    setActive(!isActive);
  };

  const dashboard = (type) => {
    if (type === "sendMoney") {
      navigate("/transfer-money");
    } else if (type === "payBill") {
      navigate("/goods-services");
    } else if (type === "scanQR") {
      navigate("/qr");
    }
  };

  useEffect(() => {
    pusherSub(
      toString(customer?.id),
      // "a",
      "p2p",
      async () => {
        try {
          const customerRes = await getCustomerInfoServices(
            customer?.customer_external_id,
            token,
          );
          if (customerRes) {
            setCustomer((prev) => ({ ...prev, ...customerRes }));
          }
        } catch (e) {
          console.log(e);
        }
      },
    );
  }, []);

  return (
    <>
      <Box component={"div"} className={`${PriceCardStyle.priceCard}`}>
        <Typography variant="h3" mb={1}>
          {customer?.name ? (
            <>
              Hello, {customer?.firstName.slice(0, 1)}
              <Box component={"span"} textTransform={"lowercase"}>
                {customer?.firstName.slice(1)}
              </Box>
              !
            </>
          ) : (
            <>
              <Skeleton animation="wave" variant="rounded" height={20} />
            </>
          )}
        </Typography>

        <Box component={"div"} className={`${PriceCardStyle.priceCardBox}`}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Typography
              variant="h3"
              fontWeight={500}
              mb={1.75}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={1}
              sx={{ color: "#fff" }}
            >
              <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20">
                <BankIcon />
              </SvgIcon>
              Account
            </Typography>
            {props.accountTop && (
              <>
                {customer?.account_number ? (
                  <>
                    <Box
                      component={"div"}
                      className={PriceCardStyle.priceCardAcccount}
                    >
                      {isActive
                        ? customer?.account_number
                        : "*****" + customer?.account_number.slice(-4)}

                      <Box component={"span"} onClick={toggleAccount}>
                        {isActive ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton animation="wave" variant="rounded" height={15} />
                  </>
                )}
              </>
            )}
          </Stack>

          <Typography
            variant="h5"
            fontWeight={400}
            gutterBottom
            sx={{ color: "#fff", opacity: 0.6 }}
          >
            Available Balance
          </Typography>
          <Typography
            variant="h1"
            component={"h2"}
            className={PriceCardStyle.priceCardAmmount}
            sx={{ color: "#fff" }}
          >
            {customer?.amount ? (
              <>{currencyFormat(customer?.amount || 0)}</>
            ) : (
              <>
                <Skeleton animation="wave" variant="rounded" height={35} />
              </>
            )}
          </Typography>

          {props.accountNo && (
            <>
              {customer?.account_number ? (
                <>
                  <Box
                    component={"div"}
                    className={PriceCardStyle.priceCardAcccount}
                    onClick={toggleAccount}
                  >
                    {isActive
                      ? customer?.account_number
                      : "***** " + customer?.account_number.slice(-4)}

                    <Divider
                      orientation="vertical"
                      variant="middle"
                      sx={{ borderColor: "#333", height: "100%", mx: 0 }}
                    />
                    <Box component={"span"}>
                      {isActive ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Skeleton animation="wave" variant="rounded" height={15} />
                </>
              )}
            </>
          )}
          {props.priceNav && (
            <Box className={PriceCardStyle.priceCardNavWrapper}>
              <Card elevation={3}>
                <CardContent sx={{ p: 1 }}>
                  <Stack direction={"row"} spacing={1}>
                    <Box
                      className={`${PriceCardStyle.priceCardNav} send_money`}
                      onClick={() => navigate("/transfer-money")}
                    >
                      <SvgIcon viewBox="0 0 25 25">
                        <SendMoneyIcon fill="#00365B" />
                      </SvgIcon>
                      Send Money
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box
                      className={`${PriceCardStyle.priceCardNav}`}
                      onClick={() => navigate("/goods-services")}
                      flex={"27.5% 1"}
                    >
                      <SvgIcon viewBox="0 0 25 25">
                        <PayBillIcon fill="#00365B" />
                      </SvgIcon>
                      Pay Bill
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Box
                      className={`${PriceCardStyle.priceCardNav}`}
                      onClick={() => dashboard("scanQR")}
                    >
                      <SvgIcon viewBox="0 0 22 22">
                        <QrCodeIcon fill="#00365B" />
                      </SvgIcon>
                      Pay using QR
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          )}

          {props.arrow && (
            <>
              <Link
                component={"div"}
                className={`${PriceCardStyle.priceCardBoxArrow}`}
                to={"/account-details"}
              >
                <ArrowForwardIosIcon />
              </Link>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
