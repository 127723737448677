import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Stack,
  Divider,
  Container,
  SvgIcon,
  FormControlLabel,
} from "@mui/material";
import { isEmpty, toNumber } from "lodash";
import { RadioGroup } from "@headlessui/react";
import {
  encryptData,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../utils/CardUtility";
import Axios from "../config/Axios";
import { useCustomer } from "../context/customerContext";
import { currencyFormat, roundUp } from "../utils/Common";
import Loader from "./Loader";
import "react-credit-cards-2/dist/lib/styles.scss";
import CustomerConfirmModal from "./Modal";
import LoadingIcon from "../svg/loader";
import LayoutStyle from "../scss/base.module.scss";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GainLogo from "../svg/Gainlogo";
import VerifyPINDrawer from "./VerifyPINDrawer";

const fakeSignature =
  "iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAABKADAAQAAAABAAAABAAAAADFbP4CAAAAJElEQVQIHWNgwAIUgGL3oViBGcgoAOIAIBYA4o9AzKAAxHAVAIfcBcFSNdjmAAAAAElFTkSuQmCC";
export default function LoadDailog({
  cust,
  info,
  amount,
  onComplete,
  // eslint-disable-next-line
  paymentCallBack,
}) {
  const { token, setCustomer, customer, sigData } = useCustomer();
  const [showCard, setShowCard] = useState(false);
  const [card, setCard] = useState([]);
  const [number, setNumber] = useState("");
  const [name, setName] = useState(cust && cust.fullName ? cust.fullName : "");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [maskingCVV, setMaskingCVV] = useState("");
  const [zip, setZip] = useState("");
  const [cardType, setCardType] = useState();
  const [debitVsCredit, setDebitVsCredit] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState({
    name: false,
    cardNumber: false,
    cvv: false,
    cardExpire: false,
    zipCode: false,
  });
  const [showPinDrawer, setShowPinDrawer] = useState(false);
  const [loadAmount, setLoadAmount] = useState(amount);
  const [apiCalcDetails, setApiCalcDetails] = useState();
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [saveCard, setSaveCard] = useState(customer?.card);

  useEffect(() => {
    if (cust?.customer_external_id) {
      setLoading(true);
      Axios(token)
        .get(`/get-cards/${cust?.customer_external_id}`)
        .then((res) => {
          setLoading(false);
          cust.card = res?.data?.response?.card;
          setSaveCard(cust?.card);
        })
        .catch(() => setLoading(false));
    }
  }, []);

  const handleInputChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
    setCard(null);
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setNumber(target.value);
      let cardNumber = number?.replace(/\D/g, "");
      customer?.card?.map((item) => {
        if (item?.cardNumber === cardNumber) {
          setNumber("");
          setShowCard(false);
          setError("Card already exists, please select from saved cards");
        }
      });
      if (cardNumber?.length > 14 && isEmpty(cardType)) {
        checkCreditCardType(cardNumber);
      } else if (cardNumber?.length < 16 && !isEmpty(cardType)) {
        setDebitVsCredit(null);
        setCardType(null);
      }
      setState({ ...state, number: cardNumber });
      setErrorType({ ...errorType, cardNumber: false });
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      setState({ ...state, expiry: target.value });
      setErrorType({ ...errorType, cardExpire: false });
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      let str = target.value;
      str = str?.replace(/.(?=.{4})/g, "*");
      setMaskingCVV(str);
      setCvc(target.value);
      setState({ ...state, cvc: target.value });
      setErrorType({ ...errorType, cvv: false });
    } else if (target.name === "name") {
      setName(target.value);
      setState({ ...state, name: target.value });
    } else if (target.name === "zip") {
      setZip(target.value);
      setState({ ...state, zip: target.value });
      setErrorType({ ...errorType, zipCode: false });
    }
  };

  const checkCreditCardType = (cardNumber, cardType = "", amt, type = "") => {
    setLoading(true);
    Axios(token)
      .post(`/account/fee/${cust?.customer_external_id}`, {
        card: cardNumber,
        card_type: cardType,
        amount: type === "updateAmt" ? amt : amount,
        store_id: info?.store?.id,
        type: "REVISED",
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.response) {
          setApiCalcDetails(res?.data?.response);
          setCardType(res?.data?.response);
          DebitVsCreditCalculation(res?.data?.response);
          setLoadAmount(res?.data?.response?.total_amount);
          setState((prevState) => ({
            ...prevState,
            feesInfo: res?.data?.response,
          }));
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(
          err?.data?.response?.message ||
            err?.message ||
            "Something went wrong.",
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    let amountArrPopped;
    let data;
    if (card) {
      data = {
        card_type: card?.card_type,
        store_id: info?.store?.id,
        amount: amount,
        type: "REVISED",
      };
    } else {
      data = {
        card_type: "DEBIT",
        store_id: info?.store?.id,
        amount: amount,
      };
    }
    Axios(token)
      .post(`/account/fee/${cust?.customer_external_id}`, data)
      .then((res) => {
        setLoading(false);
        if (res?.data?.response) {
          amountArrPopped = res?.data?.response?.allData;
          amountArrPopped.pop();
          setLoadAmount(res?.data?.response?.total_amount || amount);
          setCardType(res?.data?.response);
          if (card?.cardNumber || card?.card_type) {
            checkCreditCardType(card?.cardNumber, card?.card_type);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(
          err?.data?.response?.message ||
            err?.message ||
            "Something went wrong.",
        );
      });
  }, [card]);

  const DebitVsCreditCalculation = (val) => {
    let values = {};
    if (val) {
      values.convenience = val?.convenience_charge || 0;
      values.total = val?.order_amount || 0;
      values.orderAmount = amount || 0;
      values.cardType = val?.CardType || "";
      setDebitVsCredit(values);
    }
  };

  const handleSaveCard = async () => {
    try {
      const cardDetails = {
        cardNumber: state?.number,
        cvv: cvc,
        expiry: state?.expiry,
        name: name,
        zip: zip,
      };

      const result = await encryptData(
        cardDetails,
        customer?.CustomerKey?.publicKey,
      );

      await Axios(token).post(`/add-card/${customer?.customer_external_id}`, {
        cardToken: result,
      });
    } catch (e) {
      setError(
        e?.data?.response?.message || e?.message || "Something went wrong.",
      );
    }
  };

  function payment(data) {
    setLoading(true);
    Axios(token)
      .post(`/request/load/${cust?.customer_external_id}`, data)
      .then(async function (response) {
        if (isChecked) {
          await handleSaveCard();
        }
        let data = response?.data?.response;
        setCustomer((prev) => ({ ...prev, ...data }));
        if (onComplete) {
          onComplete(data);
        }

        setLoading(false);
      })
      .catch(function (error) {
        let errorMessage;
        setLoading(false);
        if (error?.response?.data?.message === "cvv_invalid") {
          setErrorType({ ...errorType, cvv: true });
          return setError("Invalid CVV");
        }
        if (
          error?.response?.data?.message ===
          "payment.number must be a valid credit card number"
        ) {
          setErrorType({ ...errorType, cardNumber: true });
          return setError("Invalid card number");
        }
        if (error?.response?.data?.message === "card_expired") {
          setErrorType({ ...errorType, cardExpire: true });
          return setError("Card is expired");
        }
        setError(
          errorMessage ||
            error?.response?.data?.message ||
            error?.response?.data?.response?.message ||
            error?.response?.statusText ||
            error?.response?.data?.message?.transaction_response,
          error?.response?.data?.response?.message?.transaction_response ||
            error?.response?.data?.response?.message,
        );
      });
  }
  const handleSubmit = async () => {
    if (isEmpty(state) && isEmpty(card)) {
      return setError(
        "Please select from existing card or load using new card.",
      );
    }
    let data = {};
    if (card?.card_token) {
      data = {
        card_token: card?.card_token,
        amount: loadAmount,
        store_id: info?.store?.id,
        ...state?.feesInfo,
        signature: sigData || fakeSignature,
      };
    } else {
      if (isEmpty(state?.number)) {
        setErrorType({ ...errorType, cardNumber: true });
        return setError("Please enter card number.");
      }
      if (isEmpty(state?.expiry)) {
        setErrorType({ ...errorType, cardExpire: true });
        return setError("Please enter expiry date.");
      }
      if (isEmpty(state?.cvc)) {
        setErrorType({ ...errorType, cvv: true });
        return setError("Please enter CVV.");
      }
      if (isEmpty(name)) {
        setErrorType({ ...errorType, name: true });
        return setError("Please enter card holder name.");
      }
      if (isEmpty(state?.zip)) {
        setErrorType({ ...errorType, zipCode: true });
        return setError("Please enter zip code.");
      }

      let expDate = state?.expiry.split("/");
      data = {
        ...state?.feesInfo,
        amount: amount,
        CardNumber: state?.number,
        CardExpiryMonth: expDate[0],
        CardExpiryYear: expDate[1],
        CVV2: cvc,
        CardHolderName: name,
        Zip: state?.zip,
        // activities: cust?.lastActivityId,
        saveCard: isChecked,
        signature: sigData || fakeSignature,
      };
    }
    if (customer?.enablePin && !isPinVerified) {
      setShowPinDrawer(true);
      return;
    }
    data.lastActivityId = cust?.lastActivityId;
    setLoading(true);
    payment(data);
  };

  const handleAddNewCard = (card) => {
    if (!card) {
      setShowCard(true);
      setCard(null);
      setDebitVsCredit(null);
    } else {
      setCard(card);
      setShowCard(false);
    }
  };

  const handleCloseError = () => {
    if (error === "Card already exists, please select from saved cards") {
      setDebitVsCredit(null);
      setCardType(null);
    }
    setError("");
  };

  useEffect(() => {
    if (isPinVerified) {
      setShowPinDrawer(false);
      handleSubmit();
    }
  }, [isPinVerified]);

  return (
    <>
      {loading && <Loader show={loading} />}
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={handleCloseError}
        />
      )}
      <Container>
        <Box className={LayoutStyle.amountBox}>
          <Box component={"div"} className={LayoutStyle.amountBoxLogo}>
            <GainLogo />
          </Box>
          <Box className={LayoutStyle.amountBoxText}>
            <Typography variant="h4" mb={0}>
              Account Balance
            </Typography>
            <Typography
              variant="h5"
              fontWeight={400}
              m={0}
              sx={{ opacity: 0.5 }}
            >{`Available: ${currencyFormat(
              customer?.amount || 0,
            )}`}</Typography>
          </Box>
          <Box className={LayoutStyle.amountBoxAction}>
            <Checkbox
              size="small"
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked
              readOnly
            />
          </Box>
        </Box>

        <Typography variant="h6" display={"flex"} gap={2} mt={2}>
          <InfoOutlinedIcon fontSize="small" />
          Your balance is insufficient to complete the transaction. Please add
          funds to continue.
        </Typography>
        <Divider sx={{ my: 2 }} />
        {!isEmpty(saveCard) && (
          <div className={`w-full mb-4`}>
            <Typography variant="h5" gutterBottom>
              Fund Account Using Your Saved Methods
            </Typography>
            <RadioGroup value={card} onChange={handleAddNewCard}>
              <ul className="flex gap-2 flex-wrap" role="list">
                {saveCard?.map((item, index) => (
                  <RadioGroup.Option
                    key={index}
                    value={item}
                    as="li"
                    className={``}
                  >
                    {({ checked }) => (
                      <div
                        className={` ${
                          checked
                            ? "bg-[#0068ff33] border-2 !text-green-400 border-s border-[#0350bf]"
                            : "hover:text-green-400 bg-[#E5F2FF] hover:bg-[#E5F2FF]"
                        } items-center inline-flex h-[45px] cursor-pointer relative rounded-lg `}
                      >
                        <div className={`p-2 flex items-center`}>
                          <div>
                            <img
                              className="w-10 rounded-[10px]"
                              alt={item?.card_brand}
                              src={item.image}
                            />
                          </div>
                          <div className={`text-right px-2.5`}>
                            <div className={`text-sm !text-[#011B44]`}>
                              {item?.card_number}
                            </div>
                          </div>
                          <div className={`mr-1.5 ml-auto`}>
                            {!checked ? (
                              <svg
                                className={`w-5`}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <circle cx="6" cy="6" r="6" fill="white" />
                              </svg>
                            ) : (
                              <svg
                                className={`w-5`}
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                              >
                                <circle cx="11" cy="11" r="11" fill="white" />
                                <circle cx="11" cy="11" r="9" fill="#011E46" />
                                <path
                                  d="M7 11.2222L10.2143 14L16 9"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
                <RadioGroup.Option value={null} as="li">
                  {({ checked }) => (
                    <div
                      className={` ${
                        checked
                          ? "bg-[#0068ff33] border-2 !text-green-400  border-s border-[#0350bf]"
                          : "hover:text-green-400 bg-[#E5F2FF] hover:bg-[#E5F2FF]"
                      } cursor-pointer w-full h-[45px] py-2 px-3 rounded-lg items-center gap-2.5 inline-flex `}
                    >
                      <div className={`text-center text-sm !text-[#011B44]`}>
                        + Pay Using New Card
                      </div>
                      <div className={`mr-3 ml-auto`}>
                        {!checked ? (
                          <svg
                            className={`w-5`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <circle cx="6" cy="6" r="6" fill="white" />
                          </svg>
                        ) : (
                          <svg
                            className={`w-5`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <circle cx="11" cy="11" r="11" fill="white" />
                            <circle cx="11" cy="11" r="9" fill="#011E46" />
                            <path
                              d="M7 11.2222L10.2143 14L16 9"
                              stroke="white"
                              strokeLinecap="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              </ul>
            </RadioGroup>
          </div>
        )}
        {(showCard || customer?.card?.length === 0) && (
          <>
            <Box className={"clear10"} />
            <TextField
              fullWidth
              label="Card Holder Name"
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              name={"name"}
              defaultValue={name || ""}
            />
            <TextField
              label="Card number"
              fullWidth
              margin="dense"
              defaultValue={number || ""}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              type="text"
              name={"number"}
              error={errorType?.cardNumber}
              placeholder="Enter 16 digit card number"
            />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name={"expiry"}
                  defaultValue={expiry || ""}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  placeholder="MM/YY"
                  margin="dense"
                  label="Expiry"
                  error={errorType?.cardExpire}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  defaultValue={maskingCVV || ""}
                  name={"cvc"}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  placeholder="CVV"
                  type="password"
                  margin="dense"
                  label="CVV"
                  error={errorType?.cvv}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  defaultValue={zip || ""}
                  name={"zip"}
                  onChange={handleInputChange}
                  onBlur={handleInputChange}
                  error={errorType?.zipCode}
                  placeholder="ZIP Code"
                  margin="dense"
                  label="ZIP Code"
                />
              </Grid>
            </Grid>
            <Box className="clear10" />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              }
              className="normal-case"
              label="Do you want to save this card for future transactions?"
            />
          </>
        )}
        <Box className={"clear10"} />
        {debitVsCredit && (
          <>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Typography variant="h5" fontWeight={500}>
                  From Balance
                </Typography>
                <Typography variant="h5" fontWeight={500}>
                  {currencyFormat(customer?.amount || 0)}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Typography variant="h5" fontWeight={500}>
                  From Deposit Source
                </Typography>
                <Typography variant="h5" fontWeight={500}>
                  {currencyFormat(debitVsCredit?.orderAmount)}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Typography variant="h5" fontWeight={500}>
                  {" "}
                  KYC compliance fee{" "}
                </Typography>
                <Typography variant="h5" fontWeight={500}>
                  {currencyFormat(apiCalcDetails?.convenience_charge || 0)}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Typography variant="h5" fontWeight={500}>
                  Total amount
                </Typography>
                <Typography variant="h5" fontWeight={500}>
                  {currencyFormat(
                    roundUp(debitVsCredit?.total || 0) +
                      roundUp(customer?.amount || 0),
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Box className="clear20" />
            <Typography variant="body2">
              This nominal bank fee helps protect you against fraud, Identity
              theft & ensures the integrity & security of the transaction.
            </Typography>
          </>
        )}
        <Box className="clear20" />
        {loading ? (
          <>
            <Button variant="contained" color="primary" fullWidth disabled>
              <SvgIcon viewBox="0 0 200 200" className="loader">
                <LoadingIcon />
              </SvgIcon>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              Transfer{" "}
              {currencyFormat(
                toNumber(debitVsCredit?.total || 0) +
                  toNumber(customer?.amount || 0) -
                  toNumber(debitVsCredit?.convenience || 0),
              )}
            </Button>
          </>
        )}
      </Container>
      <VerifyPINDrawer
        onSubmit={() => {
          setIsPinVerified(true);
          setShowPinDrawer(false);
        }}
        showPinDrawer={showPinDrawer}
        token={token}
        onClose={() => setShowPinDrawer(false)}
      />
    </>
  );
}
