/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useCustomer } from "../context/customerContext";
import {
  encryptData,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  mapCardAndBank,
  maskExceptLast4,
} from "../utils/CardUtility";
import { isEmpty } from "lodash";
import Axios from "../config/Axios";
import Loader from "./Loader";
import { currencyFormat, roundUp } from "../utils/Common";
import CustomerConfirmModal from "./Modal";
import LoadingIcon from "../svg/loader";
import LayoutStyle from "../scss/base.module.scss";
import AddIcon from "../Assets/Icons/addIcon";
import GainLogo from "../svg/Gainlogo";
import SuccessLogo from "../Assets/Svgs/successLogo";
import BankIcon from "../Assets/Icons/BankIcon";

function PaymentComponent({ signatureData }) {
  const [open, setOpen] = React.useState(false);
  const { customer, token, setCustomer, setInvoiceData } = useCustomer();
  const [showCard, setShowCard] = useState(false);
  const [cardType, setCardType] = useState();
  const [card, setCard] = useState(null);
  const [debitVsCredit, setDebitVsCredit] = useState(null);
  const [state, setState] = useState(null);
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [maskingCVV, setMaskingCVV] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState(
    customer && customer.fullName
      ? `${customer?.firstName} ${customer?.lastName}`
      : "",
  );
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [orderId, setOrderID] = useState("");
  const [apiCalcDetails, setApiCalcDetails] = useState();
  const [paymentSource, setPaymentSource] = useState("card");
  const [account, setAccount] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);
  const [errorType, setErrorType] = useState({
    name: false,
    cardNumber: false,
    cvv: false,
    cardExpire: false,
    zipCode: false,
  });

  const navigate = useNavigate();
  const Receipt = () => {
    navigate("/dashboard");
  };

  const DebitVsCreditCalculation = (val) => {
    let values = {};
    if (val) {
      values.convenience = val?.convenience_charge || 0;
      values.total = val?.order_amount || 0;
      values.orderAmount = signatureData?.amount || 0;
      values.cardType = val?.CardType || "";
      values.total_amount = val?.total_amount || "";
      setDebitVsCredit(values);
    }
  };

  const checkCreditCardType = (cardNumber, cardType = "") => {
    setLoading(true);
    Axios(token)
      .post(`/account/fee/${customer?.customer_external_id}`, {
        card: cardNumber,
        card_type: cardType,
        amount: signatureData?.amount,
        customer_id: customer?.id,
        type: "REVISED",
        // store_id: info?.store?.id,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.response) {
          setCardType(res?.data?.response);
          DebitVsCreditCalculation(res?.data?.response);
          setState((prevState) => ({
            ...prevState,
            feesInfo: res?.data?.response,
          }));
          setApiCalcDetails(res?.data?.response);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(
          err?.data?.response?.message ||
            err?.message ||
            "Something went wrong.",
        );
      });
  };

  const handleInputChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setNumber(target.value);
      let cardNumber = number?.replace(/\D/g, "");

      if (cardNumber?.length > 14 && isEmpty(cardType)) {
        checkCreditCardType(cardNumber);
      } else if (cardNumber?.length < 16 && !isEmpty(cardType)) {
        setDebitVsCredit(null);
        setCardType(null);
      }
      setState({ ...state, number: cardNumber });
      setErrorType({ ...errorType, cardNumber: false });
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setExpiry(target.value);
      setState({ ...state, expiry: target.value });
      setErrorType({ ...errorType, cardExpire: false });
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      let str = target.value;
      str = str?.replace(/.(?=.{4})/g, "*");
      setMaskingCVV(str);
      setCvc(target.value);
      setState({ ...state, cvc: target.value });
      setErrorType({ ...errorType, cvv: false });
    } else if (target.name === "name") {
      setName(target.value);
      setState({ ...state, name: target.value });
      setErrorType({ ...errorType, name: false });
    } else if (target.name === "zip") {
      setZip(target.value);
      setErrorType({ ...errorType, zipCode: false });
      setState({ ...state, zip: target.value });
    }
  };
  const handleAddNewCard = (e, item = null, type, source) => {
    if (type === "new") {
      setShowCard(true);
      setCard(null);
      setPaymentSource(source);
      setDebitVsCredit(null);
    } else if (type === "existing") {
      setCard(item);
      setShowCard(false);
      setPaymentSource(source);
    }
  };

  useEffect(() => {
    if (card?.card_type) {
      checkCreditCardType(card?.cardNumber, card?.card_type);
    } else if (card?.type === "Bank") {
      checkCreditCardType(null, "CREDIT");
    }
  }, [card]);

  useEffect(() => {
    if (customer?.customer_external_id) {
      setLoading(true);
      Axios(token)
        .get(`/get-cards/${customer?.customer_external_id}`)
        .then((res) => {
          setLoading(false);
          customer.card = res?.data?.response?.card;
          customer.bankInfo = res?.data?.response?.bank;
          const filter = mapCardAndBank(customer);
          // console.log(filter);
          setAccount(filter);
        })
        .catch(() => setLoading(false));
    }
  }, []);

  const handleSaveCard = async () => {
    try {
      const cardDetails = {
        cardNumber: state?.number,
        cvv: cvc,
        expiry: state?.expiry,
        name: name,
        zip: zip,
      };

      const result = await encryptData(
        cardDetails,
        customer?.CustomerKey?.publicKey,
      );

      const res = await Axios(token).post(
        `/add-card/${customer?.customer_external_id}`,
        {
          cardToken: result,
        },
      );

      // navigate("/dashboard");
    } catch (e) {
      setError(
        e?.data?.response?.message || e?.message || "Something went wrong.",
      );
    }
  };

  const handleSubmit = async () => {
    try {
      if (isEmpty(state) && isEmpty(card) && isEmpty(bankInfo)) {
        if (isEmpty(state?.number)) {
          return setErrorType({ ...errorType, cardNumber: true });
        }
        setError("Please select from existing card or load using new card.");
      } else {
        setLoading(true);
        const response = await Axios(token).post(
          `/request/signature/${customer?.customer_external_id}`,
          signatureData,
        );
        let data = {
          ...state?.feesInfo,
          amount: response?.data?.response?.amount,
          activities: response?.data?.response?.id,
        };
        if (paymentSource === "bank") {
          data.bank_id = card?.external_id;
          data.beneficiaryName = bankInfo?.beneficiaryName;
          data.accountNumber = bankInfo?.accountNumber || card?.number;
          data.bankName = bankInfo?.bankName || card?.name;
          data.routingNumber = bankInfo?.routingNumber;
          data.saveCard = isChecked;

          const res = await Axios(token).post(
            `/deposit-request/${customer?.customer_external_id}`,
            data,
          );
          setOrderID(res?.data?.response?.reference_id);
          setLoading(false);
          setOpen(true);
        } else {
          if (card?.external_id) {
            data = {
              card_token: card?.token,
              amount: signatureData?.amount,
              activities: response?.data?.response?.id,
              ...state?.feesInfo,
            };
          } else {
            if (isEmpty(state?.number)) {
              setLoading(false);
              setErrorType({ ...errorType, cardNumber: true });
              return setError("Please Enter Card Number.");
            }
            if (isEmpty(state?.expiry)) {
              setLoading(false);
              setErrorType({ ...errorType, cardExpire: true });
              return setError("Please Enter Expiry Date.");
            }
            if (isEmpty(state?.cvc)) {
              setLoading(false);
              setErrorType({ ...errorType, cvv: true });
              return setError("Please Enter CVV.");
            }
            if (isEmpty(name)) {
              setLoading(false);
              setErrorType({ ...errorType, name: true });
              return setError("Please Enter Card Holder Name.");
            }
            if (isEmpty(state?.zip)) {
              setLoading(false);
              setErrorType({ ...errorType, zipCode: true });
              return setError("Please Enter Zip Code.");
            }
            let expDate = state?.expiry.split("/");
            data = {
              ...state?.feesInfo,
              amount: response?.data?.response?.amount,
              CardNumber: state?.number,
              CardExpiryMonth: expDate[0],
              CardExpiryYear: expDate[1],
              CVV2: cvc,
              CardHolderName: name,
              Zip: state?.zip,
              activities: response?.data?.response?.id,
              saveCard: isChecked,
            };
          }
          Axios(token)
            .post(`/request/load/${customer?.customer_external_id}`, data)
            .then(async function (response) {
              if (isChecked) {
                await handleSaveCard();
              }
              setLoading(false);
              setOrderID(response?.data?.response?.order_id);
              let data2 = response?.data?.response;
              setInvoiceData(response?.data?.response);
              delete data2.fund_id;
              setCustomer((prev) => ({ ...prev, ...data2 }));
              setOpen(true);
            })
            .catch(function (error) {
              let errorMessage;
              setLoading(false);
              if (error.response.status === 400) {
                if (
                  error.response?.data &&
                  error.response?.data.message &&
                  error.response?.data.message.status === "FAILED"
                ) {
                  errorMessage =
                    error.response.data.message.transaction_response;
                } else if (
                  error.response.data &&
                  error.response.data.message &&
                  typeof error.response.data.message === "object"
                ) {
                  const errorMessages = error.response.data.message;
                  for (const prop in errorMessages) {
                    if (!isEmpty(errorMessages[prop][0])) {
                      errorMessage = errorMessages[prop][0];
                    }
                  }
                }
                if (error.response.data.message === "cvv_invalid") {
                  setErrorType({ ...errorType, cvv: true });
                  return setError("Invalid CVV");
                }
                if (
                  error.response.data.message ===
                  "payment.number must be a valid credit card number"
                ) {
                  setErrorType({ ...errorType, cardNumber: true });
                  return setError("Invalid card number");
                }
                if (error.response.data.message === "card_expired") {
                  setErrorType({ ...errorType, cardExpire: true });
                  return setError("Card is expired");
                }
                setError(
                  errorMessage
                    ? errorMessage
                    : error.response.data.message.transaction_response
                    ? error.response.data.message.transaction_response
                    : error.response.data.message || error.response.statusText,
                );
              } else {
                setError(
                  error.response.data
                    ? error.response.data.message
                    : error.message,
                );
              }
            });
        }
      }
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data?.response?.message || "Something went wrong");
    }
  };
  const handleBankData = (e) => {
    setBankInfo({ ...bankInfo, [e.target.id]: e.target.value });
    if (e.target.id === "routingNumber" && e.target.value.length > 8) {
      checkCreditCardType(null, "CREDIT");
    } else {
      setDebitVsCredit(null);
      setCardType(null);
    }
  };

  return (
    <>
      <Loader show={loading} />
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => setError("")}
        />
      )}
      <>
        <Container>
          {account?.length > 0 && (
            <>
              <Typography variant="h4" gutterBottom>
                Deposit Using Your Saved Methods
              </Typography>
              <ul
                className={`${LayoutStyle.cardList} ${LayoutStyle.cardScroll}`}
              >
                {account?.map((item, index) => (
                  <li
                    key={index}
                    onClick={(e) => {
                      handleAddNewCard(
                        e,
                        item,
                        "existing",
                        item?.type?.toLowerCase(),
                      );
                      checkCreditCardType(item?.cardNumber, "CREDIT");
                    }}
                  >
                    <Box
                      className={`${LayoutStyle.cardBox} ${
                        (item?.external_id && item?.external_id) ===
                        (card && card?.external_id)
                          ? "bg-[#0068ff33] border-2 border-s border-[#0350bf]"
                          : " bg-[#E5F2FF] "
                      }`}
                      onClick={(e) => {
                        handleAddNewCard(
                          e,
                          item,
                          "existing",
                          item?.type?.toLowerCase(),
                        );
                        checkCreditCardType(item?.cardNumber, "CREDIT");
                      }}
                    >
                      {item?.type === "Bank" ? (
                        <Box
                          className={`${LayoutStyle.cardLogo} ${LayoutStyle.cardLogoAccount} !bg-transparent`}
                        >
                          <SvgIcon
                            sx={{ width: 20, height: 20 }}
                            viewBox="0 0 20 20"
                          >
                            <BankIcon />
                          </SvgIcon>
                        </Box>
                      ) : (
                        <Box
                          className={`${LayoutStyle.cardLogo} !bg-transparent`}
                        >
                          <img src={item?.image} alt="card name" />
                        </Box>
                      )}

                      <Box
                        bgcolor={"transparent"}
                        className={`${LayoutStyle.cardNo} !text-[#011B44]`}
                      >
                        {maskExceptLast4(item?.number)}
                      </Box>
                      <Box ml={"auto"}>
                        <div
                          className={`mr-1.5 ml-auto`}
                          onClick={(e) => {
                            handleAddNewCard(
                              e,
                              item,
                              "existing",
                              item?.type?.toLowerCase(),
                            );
                            checkCreditCardType(item?.cardNumber, "CREDIT");
                          }}
                        >
                          {(item?.external_id && item?.external_id) !==
                          (card && card?.external_id) ? (
                            <svg
                              className={`w-5`}
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <circle cx="6" cy="6" r="6" fill="white" />
                            </svg>
                          ) : (
                            <svg
                              className={`w-5`}
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <circle cx="11" cy="11" r="11" fill="white" />
                              <circle cx="11" cy="11" r="9" fill="#011E46" />
                              <path
                                d="M7 11.2222L10.2143 14L16 9"
                                stroke="white"
                                strokeLinecap="round"
                              />
                            </svg>
                          )}
                        </div>
                      </Box>
                    </Box>
                  </li>
                ))}
              </ul>
            </>
          )}
          <Typography variant="h4">
            Deposit using new card/bank account
          </Typography>
          <div className={`flex gap-2 justify-start items-center mb-3`}>
            <Button
              variant="outlined"
              color="black"
              fullWidth
              size="small"
              className={`${
                (showCard || account?.length === 0) && paymentSource === "card"
                  ? ""
                  : "accentFill"
              }`}
              startIcon={
                <SvgIcon viewBox="0 0 11 16" sx={{ stroke: "#084975" }}>
                  <AddIcon />
                </SvgIcon>
              }
              onClick={(e) => handleAddNewCard(e, null, "new", "card")}
            >
              Card
            </Button>
            <Button
              variant="outlined"
              color="black"
              fullWidth
              size="small"
              className={`${
                (showCard || account?.length === 0) && paymentSource === "bank"
                  ? ""
                  : "accentFill"
              }`}
              startIcon={
                <SvgIcon viewBox="0 0 11 16" sx={{ stroke: "#084975" }}>
                  <AddIcon />
                </SvgIcon>
              }
              onClick={(e) => handleAddNewCard(e, null, "new", "bank")}
            >
              Bank Account
            </Button>
          </div>
          {(showCard || account?.length === 0) && paymentSource === "card" && (
            <>
              <TextField
                fullWidth
                label="Card Holder Name"
                margin="normal"
                onChange={handleInputChange}
                onBlur={handleInputChange}
                name={"name"}
                defaultValue={name || ""}
              />
              <TextField
                label="Card number"
                fullWidth
                margin="normal"
                defaultValue={number || ""}
                onChange={handleInputChange}
                onBlur={handleInputChange}
                type="text"
                name={"number"}
                error={errorType?.cardNumber}
                placeholder="Enter 16 digit card number"
              />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    name={"expiry"}
                    defaultValue={expiry || ""}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    placeholder="MM/YY"
                    margin="normal"
                    label="Expiry"
                    error={errorType?.cardExpire}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    defaultValue={maskingCVV || ""}
                    name={"cvc"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    placeholder="CVV"
                    type="password"
                    margin="normal"
                    label="CVV"
                    error={errorType?.cvv}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    defaultValue={zip || ""}
                    name={"zip"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    error={errorType?.zipCode}
                    placeholder="ZIP Code"
                    margin="normal"
                    label="ZIP Code"
                  />
                </Grid>
              </Grid>
              <Box className="clear20" />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    sx={{ fontSize: "10px" }}
                  />
                }
                className="normal-case"
                label="Do you want to save this card for future transactions?"
                sx={{ fontSize: "8px", lineHeight: 1 }}
              />
            </>
          )}
          {(showCard || account?.length === 0) && paymentSource === "bank" && (
            <Container sx={{ padding: "0!important" }}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={`!mb-3`}
              >
                This may take up to 3 days to deposit in to your GainFunds
                account.
              </Typography>
              <Box>
                <FormControl
                  onChange={handleBankData}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id={`beneficiaryName`}
                >
                  <InputLabel htmlFor="beneficiaryName">
                    Account Holder Name
                  </InputLabel>
                  <OutlinedInput
                    id="beneficiaryName"
                    label="Account Holder Name"
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id={`bankName`}
                  onChange={handleBankData}
                  defaultValue={bankInfo?.bankName || ""}
                >
                  <InputLabel htmlFor="bankName">Bank Name</InputLabel>
                  <OutlinedInput id="bankName" label="Bank Name" />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id={`routingNumber`}
                  onChange={handleBankData}
                  defaultValue={bankInfo?.routingNumber || ""}
                >
                  <InputLabel htmlFor="routingNumber">Routing no</InputLabel>
                  <OutlinedInput id="routingNumber" label="Routing no" />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id={`accountNumber`}
                  onChange={handleBankData}
                  defaultValue={bankInfo?.accountNumber || ""}
                >
                  <InputLabel htmlFor="accountNumber">Account no</InputLabel>
                  <OutlinedInput id="accountNumber" label="Account no" />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        sx={{ fontSize: "10px margin-top:10px" }}
                      />
                    }
                    className="normal-case"
                    label="Do you want to save this bank details for future transactions"
                    sx={{ fontSize: "8px", lineHeight: 1 }}
                  />
                </FormControl>
              </Box>
              {error && (
                <Typography variant="body1" color="error" className={`!mb-2`}>
                  {error}
                </Typography>
              )}
            </Container>
          )}

          {debitVsCredit && (
            <>
              <Box className="clear10" />
              <Stack spacing={1}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    Total amount
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    {currencyFormat(
                      roundUp(apiCalcDetails?.total_amount || 0) -
                        roundUp(apiCalcDetails?.convenience_charge || 0),
                    )}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    KYC compliance fee{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    {currencyFormat(apiCalcDetails?.convenience_charge || 0)}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    Total charged amount{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={400}
                    sx={{ opacity: 0.6 }}
                  >
                    {currencyFormat(apiCalcDetails?.total_amount || 0)}
                  </Typography>
                </Stack>
              </Stack>
              <Box className="clear10" />
              <Typography variant="body2">
                This nominal bank fee helps protect you against fraud, Identity
                theft & ensures the integrity & security of the transactio
              </Typography>
            </>
          )}
          <Box className="clear20" />
          {loading ? (
            <>
              <Button variant="contained" color="primary" fullWidth disabled>
                <SvgIcon viewBox="0 0 200 200" className="loader">
                  <LoadingIcon />
                </SvgIcon>
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Deposit{" "}
                {currencyFormat(
                  roundUp(apiCalcDetails?.total_amount || 0) -
                    roundUp(apiCalcDetails?.convenience_charge || 0),
                )}
              </Button>
              <Box className="clear20" />
            </>
          )}
        </Container>
      </>

      <Dialog fullScreen anchor="bottom" open={open}>
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.loginContainer}`}
          justifyContent={"center"}
        >
          <Container>
            <Box
              component={"div"}
              className={`${LayoutStyle.BrandLogo}`}
              sx={{ mb: { xs: 3, sm: 4 } }}
            >
              <GainLogo />
            </Box>
            <Typography variant="h3" color={"secondary"} textAlign={"center"}>
              {paymentSource === "bank"
                ? `Deposit Request Initiated`
                : `Funds Added Successfully`}
              {paymentSource === "bank" && (
                <Typography
                  variant="body1"
                  color={"textSecondary"}
                  className={`!mt-3`}
                >
                  This may take up to 3 days to deposit in to your GainFunds
                  account.
                </Typography>
              )}
            </Typography>
            <Box component={"div"} className={`${LayoutStyle.successLogo}`}>
              <SuccessLogo />
            </Box>
            <Box className="clear30" />
            <Card elevation={5} sx={{ mx: { xs: 0, sm: 1 } }}>
              <CardContent>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      Reference number:{" "}
                    </Typography>
                    <Typography variant="h5" fontWeight={500}>
                      {paymentSource === "bank" ? "" : orderId}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      {paymentSource === "bank"
                        ? "Amount Initiated"
                        : "Amount added"}
                      :{" "}
                    </Typography>
                    <Typography variant="h5" fontWeight={500}>
                      {currencyFormat(signatureData?.amount)}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Box className="clear30" />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={Receipt}
            >
              Done
            </Button>
          </Container>
        </Box>
      </Dialog>
    </>
  );
}

export default PaymentComponent;
