import { createTheme, darken, lighten } from "@mui/material";
import "./index.scss";

const primary = "#011B44";
const secondary = "#00347F";
const black = "#000";
const white = "#fff";
const accent = "#00395f";
const info = "#D3F1FF";
const error = "#f44336";
const success = "#008626";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 768,
      lg: 1000,
      xl: 1400,
    },
  },
  palette: {
    primary: {
      light: "lighten(" + primary + ",10%)",
      main: primary,
      dark: "darken(" + primary + ",10%)",
      contrastText: white,
    },
    secondary: {
      light: "lighten(" + secondary + ",10%)",
      main: secondary,
      dark: "darken(" + secondary + ",10%)",
      contrastText: black,
    },
    accent: {
      light: "lighten(" + accent + ",10%)",
      main: accent,
      dark: "#03569b",
      contrastText: white,
    },
    info: {
      light:  info,
      main: info,
      dark: info,
      contrastText: accent,
    },
    white: {
      light: white,
      main: white,
      dark: darken(black,'1%'),
      contrastText: lighten(black,'40%'),
    },
    black: {
      light: black,
      main: black,
      dark: black,
      contrastText: white,
    },
    error: {
      light: "lighten(" + error + ",10%)",
      main: error,
      dark: "darken(" + error + ",10%)",
      contrastText: white,
    },
    success: {
      light: "lighten(" + success + ",10%)",
      main: success,
      dark: "darken(" + success + ",10%)",
      contrastText: white,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 16,
    fontStyle: "400",
    fontDisplay: "block",
    color: black,
    lineHeight: 1.5,
    h1: {
      fontSize: "3.75rem",
      fontWeight: 600,
      marginBlockEnd: "0.875rem",
      lineHeight:1.25,
      '@media (max-width:750px)': {
        fontSize: '2.5rem',
      },
      '@media (max-width:600px)': {
        fontSize: '2rem',
        lineHeight:1.15,
      },
    },
    h2: {
      fontSize: "1.25rem",
      fontWeight: 500,
      marginBlockEnd: "0.875rem",
      lineHeight:1.5,
      // '@media (max-width:600px)': {
      //   lineHeight:1.15,
      // },
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: 500,
      marginBlockEnd: "1.25rem",
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 400,
      marginBlockEnd: "0.75rem",
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 400,
      marginBlockEnd: "1.25rem",
    },
    h6: {
      fontSize: "0.75rem ",
      fontWeight: 300,
      marginBlockEnd: "0.5rem",
      lineHeight:1.25,
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
      color: "rgba(" + black + ",0.75)",
    },
    body2: {
      fontSize: "0.625rem",
      lineHeight: 1.5,
      color:lighten(black, .6),
    },
    subtitle1: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
      fontWeight: 500,
      '@media (max-width:600px)': {
        lineHeight:1.25,
        fontWeight:400,
      },
    },
    subtitle2: {
      color:'#000',
    },
    button: {
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      lineHeight: 2.5,
    },
  },
  container:{
    px:4,
  },
  tooltip: {
    backgroundColor: "#333",
    color: white,
    fontSize: 11,
  },
});

export default theme;
