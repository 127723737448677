import React, { useEffect, useRef, useState } from "react";
import { toNumber } from "lodash";
import {
  Button,
  Typography,
  FormHelperText,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  IconButton,
  SvgIcon,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import HeaderFull from "../components/headerFull";
import CustomerConfirmModal from "../components/Modal";
import { roundUp } from "../utils/Common";
import LayoutStyle from "../scss/base.module.scss";
import BottomNav from "../components/bottomNav";
import GainLogo from "../svg/Gainlogo";
import RemoveIcon from "../Assets/Icons/removeIcon";
import PaymentDrawer from "../components/paymentDrawer";
import { checkLogin } from "../components/helpers/checkLogin";
import { useCustomer } from "../context/customerContext";
import { useNavigate } from "react-router-dom";

function LoadFund() {
  let padRef = useRef();
  const { customer } = useCustomer();
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = useState("");
  const [error, setError] = useState(false);
  const [state, setState] = useState({});
  const [payment, setPayment] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin(customer, navigate);
  }, []);
  const handleClear = (e) => {
    e.preventDefault();
    padRef?.current?.clear();
  };

  const handleSubmit = async () => {
    if (toNumber(amount) <= 0) {
      setAmountError("Invalid amount");
      return;
    }
    if (!amount) {
      setAmountError("Please enter amount");
      return;
    }
    if (padRef?.current?.isEmpty()) {
      return setError("Please Sign to Continue.");
    }
    const data1 = {
      file: padRef?.current?.toDataURL(),
      amount: amount,
    };
    setState(data1);
    setPayment(true);
  };
  const makeRoundUpNumber = (amount, roundUpValue) => {
    return Math.ceil(roundUp(amount) / roundUpValue) * roundUpValue;
  };

  return (
    <>
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => setError("")}
        />
      )}
      <>
        <HeaderFull />
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
        >
          <Container>
            <Typography variant="h4" gutterBottom>
              Deposit
            </Typography>
            <FormControl fullWidth variant="outlined" focused margin="normal">
              <InputLabel htmlFor="outlined-adornment-amount">
                Enter Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                endAdornment={<InputAdornment position="end">$</InputAdornment>}
                label="Enter Amount"
                onChange={(e) => {
                  const input = e.target.value;
                  const onlyNumbersAndDots = input
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");
                  setAmount(onlyNumbersAndDots);
                  setAmountError(null);
                }}
                onBlur={(e) => {
                  const input = e.target.value;
                  const onlyNumbersAndDots = input
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");
                  setAmount(makeRoundUpNumber(onlyNumbersAndDots, 1));
                }}
                type="number"
                value={amount}
              />
              {amountError && (
                <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{ color: "red" }}
                >
                  {amountError}
                </FormHelperText>
              )}
            </FormControl>
            {/* <Box className="clear10" /> */}
            <Typography
              variant="h4"
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={1}
            >
              Accept authorization
              <Box component={"div"} width={"5rem"} height={"1.25rem"} mr={0}>
                <GainLogo />
              </Box>
            </Typography>
            <Typography variant="body2" textAlign={"left"} lineHeight={1.25}>
              {`You understand that your funds in your Gain Funds Account are being received by Gain Federal Credit Union. You represent that all of the information you have provided, including information provided as part of the "Know-Your-Customer" or KYC process, is correct and up to date. Accordingly, your account statement will show your credit/debit card account funding is being made to your Gain Account held in the name of Gain Federal Credit Union as the Merchant of Record.`}
            </Typography>
            <Box className="clear10" />
            <Box
              component={"fieldset"}
              className={LayoutStyle.signatureWrapper}
            >
              <legend>Your Signature</legend>
              <SignatureCanvas
                canvasProps={{
                  className: LayoutStyle.signatureBox,
                }}
                minWidth={2}
                ref={padRef}
                penColor="darkblue"
                clearOnResize={false}
              />

              <Box className={LayoutStyle.signatureClear}>
                <IconButton color="error" onClick={handleClear}>
                  <SvgIcon viewBox="0 0 22 15">
                    <RemoveIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>
            <Box className="clear20" />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Container>
        </Box>
        <BottomNav />
        <PaymentDrawer
          data={state}
          onOpen={payment}
          closeDrawer={() => setPayment(false)}
        />
      </>
    </>
  );
}
export default LoadFund;
