import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

function SendMoneyReceivedModal({
  isMoneyReceivedModal,
  setMoneyReceivedModal,
  p2pDetails,
  isLoading,
  requestIdCopied,
  setRequestIdCopied,
}) {
  const copyRequestId = () => {
    navigator.clipboard.writeText(p2pDetails?.CustomerTransaction?.external_id);
    setRequestIdCopied(true);
    setTimeout(() => setRequestIdCopied(false), 1000);
  };

  return (
    <>
      <Dialog open={isMoneyReceivedModal}>
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="text-xs text-gray-600">Paid to</p>
            <button
              onClick={() => {
                // setRequestIdCopied("");
                setMoneyReceivedModal(false);
              }}
              className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition-colors flex items-center justify-center"
              aria-label="Close"
            >
              <CloseIcon
                sx={{
                  fontSize: "1rem",
                  color: "rgb(75, 85, 99)", // Tailwind's gray-600
                }}
              />
            </button>
          </div>
          {isLoading ? (
            <div className="flex flex-col items-center justify-center h-48">
              <div className="animate-pulse bg-gray-200 rounded-full h-12 w-12 mb-4" />
              <div className="animate-pulse bg-gray-200 h-4 w-40 mb-2" />
              <div className="animate-pulse bg-gray-200 h-4 w-24 mb-2" />
              <div className="animate-pulse bg-gray-200 h-4 w-32 mb-2" />
              <div className="animate-pulse bg-gray-200 h-4 w-28 mb-2" />
            </div>
          ) : (
            <>
              <div
                className={
                  "flex justify-between mt-3 bg-blue-100 rounded-md p-4"
                }
              >
                <div className={"flex space-x-2"}>
                  <AccountBoxIcon
                    sx={{
                      fontSize: "2.5rem",
                    }}
                  />
                  <div className={"flex-col"}>
                    <p className={"text-sm font-semibold"}>
                      {p2pDetails?.receiver?.fullName}
                    </p>
                    <p className={"text-xs"}>
                      {p2pDetails?.receiver?.account_number}
                    </p>
                  </div>
                </div>
                <div>
                  <p>${p2pDetails?.amount}</p>
                </div>
              </div>
              {/*<hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />*/}
              <div className={"flex mt-3 justify-between items-center"}>
                <div className={"flex space-x-3"}>
                  <ListAltIcon />
                  <p className={"text-sm"}>Payment details</p>
                </div>
                <div>
                  <KeyboardArrowDownIcon />
                </div>
              </div>
              <div className={"flex mt-3 justify-between items-center"}>
                <div className={"flex-col"}>
                  <p className={"text-xs"}>Transaction ID</p>
                  <p className={"text-xs"}>
                    {p2pDetails?.CustomerTransaction?.external_id}
                    {requestIdCopied && (
                      <p className="text-green-500">Copied!</p>
                    )}
                  </p>
                </div>
                <div onClick={copyRequestId}>
                  <ContentCopyIcon sx={{ fontSize: "1rem" }} />
                </div>
              </div>
              <p className={"text-xs mt-3"}>Debited from</p>
              <div className={"flex justify-between pb-2"}>
                <div className={"flex space-x-3"}>
                  <AccountBalanceWalletIcon sx={{ fontSize: "1.5rem" }} />
                  <p className={"text-sm"}>
                    {p2pDetails?.sender?.account_number}
                  </p>
                </div>
                <p className={"text-sm"}>${p2pDetails?.amount}</p>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SendMoneyReceivedModal;
