/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { Global } from "@emotion/react";
import { Stack } from "@mui/material";
import { useCustomer } from "../context/customerContext";
import { styled } from "@mui/material/styles";
import QRCode from "qrcode";
import { handleShare, QRURL } from "../utils/Common";
import { useNavigate } from "react-router-dom";
import NoRecordFound from "../components/NoRecordFound";
import BottomNav from "../components/bottomNav";
import HeaderFull from "../components/headerFull";
import CustomerConfirmModal from "../components/Modal";
import Loader from "../components/Loader";
import QrButtonPair from "../components/qrButtonPair";
import QrShareDialogue from "../components/qrShareDialogue";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const QRCodeExample = (props) => {
  const { customer } = useCustomer();
  const navigate = useNavigate();
  const [hasFlash, setHasFlash] = useState(false);
  const [flashOn, setFlashOn] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMyQr, setOpenMyQr] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const scannerRef = useRef(null);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(null);
  const [loading, setLoading] = useState(true);
  const highlightScanRegion = () => {
    const overlayElement = overlayRef.current;
    if (overlayElement) {
      overlayElement.classList.add("highlight-scan");
      setTimeout(() => {
        overlayElement.classList.remove("highlight-scan");
      }, 1000); // Remove the highlight after 1 second
    }
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setCameraError("Camera permission denied. Please allow camera access.");
      throw new Error("Camera permission denied.");
    }
  };

  useEffect(() => {
    const initializeScanner = async () => {
      try {
        await requestCameraPermission();
        const calculateScanRegion = (video) => {
          const width = video.videoWidth;
          const height = video.videoHeight;
          const smallestDimension = Math.min(width, height);
          const regionSize = Math.round(smallestDimension * 0.5); // 50% of the smallest dimension
          return {
            x: Math.round((width - regionSize) / 2),
            y: Math.round((height - regionSize) / 2) - 100, // Shift up by 100 pixels
            width: regionSize,
            height: regionSize,
            downScaledWidth: 400, // Downscale the region for performance
            downScaledHeight: 400,
          };
        };
        const scanner = new QrScanner(
          videoRef.current,
          (result) => {
            highlightScanRegion();

            const segments = result?.data?.split("/");
            const uuid = segments[segments?.length - 1];
            const type = segments[segments?.length - 2];

            if (type === "qrpay") {
              navigate(`/goods-services-qr/${uuid}`);
              return;
            }
            if (type === "p2p-qr") {
              navigate(`/transfer-money/${uuid}`);
              return;
            }
            if (type === "scan") {
              navigate(`/goods-services/${uuid}`);
              return;
            }
            setError("QR code not supported.");
          },
          {
            onDecodeError: (error) => {
              // console.error(error.message || error);
            },
            highlightScanRegion: true,
            highlightCodeOutline: true,
            calculateScanRegion: calculateScanRegion,
            inversionMode: "both",
            overlay: overlayRef.current, // Use custom overlay,
            maxScansPerSecond: 2,
          },
        );
        scannerRef.current = scanner;

        QrScanner.hasCamera()
          .then((hasCamera) => {
            if (!hasCamera) {
              console.error("No camera found");
            } else {
              scanner.start().then(() => {
                scanner.setCamera("environment").then(() => {
                  scanner.hasFlash().then((hasFlash) => {
                    console.log("Flash available:", hasFlash);
                  });
                });
              });
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    initializeScanner();

    return () => {
      if (scannerRef.current) {
        scannerRef.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    QRCode.toDataURL(`${QRURL()}/p2p-qr/${customer?.customer_external_id}`, {
      version: 10,
    })
      .then((qr) => {
        setQrCodeData(qr);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [customer]);

  const toggleFlash = () => {
    if (!hasFlash) {
      console.error("No flash available on this device");
      return;
    }
    scannerRef.current.toggleFlash().then(() => {
      setFlashOn(scannerRef.current.isFlashOn());
    });
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClickOpen = () => {
    setOpenMyQr(true);
  };

  const handleClose = () => {
    setOpenMyQr(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        QrScanner.scanImage(base64String)
          .then((result) => {
            const segments = result.split("/");
            const uuid = segments[segments.length - 1];
            const type = segments[segments.length - 2];
            if (type === "qrpay") {
              navigate(`/goods-services-qr/${uuid}`);
            }
            if (type === "p2p-qr") {
              navigate(`/transfer-money/${uuid}`);
            }
            if (type === "scan") {
              navigate(`/goods-services/${uuid}`);
            }
          })
          .catch((error) => {
            setError("QR code not supported.");
            QrScanner.hasCamera().then((hasCamera) => {
              if (!hasCamera) {
                console.error("No camera found");
              } else {
                scannerRef.current.start();
              }
            });
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBrowserShare = async () => {
    try {
      const share = await handleShare(customer, qrCodeData);
      if (share === true) {
        setOpenMyQr(false);
      } else {
        setError(
          "Share is not supported in this browser. Please download the QR code and share it.",
        );
      }
    } catch (e) {
      setError(e.message);
    }
  };
  const handleDownload = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();
    img.src = qrCodeData;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = "p2p-qr-code.png";
      link.href = dataURL;
      link.click();
    };
  };
  return (
    <>
      {loading && <Loader />}
      <HeaderFull />
      {error && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={error}
          isModalOpen={!!error}
          CloseOnClick={() => setError(null)}
        />
      )}
      {cameraError ? (
        <NoRecordFound
          title={cameraError}
          handleFileChange={handleFileChange}
          handleClickOpen={handleClickOpen}
          VisuallyHiddenInput={VisuallyHiddenInput}
          openMyQr={openMyQr}
          handleClose={handleClose}
          handleDownload={handleDownload}
          handleBrowserShare={handleBrowserShare}
          qrCodeData={qrCodeData}
        />
      ) : (
        <div className={`relative`}>
          <style>{`
        #video-container.example-style-2 .scan-region-highlight-svg,
        #video-container.example-style-2 .code-outline-highlight {
          stroke: #64a2f3 !important;
        }
        #flash-toggle {
          display: none;
        }
        .scan-region-highlight {
          background:
          linear-gradient(to right, white 5px, transparent 1px) 0 0,
          linear-gradient(to right, white 5px, transparent 1px) 0 100%,
          linear-gradient(to left, white 5px, transparent 1px) 100% 0,
          linear-gradient(to left, white 5px, transparent 1px) 100% 100%,
          linear-gradient(to bottom, white 5px, transparent 1px) 0 0,
          linear-gradient(to bottom, white 5px, transparent 1px) 100% 0,
          linear-gradient(to top, white 5px, transparent 1px) 0 100%,
          linear-gradient(to top, white 5px, transparent 1px) 100% 100%;
          background-repeat: no-repeat;
          background-size: 50px 50px;
          box-sizing: border-box;
          pointer-events: none;
          outline: rgba(0, 0, 0, .25) solid 50vmax;
          box-shadow: 0 0 20px 20px #00000012;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .scan-region-highlight::after {
          content: "";
        }
        .highlight-scan {
          border: 4px solid white !important; /* Customize the highlight style */
          box-shadow: 0 0 10px white; /* Add a glow effect */
          transition: border 0.5s, box-shadow 0.5s; /* Smooth transition */
        }

      `}</style>
          <div>
            <Global
              styles={{
                ".MuiDrawer-root > .MuiPaper-root": {
                  overflow: "visible",
                },
              }}
            />
            <div
              id="video-container"
              className="example-style-2 leading-none relative"
            >
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  aspectRatio: 9 / 16,
                  objectFit: "cover",
                }}
                id="qr-video"
                ref={videoRef}
              ></video>
              <div ref={overlayRef} className="scan-region-highlight"></div>
              <div className={`w-full px-8 absolute bottom-0`}>
                <Stack px={3} spacing={2} alignItems={"center"} mb={15}>
                  <QrButtonPair
                    handleFileChange={handleFileChange}
                    handleClickOpen={handleClickOpen}
                    VisuallyHiddenInput={VisuallyHiddenInput}
                  />
                </Stack>
              </div>
            </div>
          </div>
          <QrShareDialogue
            openMyQr={openMyQr}
            handleClose={handleClose}
            handleBrowserShare={handleBrowserShare}
            handleDownload={handleDownload}
            qrCodeData={qrCodeData}
          />
        </div>
      )}
      <BottomNav />
    </>
  );
};

export default QRCodeExample;
