import React from "react";

export default function SuccessIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_8849_5015)"/>
      <path d="M16 23.8085L23.1233 31L36 18" stroke="white" strokeWidth="4" strokeLinecap="round"/>
      <defs>
        <linearGradient id="paint0_linear_8849_5015" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0068FF"/>
          <stop offset="1" stopColor="#0045A9"/>
        </linearGradient>
      </defs>
    </svg>
  );
}
