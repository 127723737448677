/* eslint-disable */
import React, { useEffect, useState } from "react";
import { toNumber } from "lodash";
import moment from "moment-timezone";
import {
  Button,
  Dialog,
  Drawer,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
  Container,
  SvgIcon,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HeaderFull from "../components/headerFull";
import LoadDailog from "../components/loadDailog";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { useCustomer } from "../context/customerContext";
import Loader from "../components/Loader";
import { currencyFormat, roundUp } from "../utils/Common";
import Axios from "../config/Axios";
import CustomerConfirmModal from "../components/Modal";
import { browserName } from "react-device-detect";
import LayoutStyle from "../scss/base.module.scss";
import BottomNav from "../components/bottomNav";
import GainLogo from "../svg/Gainlogo";
import SuccessLogo from "../Assets/Svgs/successLogo";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LoadingIcon from "../Assets/Icons/loader";
import VerifyPINDrawer from "../components/VerifyPINDrawer";

function FriendsAndFamily() {
  const route = useNavigate();
  const location = useLocation();
  const type = location.state;
  const [requiredOpenPopup, setRequiredOpenPopup] = useState(false);
  const { customerId } = useParams();
  const { token, customer } = useCustomer();
  const [requestInfo, setRequestInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [errors, setErrors] = useState(null);
  const [completedTransaction, setCompletedTransaction] = useState(false);
  const [completedTransactionData, setCompletedTransactionData] =
    useState(null);
  const [state, setState] = useState(null);
  const [fundLoaded, setFundLoaded] = useState(false);
  const [receiverDetails, setReceiverDetails] = useState();
  const [isReceiverNameDisabled, setIsReceiverNameDisabled] = useState(true);
  const [custConfirmed, setCustConfirmed] = useState(false);
  const [rcvrExtId, setRcvrExtId] = useState("");
  const [instantTransferComplete, setInstantTransferComplete] = useState(false);
  const [transferData, setTransferData] = useState({});
  const [showPinDrawer, setShowPinDrawer] = useState(false);
  const [transferFor, setTransferFor] = useState("");
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [dailog, setDailog] = useState(false);
  const [isSameCustomer, setIsSameCustomer] = useState(false);

  useEffect(() => {
    if (customerId) {
      if (customer?.customer_external_id === customerId) {
        setIsSameCustomer(true);
        setErrors("You can not send money to yourself");
        return;
      }
      Axios(token)
        .get(`/get-receiver-details/${customerId}`)
        .then((data) => {
          setState({
            paying_to: data?.data?.response?.fullName,
            // phone_number: data?.data?.response?.phone,
          });
          setRcvrExtId(data?.data?.response?.customer_external_id);
        })
        .catch((e) => {
          setErrors(e?.response?.data?.message);
        });
    }
  }, [customerId]);

  const toggleDrawer = (newOpen) => () => {
    setRequiredOpenPopup(newOpen);
  };

  const handleClickOpen = () => {
    setDailog(true);
  };

  const handleClose = () => {
    setDailog(false);
  };

  const sendInstantMoney = () => {
    setLoading(true);
    Axios(token)
      .post(`/send-instant-money`, {
        external_id: rcvrExtId,
        amount: state?.amount,
        // phone: state?.phone_number,
      })
      .then((res) => {
        setLoading(false);
        setInstantTransferComplete(true);
        setTransferData(res?.data?.response?.transferDetails);
      })
      .catch((e) => {
        setLoading(false);
        setErrors(e?.response?.data?.message);
      });
  };
  const sendMoneyMessage = () => {
    setLoading(true);
    Axios(token)
      .post(`/send-money`, {
        amount: toNumber(state?.amount),
        receiver_name: state?.paying_to,
        memo: state?.memo,
        phone: state?.phone_number,
        receiver_phone: state?.phone_number,
        // customer_id: custId,
      })
      .then((res) => {
        setLoading(false);
        setCompletedTransaction(true);
        if (res?.data?.status === true && res?.data?.statusCode === 200) {
          setCompletedTransactionData(res?.data?.response?.createdReq);
        }
        // console.log(res?.data?.response?.createdReq);
      })
      .catch((e) => {
        setLoading(false);

        setErrors(e?.response?.data?.message);
      });
  };
  const handleSubmit = () => {
    // if (!state?.phone_number) {
    //   setErrors("Phone number is required");
    //   return;
    // }

    if (!state?.amount) {
      setErrors("Amount is required");
      return;
    }

    if (toNumber(state?.amount) <= 0) {
      setErrors("Invalid Amount");
      return;
    }

    if (toNumber(state?.amount) > 2000) {
      setErrors("Amount can not be greater than 2000");
      return;
    }

    if (toNumber(customer?.amount) < toNumber(state?.amount)) {
      setDailog(true);
    } else {
      if (customer?.enablePin) {
        // setTransferFor("Share");
        setShowPinDrawer(true);
      } else {
        if (rcvrExtId) {
          if (customer?.enablePin) {
            setTransferFor("Instant");
            setShowPinDrawer(true);
          } else {
            sendInstantMoney();
          }
        } else {
          setTransferFor("Share");
          sendMoneyMessage();
        }
      }

      // if (custConfirmed || customerId) {
      //   if (customer?.enablePin) {
      //     setTransferFor("Instant");
      //     setShowPinDrawer(true);
      //   } else {
      //     sendInstantMoney();
      //   }
      // } else {
      //   if (customer?.enablePin) {
      //     setTransferFor("Share");
      //     setShowPinDrawer(true);
      //   } else {
      //     sendMoneyMessage();
      //   }
      // }
    }
  };
  const handleChange = ({ target }) => {
    if (target.name === "phone_number") {
      setCustConfirmed(false);
      const formattedPhoneNumber = target.value.replace(/\D/g, ""); // Remove non-digit characters
      setState((prev) => ({
        ...prev,
        phone_number: formattedPhoneNumber,
        phone: formattedPhoneNumber,
        paying_to: "",
      }));
    } else if (target.name === "amount") {
      setState((prev) => ({
        ...prev,
        amount: roundUp(target.value || 0),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [target.name]: target.value,
      }));
    }
  };
  const handleLoading = (data) => {
    // if (rcvrExtId) {
    //   sendInstantMoney();
    // } else {
    //   sendMoneyMessage();
    // }
    if (rcvrExtId) {
      sendInstantMoney();
      setShowReceipt(true);
      setDailog(false);
      setRequiredOpenPopup(false);
      setFundLoaded(data);
    } else {
      sendMoneyMessage();
      setShowReceipt(true);
      setDailog(false);
      setRequiredOpenPopup(false);
      setFundLoaded(data);
    }
  };

  const message =
    type === "Request Money"
      ? `
      Hi ${state?.paying_to},  - I have requested ${currencyFormat(
        state?.amount,
      )} from you through Gain Funds.
      Please click the link below to complete the payment ${
        window.location.origin
      }/p2p/${completedTransactionData?.friendsandfamily_external_id}`
      : `Hi, ${state?.paying_to}, I've sent you ${currencyFormat(
          state?.amount,
        )} through Gain Funds.
Please click the link below to receive it ${window.location.origin}/p2p/${
          completedTransactionData?.friendsandfamily_external_id
        }`;

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator
          .share({
            title: `Payment Initiated to: ${state?.paying_to}`,
            text: message,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Web Share API is not supported in this browser');
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };
  const handleFirefoxShare = async () => {
    try {
      if (navigator.share) {
        await navigator
          .share({
            title: `Payment Initiated to: ${state?.paying_to}`,
            text: message,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      } else {
        // Fallback for browsers that do not support the Web Share API
        // alert('Web Share API is not supported in this browser');
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };
  // const handelGetCustDetails = () => {
  //   setErrors(null);
  //   setVerifyLoading(true);
  //   if (!state?.phone_number) {
  //     setErrors("Phone number is required");
  //     setVerifyLoading(false);
  //     return;
  //   }
  //   Axios(token)
  //     .post(`/get-customer-details/${customer?.customer_external_id}`, {
  //       phone: state?.phone_number,
  //     })
  //     .then((data) => {
  //       setVerifyLoading(false);
  //       if (data?.data?.message !== "Failed") {
  //         setCustConfirmed(true);
  //         setState((prev) => ({
  //           ...prev,
  //           paying_to: data?.data?.response?.custDetails?.fullName,
  //         }));
  //         setRcvrExtId(data?.data?.response?.custDetails?.customer_external_id);
  //       } else {
  //         setCustConfirmed(false);
  //         setIsReceiverNameDisabled(false);
  //       }
  //     })
  //     .catch((e) => {
  //       setVerifyLoading(false);
  //       setCustConfirmed(false);
  //       setIsReceiverNameDisabled(false);
  //       setState((prev) => ({
  //         ...prev,
  //         paying_to: "",
  //         phone_number: "",
  //       }));
  //       if (e?.response?.data?.message !== "Failed") {
  //         setErrors(e?.response?.data?.message);
  //       }
  //     });
  // };
  const handleRequestMoney = () => {
    // if (!state?.phone_number) {
    //   setErrors("Phone number is required");
    //   return;
    // }
    if (!state?.amount) {
      setErrors("Amount is required");
      return;
    }
    if (toNumber(state?.amount) <= 0) {
      setErrors("Invalid Amount");
      return;
    }
    if (toNumber(state?.amount) > 2000) {
      setErrors("Amount can not be greater than 2000");
      return;
    }
    Axios(token)
      .post("/request-money", {
        amount: toNumber(state?.amount),
        sender_phone: state?.phone_number,
        sender_name: state?.paying_to,
        memo: state?.memo,
      })
      .then((res) => {
        setCompletedTransactionData(res?.data?.response?.createdReq);
        setCompletedTransaction(true);
        // console.log(res?.data?.response?.createdReq);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isPinVerified) {
      setShowPinDrawer(false);
      if (transferFor === "Instant") {
        sendInstantMoney();
        // console.log("Empty If Statement");
      } else {
        sendMoneyMessage();
      }
    }
  }, [isPinVerified]);

  return (
    <>
      {errors && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={errors}
          isModalOpen={!!errors}
          CloseOnClick={() => {
            setReceiverDetails("");
            setErrors("");
            setState({ amount: "" });
            if (isSameCustomer) {
              route("/dashboard");
            }
          }}
        />
      )}
      <HeaderFull />
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        <Container>
          <Typography variant="h4" fontWeight={600} gutterBottom>
            {type === "Request Money" ? "Request Money" : "Send Money"}
          </Typography>
          {!requestInfo?.contactless?.contactLess_external_id && (
            <>
              {/*{customerId ? (*/}
              {/*  <>*/}
              {/*    <TextField*/}
              {/*      fullWidth*/}
              {/*      label="Enter Phone Number"*/}
              {/*      placeholder="XXXXXXXXXX"*/}
              {/*      onChange={handleChange}*/}
              {/*      name={`phone_number`}*/}
              {/*      value={state?.phone_number}*/}
              {/*      InputProps={{*/}
              {/*        endAdornment: (*/}
              {/*          <InputAdornment position="end">*/}
              {/*            <GppGoodIcon*/}
              {/*              sx={{ fontSize: "1.5rem", color: "green" }}*/}
              {/*            />*/}
              {/*          </InputAdornment>*/}
              {/*        ),*/}
              {/*      }}*/}
              {/*      required*/}
              {/*      className={state?.phone_number && "shrinkLable"}*/}
              {/*      margin="dense"*/}
              {/*      onBlur={handelGetCustDetails}*/}
              {/*      InputLabelProps={{ shrink: true }}*/}
              {/*      disabled={true}*/}
              {/*    />*/}
              {/*    <TextField*/}
              {/*      fullWidth*/}
              {/*      onChange={handleChange}*/}
              {/*      name={`paying_to`}*/}
              {/*      value={state?.paying_to}*/}
              {/*      required*/}
              {/*      label="Receipient name"*/}
              {/*      margin="dense"*/}
              {/*      id={"payee_name"}*/}
              {/*      InputLabelProps={{ shrink: true }}*/}
              {/*      disabled={isReceiverNameDisabled}*/}
              {/*      // InputLabelProps={{ shrink: custConfirmed }}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <TextField*/}
              {/*      fullWidth*/}
              {/*      label="Enter Phone Number"*/}
              {/*      placeholder="XXXXXXXXXX"*/}
              {/*      onChange={handleChange}*/}
              {/*      name={`phone_number`}*/}
              {/*      disabled={*/}
              {/*        !!requestInfo?.contactless?.contactLess_external_id*/}
              {/*      }*/}
              {/*      value={state?.phone_number}*/}
              {/*      InputProps={{*/}
              {/*        endAdornment: (*/}
              {/*          <InputAdornment position="end">*/}
              {/*            {verifyLoading && (*/}
              {/*              <SvgIcon viewBox="0 0 200 200">*/}
              {/*                <LoadingIcon />*/}
              {/*              </SvgIcon>*/}
              {/*            )}*/}
              {/*            {custConfirmed && (*/}
              {/*              <GppGoodIcon*/}
              {/*                sx={{ fontSize: "1.5rem", color: "green" }}*/}
              {/*              />*/}
              {/*            )}*/}
              {/*          </InputAdornment>*/}
              {/*        ),*/}
              {/*      }}*/}
              {/*      required*/}
              {/*      margin="dense"*/}
              {/*      onBlur={handelGetCustDetails}*/}
              {/*    />*/}
              {/*    {custConfirmed ? (*/}
              {/*      <TextField*/}
              {/*        fullWidth*/}
              {/*        placeholder="Paying to"*/}
              {/*        onChange={handleChange}*/}
              {/*        name={`paying_to`}*/}
              {/*        value={state?.paying_to}*/}
              {/*        label="Receipient name"*/}
              {/*        required*/}
              {/*        margin="dense"*/}
              {/*        id={"payee_name"}*/}
              {/*        disabled={isReceiverNameDisabled}*/}
              {/*        InputLabelProps={{ shrink: true }}*/}
              {/*      />*/}
              {/*    ) : (*/}
              {/*      <TextField*/}
              {/*        fullWidth*/}
              {/*        placeholder="Paying to"*/}
              {/*        onChange={handleChange}*/}
              {/*        name={`paying_to`}*/}
              {/*        value={state?.paying_to}*/}
              {/*        label="Receipient name"*/}
              {/*        required*/}
              {/*        margin="dense"*/}
              {/*        id={"payee_name"}*/}
              {/*        disabled={isReceiverNameDisabled}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  </>*/}
              {/*)}*/}
              {rcvrExtId ? (
                <TextField
                  fullWidth
                  placeholder="Paying to"
                  onChange={handleChange}
                  name={`paying_to`}
                  value={state?.paying_to}
                  label="Receipient name"
                  required
                  margin="dense"
                  id={"payee_name"}
                  InputLabelProps={{ shrink: true }}
                  disabled={true}
                  // disabled={isReceiverNameDisabled}
                />
              ) : (
                <TextField
                  fullWidth
                  placeholder="Paying to"
                  onChange={handleChange}
                  name={`paying_to`}
                  value={state?.paying_to}
                  label="Receipient name"
                  required
                  margin="dense"
                  id={"payee_name"}
                  // disabled={isReceiverNameDisabled}
                />
              )}
              {/*<TextField*/}
              {/*  fullWidth*/}
              {/*  placeholder="Paying to"*/}
              {/*  onChange={handleChange}*/}
              {/*  name={`paying_to`}*/}
              {/*  value={state?.paying_to}*/}
              {/*  label="Receipient name"*/}
              {/*  required*/}
              {/*  margin="dense"*/}
              {/*  id={"payee_name"}*/}
              {/*  // disabled={isReceiverNameDisabled}*/}
              {/*/>*/}

              <TextField
                fullWidth
                type="number"
                onChange={handleChange}
                name={`amount`}
                disabled={!!requestInfo?.contactless?.amount}
                value={state?.amount || requestInfo?.contactless?.amount || ""}
                label="Enter amount"
                margin="dense"
                placeholder="0.00"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"> $</InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                placeholder="Memo"
                label="Memo"
                multiline
                maxRows={5}
                minRows={3}
                margin="dense"
                onChange={handleChange}
                name={`memo`}
                value={state?.memo}
              />
            </>
          )}
          <Box className="clear10" />
          {type === "Request Money" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestMoney}
              fullWidth
              disabled={loading}
            >
              REQUEST {currencyFormat(state?.amount)}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              disabled={loading}
            >
              TRANSFER {currencyFormat(state?.amount)}
            </Button>
          )}
        </Container>
      </Box>
      <BottomNav />

      <Drawer
        anchor="bottom"
        open={requiredOpenPopup}
        onClose={toggleDrawer(false)}
        className="grayBg"
      >
        <IconButton
          color="primary"
          onClick={toggleDrawer(false)}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box component={"div"} pb={2} pt={5} px={2}>
          <Typography variant="h3">
            <>
              Hi {customer?.firstName.slice(0, 1)}
              <Box component={"span"} textTransform={"lowercase"}>
                {customer?.firstName.slice(1)}
              </Box>
            </>
          </Typography>
          <Typography variant="body1" className="text-center">
            Your account does not have sufficient funds to make this transfer.
            Please load funds into your account before continuing.
          </Typography>
          <Box className="clear30" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            fullWidth
          >
            Load Fund
          </Button>
        </Box>
      </Drawer>
      <Drawer
        open={dailog}
        anchor="bottom"
        onClose={handleClose}
        className="grayBg"
      >
        <IconButton
          color="primary"
          onClick={handleClose}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box component={"div"} pb={2} pt={5}>
          <LoadDailog
            onComplete={handleLoading}
            // open={dailog}
            cust={customer}
            info={requestInfo}
            amount={toNumber(state?.amount) - toNumber(customer?.amount)}
            onCancel={handleClose}
            // storeInfo={requestInfo?.store || state}
            // rcvrExtId={rcvrExtId}
            // setInstantTransferComplete={setInstantTransferComplete}
            // setTransferData={setTransferData}
            // setErrors={setErrors}
            // custConfirmed={custConfirmed}
            // customerId={customerId}
            // wantToSendAmount={state?.amount}
            // receiver_name={state?.receipient_name}
            // memo={state?.memo}
            // setCompletedTransaction={setCompletedTransaction}
            // setCompletedTransactionData={setCompletedTransactionData}
            // isP2p={true}
            // requestedAmount={state?.amount}
          />
        </Box>
      </Drawer>
      {!rcvrExtId && (
        <Drawer anchor="bottom" open={completedTransaction}>
          <IconButton
            color="primary"
            onClick={() => {
              setCompletedTransaction(false);
              route(`/dashboard`);
            }}
            aria-label="close"
            size="small"
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box component={"div"} p={5}>
            <Box
              component={"div"}
              width={"7.5rem"}
              height={"6.25rem"}
              mx={"auto"}
              my={2}
            >
              <GainLogo />
            </Box>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={2}
              >
                <Typography variant="h5" fontWeight={500}>
                  {`Transferring to: ${state?.paying_to}`}
                </Typography>
              </Stack>
              {completedTransactionData?.amount && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={2}
                >
                  <Typography variant="h5" fontWeight={500}>
                    {`Transferring Amount: ${currencyFormat(
                      completedTransactionData?.amount || 0,
                    )}`}
                  </Typography>
                  <Typography variant="h5" fontWeight={500}></Typography>
                </Stack>
              )}
            </Stack>
            <Box className="clear40" />
            {browserName === "Chrome" || browserName === "Mobile Safari" ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleShare}
                startIcon={<ShareOutlinedIcon />}
              >
                Share Link
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleFirefoxShare}
                startIcon={<ShareOutlinedIcon />}
              >
                Share Link
              </Button>
            )}
          </Box>
        </Drawer>
      )}

      <Dialog fullScreen anchor="bottom" open={instantTransferComplete}>
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.loginContainer}`}
          justifyContent={"center"}
        >
          <Container>
            <Box
              component={"div"}
              className={`${LayoutStyle.BrandLogo}`}
              sx={{ mb: { xs: 3, sm: 4 } }}
            >
              <Link to={`/`}>
                <GainLogo />
              </Link>
            </Box>

            <Typography variant="h3" color={"secondary"} textAlign={"center"}>
              {`Paid Successfully`}
            </Typography>
            <Box component={"div"} className={`${LayoutStyle.successLogo}`}>
              <SuccessLogo />
            </Box>
            <Box className="clear30" />
            <Card elevation={2} sx={{ mx: { xs: 0, sm: 1 } }}>
              <CardContent>
                <Typography
                  variant="h2"
                  gutterBottom
                  textAlign={"center"}
                ></Typography>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      Name:{" "}
                    </Typography>
                    <Typography variant="h5" fontWeight={500}>
                      {transferData?.receiver_name || ""}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      Date:{" "}
                    </Typography>
                    <Typography variant="h5" fontWeight={500}>
                      {moment(transferData?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a",
                      )}
                    </Typography>
                  </Stack>
                  {transferData?.transaction_id && (
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={2}
                    >
                      <Typography variant="h5" fontWeight={500}>
                        Reference:{" "}
                      </Typography>
                      <Typography variant="h5" fontWeight={500}>
                        {transferData?.transaction_id || ""}
                      </Typography>
                    </Stack>
                  )}
                  {transferData?.amount && (
                    <>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        spacing={2}
                      >
                        <Typography variant="h5" fontWeight={500}>
                          Amount:{" "}
                        </Typography>
                        <Typography variant="h5" fontWeight={500}>
                          {currencyFormat(transferData?.amount || 0)}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
            <Box className="clear30" />

            <Button
              variant="contained"
              color="primary"
              onClick={() => route(`/dashboard`)}
              fullWidth
              // to={`/dashboard`}
              // on
            >
              Go Back to Dashboard
            </Button>
          </Container>
        </Box>
      </Dialog>

      <VerifyPINDrawer
        onSubmit={() => {
          setIsPinVerified(true);
          if (rcvrExtId) {
            sendInstantMoney();
          }
          setShowPinDrawer(false);
        }}
        showPinDrawer={showPinDrawer}
        token={token}
        onClose={() => setShowPinDrawer(false)}
      />
    </>
  );
}
export default FriendsAndFamily;
