import React from "react";

export default function TwoFactor() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 42" fill="none">
     <g opacity="0.5">
      <path d="M17.0786 12.1113H22.4381" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M36.8172 12.1113H37.6669C38.6473 12.1113 39.4316 12.8956 39.4316 13.876V37.3401H3.02637V13.876C3.02637 12.8956 3.81068 12.1113 4.79107 12.1113H8.97408" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M41.5229 40.0196V38.3856C41.5229 37.7974 41.0654 37.3398 40.4771 37.3398H2.04575C1.45752 37.3398 1 37.7974 1 38.3202V39.9542C1 40.5425 1.45752 41 2.04575 41H40.5425C41.0654 41 41.5229 40.5425 41.5229 40.0196Z" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.6538 3.48366H27.6016C27.0787 3.48366 26.6865 3.0915 26.6865 2.56863V1H32.6342V2.56863C32.6342 3.02614 32.1767 3.48366 31.6538 3.48366Z" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M36.6208 23.1569C36.6208 24.0065 35.9672 24.6601 35.1175 24.6601H24.2025C23.3528 24.6601 22.6992 24.0065 22.6992 23.1569V2.50327C22.6992 1.65359 23.3528 1 24.2025 1H35.1175C35.9672 1 36.6208 1.65359 36.6208 2.50327V23.1569Z" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.7778 22.1113H30.4772" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.55566 28.3203L9.75828 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.18945 27.8628V31.0654" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.75828 28.3203L6.55566 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.0913 28.3203L16.2939 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.7256 27.8628V31.0654" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.2939 28.3203L13.0913 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.6929 28.3203L22.8301 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.2612 27.8628V31.0654" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.8301 28.3203L19.6929 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.2285 28.3203L29.4311 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.7974 27.8628V31.0654" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.4311 28.3203L26.2285 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.7646 28.3203L35.9673 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M34.3335 27.8628V31.0654" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.9673 28.3203L32.7646 30.6079" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.55566 33.353H9.75828" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.0913 33.353H16.2939" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.6929 33.353H22.8301" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.2285 33.353H29.4311" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.7646 33.353H35.9673" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.647 17.4707C28.9084 17.0786 30.1502 14.4642 30.0849 12.5034" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M34.3989 11.7188C34.3989 11.9148 34.4643 12.1763 34.3989 12.3723C34.3336 14.3985 33.9414 16.0325 33.4839 17.3397" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.8564 15.4445C24.9218 15.3138 24.9872 15.2484 25.0525 15.1177C25.1179 15.0523 25.7715 14.0066 25.8368 12.0458C25.9022 9.69286 27.7976 7.86279 30.0852 7.86279H30.2159C31.327 7.86279 32.4381 8.38567 33.2225 9.16998" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.111 13.876C31.8496 15.5753 31.3921 16.8172 30.9346 17.7322" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.6211 16.6863C26.6865 16.6209 26.7518 16.4902 26.8172 16.4248C26.8825 16.2941 27.9283 14.8562 27.9936 12.1765C27.9936 11 28.974 10.085 30.1505 10.085H30.2159C31.0002 10.085 31.6538 10.5425 32.0459 11.1961" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.8758 11.0002V7.92835C13.8758 7.2094 13.2875 6.68652 12.6339 6.68652C11.915 6.68652 11.3921 7.27476 11.3921 7.92835V11.0002" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.97363 12.1765V7.86277C8.97363 5.83662 10.6076 4.20264 12.6338 4.20264C14.6599 4.20264 16.2939 5.83662 16.2939 7.86277V12.3072" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9737 17.732C18.9737 21.2614 16.0979 24.0718 12.6338 24.0718C9.1044 24.0718 6.29395 21.196 6.29395 17.732C6.29395 14.2025 9.16976 11.3921 12.6338 11.3921C16.1632 11.3921 18.9737 14.2025 18.9737 17.732Z" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6992 20.3462V21.5227" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.1374 18.5819C14.1374 19.4316 13.4838 20.0851 12.6341 20.0851C11.7845 20.0851 11.1309 19.4316 11.1309 18.5819C11.1309 17.7322 11.7845 17.0786 12.6341 17.0786C13.4838 17.0786 14.1374 17.7322 14.1374 18.5819Z" stroke="black" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
  );
}
