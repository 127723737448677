import React from "react";

export default function ListIcon(props) {
  return (
    <>
       <path fill={props.fill? (props.fill):"black"} d="m14.9 24q-0.2 0-0.5-0.1-0.3 0-0.6-0.2-0.2-0.1-0.5-0.3-0.2-0.1-0.4-0.4c-0.3-0.3-0.5-0.4-0.9-0.4-0.4 0-0.5 0.1-0.9 0.4q-0.4 0.5-0.9 0.7-0.5 0.3-1.1 0.3-0.6 0-1.1-0.3-0.6-0.2-1-0.7c-0.3-0.3-0.5-0.4-0.9-0.4q0 0-0.1 0-0.1 0-0.2 0-0.1 0-0.1 0.1-0.1 0-0.2 0.1-0.2 0.2-0.4 0.3-0.3 0.1-0.6 0.1-0.2 0-0.5 0-0.3 0-0.5-0.2-0.3-0.1-0.5-0.3-0.2-0.1-0.3-0.4-0.2-0.2-0.3-0.5 0-0.2 0-0.5v-19.2q0-0.4 0.1-0.7 0.2-0.4 0.4-0.7 0.3-0.2 0.7-0.4 0.3-0.1 0.7-0.1h15.4q0.4 0 0.7 0.1 0.4 0.2 0.7 0.4 0.2 0.3 0.4 0.7 0.1 0.3 0.1 0.7v19.2q0 0.3 0 0.5-0.1 0.3-0.3 0.5-0.1 0.2-0.3 0.4-0.2 0.2-0.5 0.3-0.2 0.1-0.5 0.2-0.2 0-0.5 0-0.3 0-0.6-0.1-0.2-0.1-0.4-0.3-0.1-0.1-0.2-0.1 0-0.1-0.1-0.1-0.1 0-0.2 0 0 0-0.1 0c-0.4 0-0.6 0.1-0.9 0.4q-0.2 0.3-0.4 0.4-0.3 0.2-0.5 0.3-0.3 0.2-0.6 0.2-0.3 0.1-0.6 0.1zm-2.9-3.1q0.3 0 0.6 0.1 0.3 0 0.5 0.2 0.3 0.1 0.5 0.3 0.3 0.1 0.4 0.4c0.4 0.3 0.6 0.4 0.9 0.4 0.4 0 0.6-0.1 0.9-0.4q0.2-0.3 0.5-0.4 0.2-0.2 0.5-0.3 0.2-0.2 0.5-0.2 0.3-0.1 0.6-0.1 0.2 0 0.4 0.1 0.2 0 0.4 0.1 0.2 0 0.4 0.1 0.2 0.2 0.4 0.3 0 0 0.1 0 0 0.1 0.1 0.1 0 0 0 0 0.1 0 0.1-0.1 0.1 0 0.1 0 0 0 0-0.1 0.1 0 0.1 0 0-0.1 0-0.1v-19.2q0-0.1 0-0.1-0.1-0.1-0.1-0.1 0 0-0.1-0.1 0 0-0.1 0h-15.4q-0.1 0-0.1 0-0.1 0.1-0.1 0.1 0 0-0.1 0.1 0 0 0 0.1v19.2q0 0 0 0.1 0 0 0.1 0 0 0.1 0 0.1 0 0 0.1 0 0 0.1 0.1 0.1 0 0 0 0 0.1 0 0.1-0.1 0.1 0 0.1 0 0.2-0.1 0.4-0.3 0.2-0.1 0.4-0.1 0.2-0.1 0.4-0.1 0.2-0.1 0.4-0.1 0.3 0 0.6 0.1 0.3 0 0.6 0.2 0.2 0.1 0.5 0.3 0.2 0.1 0.4 0.4c0.3 0.3 0.5 0.4 0.9 0.4 0.4 0 0.5-0.1 0.9-0.4q0.2-0.3 0.4-0.4 0.2-0.2 0.5-0.3 0.3-0.2 0.5-0.2 0.3-0.1 0.6-0.1z"/>
        <path fill={props.fill? (props.fill):"black"} d="m16.4 6.2h-6q-0.4 0-0.6-0.2-0.3-0.3-0.3-0.6 0-0.3 0.3-0.6 0.2-0.2 0.6-0.2h6q0.3 0 0.6 0.2 0.2 0.3 0.2 0.6 0 0.3-0.2 0.6-0.3 0.2-0.6 0.2z"/>
        <path fill={props.fill? (props.fill):"black"} d="m16.4 11.7h-6q-0.4 0-0.6-0.2-0.3-0.3-0.3-0.6 0-0.3 0.3-0.6 0.2-0.2 0.6-0.2h6q0.3 0 0.6 0.2 0.2 0.3 0.2 0.6 0 0.3-0.2 0.6-0.3 0.2-0.6 0.2z"/>
        <path fill={props.fill? (props.fill):"black"} d="m16.4 17.2h-6q-0.4 0-0.6-0.2-0.3-0.3-0.3-0.6 0-0.3 0.3-0.6 0.2-0.2 0.6-0.2h6q0.3 0 0.6 0.2 0.2 0.3 0.2 0.6 0 0.3-0.2 0.6-0.3 0.2-0.6 0.2z"/>
        <path fill={props.fill? (props.fill):"black"} d="m7.6 12c-0.6 0-1.1-0.5-1.1-1.1 0-0.6 0.5-1.1 1.1-1.1 0.6 0 1.1 0.5 1.1 1.1 0 0.6-0.5 1.1-1.1 1.1z"/>
        <path fill={props.fill? (props.fill):"black"} d="m7.6 6.5c-0.6 0-1.1-0.5-1.1-1.1 0-0.6 0.5-1.1 1.1-1.1 0.6 0 1.1 0.5 1.1 1.1 0 0.6-0.5 1.1-1.1 1.1z"/>
        <path fill={props.fill? (props.fill):"black"} d="m7.6 17.5c-0.6 0-1.1-0.5-1.1-1.1 0-0.6 0.5-1.1 1.1-1.1 0.6 0 1.1 0.5 1.1 1.1 0 0.6-0.5 1.1-1.1 1.1z"/>
    </>

  );
}
