import Axios from "../config/Axios";

export const getWithdrawalServices = async (
  customerId,
  token,
  page,
  type,
  limit = 10,
) => {
  const getCustomer = await Axios(token).post(
    `/withdrawal-request/${customerId}/list`,
    {
      page: page,
      limit: limit,
      type: type,
    },
  );
  return getCustomer?.data?.response;
};

export const createWithdrawalRequest = async (customerId, token, data) => {
  const createWithdrawal = await Axios(token).post(
    `/withdrawal-request/${customerId}`,
    data,
  );
  return createWithdrawal?.data;
};
