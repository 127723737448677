import React from "react";

export default function HelpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 22" fill="none">
    <path d="M10.7213 13.9156C10.7188 13.9156 10.7163 13.9156 10.7137 13.9156C10.2463 13.9114 9.87031 13.5291 9.87454 13.0612L9.87835 12.6378C9.87835 12.6137 9.87962 12.5891 9.88217 12.565C9.99945 11.3333 10.817 10.5784 11.4746 9.97171C11.6973 9.76594 11.9081 9.5716 12.0876 9.3688C12.3065 9.12196 12.6241 8.61642 12.29 8.00631C11.9051 7.30178 10.9652 7.10237 10.2349 7.26961C9.4719 7.44405 9.19076 8.09565 9.09084 8.46824C8.96975 8.92 8.50529 9.18801 8.05352 9.06734C7.60176 8.94625 7.33375 8.48178 7.45442 8.03002C7.79187 6.76958 8.66745 5.89103 9.85634 5.61879C11.4563 5.2534 13.0678 5.89993 13.7753 7.1934C14.3642 8.27051 14.2029 9.53434 13.354 10.4921C13.1182 10.758 12.8662 10.9908 12.6224 11.2156C12.0148 11.7766 11.635 12.1518 11.5707 12.6933L11.5673 13.0756C11.5639 13.5418 11.1854 13.9156 10.7213 13.9156Z" fill="black"/>
    <path d="M10.7208 16.4541C10.5006 16.4541 10.2805 16.3652 10.1238 16.2086C9.96294 16.0519 9.87402 15.8318 9.87402 15.6074C9.87402 15.3872 9.96294 15.167 10.1238 15.0104C10.4371 14.6971 11.0045 14.6971 11.3178 15.0104C11.4787 15.167 11.5676 15.3872 11.5676 15.6074C11.5676 15.8318 11.4787 16.0477 11.322 16.2086C11.1611 16.3652 10.9452 16.4541 10.7208 16.4541Z" fill="black"/>
    <path d="M10.9762 22C10.0129 22 9.05818 21.8747 8.13814 21.627C4.17347 20.5605 1.06915 17.2762 0.229556 13.2594C-0.626124 9.16734 0.953137 4.86565 4.2535 2.3003C6.16131 0.817151 8.56746 0 11.0291 0C12.7332 0 14.4255 0.396297 15.9235 1.14528C19.6227 2.99594 22.0128 6.86238 22.0128 10.996C22.0128 14.3687 20.4212 17.6196 17.7555 19.6917C15.841 21.1803 13.4331 22 10.9762 22ZM11.0295 1.69358C8.94259 1.69358 6.90522 2.38371 5.29335 3.63738C2.50022 5.80855 1.16314 9.44932 1.88757 12.9127C2.59802 16.3104 5.22434 19.0892 8.57847 19.9914C9.35498 20.2001 10.162 20.3064 10.9762 20.3064C13.0584 20.3064 15.0971 19.6133 16.7165 18.3546C18.9728 16.6009 20.3196 13.8501 20.3196 10.996C20.3196 7.49832 18.2971 4.22632 15.1665 2.66019C13.9022 2.02806 12.472 1.69358 11.0295 1.69358Z" fill="black"/>
    </svg>
  );
}
