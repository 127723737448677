/* eslint-disable */
import React, { useEffect, useState } from "react";
import HeaderFull from "../components/headerFull";
import LayoutStyle from "../scss/base.module.scss";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";

import Axios from "../config/Axios";
import { useCustomer } from "../context/customerContext";
import { useNavigate, useParams } from "react-router-dom";

import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import moment from "moment-timezone";
import Loader from "../components/Loader";
import { toNumber } from "lodash";
import TransactionSuccessfulDrawer from "../components/transactionSuccessfulDrawer";
import CustomerConfirmModal from "../components/Modal";
import CloseIcon from "@mui/icons-material/Close";
import LoadDailog from "../components/loadDailog";
import BottomNav from "../components/bottomNav";

function FundRequests() {
  const { token, customer, setCustomer } = useCustomer();
  const navigate = useNavigate();
  const { extId } = useParams();
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [completedTransaction, setCompletedTransaction] = useState(false);
  const [completedTransactionData, setCompletedTransactionData] = useState({});
  const [totalAmount, setTotalAmount] = useState();
  const [errors, setErrors] = useState("");
  const [fundRequired, setFundRequired] = useState(false);

  function getInitials(fullName) {
    const nameParts = fullName.split(" ");
    const initials = nameParts.map((part) => part[0]).join("");
    return initials.toUpperCase();
  }
  useEffect(() => {
    setLoading(true);
    Axios(token)
      .get(`/fetch-p2p-details/${extId}`)
      .then((res) => {
        setLoading(false);
        // console.log(res?.data?.response);
        setDetails(res.data?.response);
        setName(getInitials(res.data?.response?.receiver_name));
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, []);
  const sendMoneyCb = () => {
    setLoading(true);
    Axios(token)
      .post(`/send-instant-money/${details?.friendsandfamily_external_id}`, {
        external_id: details?.receiver?.customer_external_id,
        amount: toNumber(details?.amount),
      })
      .then((res) => {
        setLoading(false);
        setCompletedTransactionData({
          created_at: res?.data?.response?.debitTransaction?.created_at,
          name: details?.sender_name,
          customer_account_number: details?.sender?.account_number,
          order_id: details?.sender?.account_number,
        });
        setTotalAmount(res?.data?.response?.debitTransaction?.amount);
        setCompletedTransaction(true);
        setCustomer((prev) => ({
          ...prev,
          p2p: false,
          p2pId: "",
        }));
        // console.log(res?.data?.response?.debitTransaction);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };
  const sendMoney = () => {
    if (toNumber(customer?.amount) < toNumber(details?.amount)) {
      setFundRequired(true);
      return;
    }
    sendMoneyCb();
  };

  const handleLoading = (data) => {
    sendMoneyCb();
    // console.log("callback");
    setFundRequired(false);
  };

  const handleRejectMoney = () => {
    setLoading(true);
    Axios(token)
      .post("/reject-request-money", {
        reqId: extId,
      })
      .then((res) => {
        setLoading(false);
        // console.log(res?.data?.response);
        setCustomer((prev) => ({
          ...prev,
          p2p: false,
          p2pId: "",
        }));
        navigate("/requests");
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      {loading && <Loader show={loading} />}
      {errors && (
        <CustomerConfirmModal
          type={`Error`}
          headLine={`Error`}
          showCancel={`Close`}
          message={errors}
          isModalOpen={!!errors}
          CloseOnClick={() => {
            setErrors("");
          }}
        />
      )}
      <HeaderFull />
      {details && (
        <Box
          component={"main"}
          className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
        >
          <div className={"flex justify-center mt-10"}>
            <Avatar sx={{ bgcolor: "#00365B", width: 56, height: 56 }}>
              {name}
            </Avatar>
          </div>

          <div
            className={"flex-col justify-center items-center  mt-6 space-y-3"}
          >
            <div className={"flex justify-center"}>
              <Typography
                className={"text-center w-3/4"}
                variant="h4"
                sx={{
                  color: "#00365B",
                  fontWeight: "500",
                }}
              >{`${details?.receiver_name} has requested funds from you`}</Typography>
              {/*<div className={"border-amber-950 h-10 bg-amber-500 w-3/4"}></div>*/}
            </div>
            {details?.receiver_phone && (
              <Typography
                className={"text-center mt-2 text-sm text-gray-500"}
                style={{ marginTop: "-2px" }}
              >{`${details?.receiver_phone}`}</Typography>
            )}

            <div className={"mt-6"}>
              <Typography
                className={"text-center mt-6"}
                sx={{ color: "#00365B", fontWeight: "600", fontSize: "3rem" }}
              >{`$${details?.amount}`}</Typography>
            </div>
            <div className="flex justify-center space-x-2">
              <button
                onClick={sendMoney}
                className="bg-[#00365B] text-white font-medium py-2 px-4 rounded-full w-[90px]"
              >
                Pay
              </button>
              {/*<button*/}
              {/*  onClick={handleRejectMoney}*/}
              {/*  className="border border-red-600 text-red-600 font-medium py-2 px-4 rounded-full w-[90px]"*/}
              {/*>*/}
              {/*  Decline*/}
              {/*</button>*/}
            </div>
            <div className={"flex justify-center"}>
              <ErrorRoundedIcon sx={{ color: "#00365B", fontSize: "1.3rem" }} />
              <Typography className="text-center ">Request received</Typography>
            </div>
            {/*<p className="text-center mt-1 text-sm text-gray-500">*/}
            {/*  Ignore this request if you have already made this payment outside of*/}
            {/*  Gainfunds*/}
            {/*</p>*/}
            <div className="flex justify-center mt-4">
              <hr className="border-t border-gray-500 w-1/2" />
            </div>
            <p className="text-center mt-2 text-sm text-gray-500">
              {moment(details?.createdAt).local().format("MMM DD YYYY, h:mm a")}
            </p>
            <div className="mt-6 p-4 border border-gray-700 rounded-lg w-4/5 mx-auto">
              <p className="text-lg font-semibold text-[#00365B]">{`To: ${details?.receiver_name}`}</p>
              <p className="text-sm text-gray-500">
                {details?.receiver?.account_number}
              </p>
              <p className="text-lg font-semibold mt-4 text-[#00365B]">
                Request ID
              </p>
              <p className="text-sm text-gray-500">
                {extId.replace(/-/g, "").substring(0, 12)}
              </p>
            </div>
            <div className={"flex justify-center"}>
              <div className={"w-[25%]"}>
                <Typography
                  className="text-center underline text-red-500 cursor-pointer"
                  onClick={handleRejectMoney}
                >
                  Decline
                </Typography>
              </div>
            </div>
          </div>
        </Box>
      )}

      <BottomNav payRound={false} />
      <Drawer
        open={fundRequired}
        anchor="bottom"
        onClose={() => setFundRequired(false)}
        className="grayBg"
      >
        <IconButton
          color="primary"
          onClick={() => setFundRequired(false)}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box component={"div"} pb={2} pt={5}>
          <LoadDailog
            onComplete={handleLoading}
            cust={customer}
            amount={toNumber(details?.amount) - toNumber(customer?.amount)}
            onCancel={() => setFundRequired(false)}
          />
        </Box>
      </Drawer>
      {completedTransaction && (
        <TransactionSuccessfulDrawer
          completedTransaction={completedTransaction}
          completedTransactionData={completedTransactionData}
          totalAmount={totalAmount}
        />
      )}
    </>
  );
}

export default FundRequests;
