/* eslint-disable */
import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import { useCustomer } from "../context/customerContext";
import CloseIcon from "@mui/icons-material/Close";
import Axios from "../config/Axios";
import { useNavigate } from "react-router-dom";
import {
  encryptData,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../utils/CardUtility";
import Loader from "./Loader";

function NewCardDrawer({
  onOpen,
  closeDrawer,
  customer,
  onSubmit,
  account_for,
  // sameCardError,
  setSameCardError,
  formData,
  setFormData,
}) {
  const { token } = useCustomer();
  const navigate = useNavigate();
  const crypto = window.crypto || window.msCrypto;

  const [state, setState] = useState({
    account_for,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDrawerClose = () => {
    setState(null);
    setFormData({
      name: `${customer?.firstName} ${customer?.lastName}`,
      number: "",
      expiry: "",
      cvc: "",
      zip: "",
    });
    if (closeDrawer) {
      closeDrawer(true);
    }
  };
  const [number, setNumber] = useState("");
  const checkIfSameCard = (e) => {
    let { name, value } = e.target;
    if (name === "number") {
      value = formatCreditCardNumber(value);
      setNumber(value);
      let cardNumber = number?.replace(/\D/g, "");
      customer?.card?.map((item, index) => {
        if (item?.cardNumber === cardNumber) {
          setSameCardError("Card Already exists");
        }
      });
    }
  };
  const handleChange = (e) => {
    setError("");
    // setSameCardError(null);
    let { name, value } = e.target;
    if (name === "number") {
      value = formatCreditCardNumber(value);
      setNumber(value);
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "cvc") {
      value = formatCVC(value);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSaveCard = () => {
    if (isEmpty(formData?.number)) {
      setError("Card number must not be empty");
    } else if (isEmpty(formData?.cvc)) {
      setError("Card cvv/cvc must not be empty");
    } else if (isEmpty(formData?.expiry)) {
      setError("Card expiry must not be empty");
    } else if (isEmpty(formData?.zip)) {
      setError("Postal code must not be empty");
    } else {
      const cardDetails = {
        cardNumber: formData.number?.replace(/\D/g, ""),
        cvv: formData.cvc,
        expiry: formData?.expiry,
        name: formData?.name,
        zip: formData.zip,
      };
      setLoading(true);
      encryptData(cardDetails, customer?.CustomerKey?.publicKey)
        .then((result) => {
          Axios(token)
            .post(`/add-card/${customer?.customer_external_id}`, {
              cardToken: result,
            })
            .then((res) => {
              setLoading(false);
              onSubmit(true);
              navigate("/add-funding-method");
            })
            .catch((e) => {
              setLoading(false);
              setError(
                e?.data?.response?.message ||
                  e?.message ||
                  "Something went wrong.",
              );
            });
        })
        .catch((e) => {
          setError(
            e?.data?.response?.message || e?.message || "Something went wrong.",
          );
        });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Drawer
        anchor={"bottom"}
        open={onOpen}
        // onClose={handleDrawerClose}
        className="grayBg"
      >
        <IconButton
          color="primary"
          onClick={handleDrawerClose}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Container sx={{ pb: 2, pt: 5 }}>
          <Typography variant="h4">Add new Card</Typography>
          <>
            <TextField
              fullWidth
              label="Card Holder Name"
              margin="dense"
              onChange={handleChange}
              onBlur={handleChange}
              name="name"
              value={`${customer?.firstName} ${customer?.lastName}`}
              disabled={true}
            />
            <TextField
              label="Card number"
              fullWidth
              margin="dense"
              onChange={handleChange}
              onBlur={checkIfSameCard}
              type="text"
              name="number"
              placeholder="Enter 16 digit card number"
              value={formData.number}
            />
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name="expiry"
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="MM/YY"
                  margin="dense"
                  label="Expiry"
                  value={formData.expiry}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="cvc"
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="CVV"
                  type="password"
                  margin="dense"
                  label="CVV"
                  value={formData.cvc}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="zip"
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="ZIP Code"
                  margin="dense"
                  label="ZIP Code"
                  value={formData.zip}
                />
              </Grid>
            </Grid>
          </>
          {error && (
            <Typography variant="body1" color="error" className={`!my-2`}>
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveCard}
            disabled={loading}
            className={`!mt-4`}
          >
            Link Card
            {loading && <CircularProgress className={`ml-2`} size={20} />}
          </Button>
        </Container>
      </Drawer>
    </>
  );
}
export default NewCardDrawer;
