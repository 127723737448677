/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import OtpInput from "react-otp-input";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

function PinComponent({
  openPinDrawer,
  setPin,
  pin,
  setConfirmPin,
  setConfirm,
  confirm,
  confirmPin,
  error,
  setError,
  handleSetPin,
  handlePinDrawerClose,
}) {
  return (
    <>
      <Drawer anchor="bottom" open={openPinDrawer}>
        <IconButton
          color="primary"
          onClick={() => {
            handlePinDrawerClose();
          }}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box component={"div"} px={2} py={5}>
          <Typography variant="h3">PIN (6 Digits)</Typography>
          <Typography variant="h5" gutterBottom>
            Enter PIN
          </Typography>
          <OtpInput
            value={pin}
            onChange={setPin}
            numInputs={6}
            renderInput={(props) => <input {...props} type={"password"} />}
            containerStyle={{
              justifyContent: "space-between",
            }}
            inputStyle={{
              backgroundColor: "#F7F7F7",
              width: "3.3rem",
              height: "3.3rem",
              fontWeight: 700,
              fontSize: "1rem",
              borderRadius: "0.5rem",
              border: "1px solid rgba(0,104,255,.15)",
            }}
            inputType={"number"}
          />
          <Box component={"div"} className="clear20" />
          {error && (
            <>
              {/* <p className={"text-red-600"}>{error}</p> */}
              <Typography
                variant="body2"
                color={"error"}
                fontWeight={700}
                textAlign={"center"}
              >
                {error}
              </Typography>
            </>
          )}
          <Typography variant="h5" gutterBottom>
            Confirm PIN
          </Typography>
          <OtpInput
            value={confirmPin}
            onChange={setConfirmPin}
            // onChange={(e) => console.log(e)}
            numInputs={6}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} type={"password"} />}
            containerStyle={{
              justifyContent: "space-between",
            }}
            inputStyle={{
              backgroundColor: "#F7F7F7",
              width: "3.3rem",
              height: "3.3rem",
              fontWeight: 700,
              fontSize: "1rem",
              borderRadius: "0.5rem",
              border: "1px solid rgba(0,104,255,.15)",
            }}
            inputType={"number"}
          />
          <Stack direction={"row"} spacing={1} mt={3}>
            <InfoOutlinedIcon fontSize="small" />
            <Typography variant="body2" mb={0}>
              PIN will keep your account secure from unauthorized access. Do not
              share this PIN with anyone.
            </Typography>
          </Stack>
          <Box component={"div"} className="clear20" />
          {confirm ? (
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                // onClick={handleSetPin}
                onClick={() => {
                  setPin("");
                  setConfirmPin("");
                  setError("");
                  setConfirm(false);
                }}
                fullWidth={true}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSetPin}
                // onClick={handleSubmit}
                fullWidth={true}
              >
                Continue
              </Button>
            </Stack>
          ) : (
            <>
              <Stack direction={"row"} spacing={2}>
                {/*{isDisableButton && (*/}
                {/*  <Button*/}
                {/*    variant="outlined"*/}
                {/*    color="primary"*/}
                {/*    onClick={handleDisablePin}*/}
                {/*    fullWidth*/}
                {/*    size={"small"}*/}
                {/*    // onClick={handleSetPin}*/}
                {/*    // onClick={handleSubmit}*/}
                {/*  >*/}
                {/*    Disable*/}
                {/*  </Button>*/}
                {/*)}*/}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (!pin) {
                      setError("PIN can not be empty");
                    } else if (pin.length < 6) {
                      setError("PIN can not be less than 6 digits");
                    } else {
                      setError("");
                      handleSetPin();
                      // setConfirm(true);
                    }
                  }}
                  // onClick={handleSetPin}
                  fullWidth
                  // onClick={handleSetPin}
                  // onClick={handleSubmit}
                >
                  SAVE & CONTINUE
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default PinComponent;
