/* eslint-disable no-unused-vars */
import React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  // IconButton,
  Stack,
  Typography,
} from "@mui/material";
// import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useNavigate } from "react-router-dom";

function FirstTime2Fa({
  firstTime2fa,
  setFirstTime2fa,
  setShowModal,
  setOpenPinDrawer,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Drawer
        anchor="bottom"
        open={firstTime2fa}
      >
        <Box>
          <Typography variant="h3" mb={0}>Two Factor Authentication</Typography>
          <Divider sx={{my:2}}/>
          <Typography variant="Body2">
            Would you like to enable two factor authentication via your phone in
            order to secure your account?
          </Typography>
          <Stack direction={"row"} spacing={2} mt={3}>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={() => {
                setFirstTime2fa(false);
                setShowModal(true);
              }}
              size={"small"}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              // onClick={() => setOpenPinDrawer(true)}
              onClick={() => navigate("/confirmation")}
              size={"small"}
            >
              YES
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export default FirstTime2Fa;
