import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function P2PCancelModal({
  isModalOpen,
  message,
  type,
  modalCallBack,
  showOk,
  showCancel,
  cancelBtnText,
  showOkText,
  // CloseOnClick,
  headLine,
  confirmHandler,
  confirmButtonCallback,
  // showTransition,
  cancelHandler,
}) {
  const [showModal, setShowModal] = useState(isModalOpen);
  useEffect(() => {
    if (isModalOpen) {
      setShowModal(true);
    }
  }, [isModalOpen]);
  const modalIsClose = () => {
    setShowModal(false);
    if (modalCallBack) {
      modalCallBack(false);
    }
  };
  // const CloseOnClicks = () => {
  //   cancelHandler();
  //   setShowModal(false);
  //   if (CloseOnClicks) {
  //     CloseOnClick(false);
  //   }
  // };
  const handleClose = () => {
    cancelHandler();
    setShowModal(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      open={showModal}
    >
      <DialogContent
        dividers
        className={`relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none`}
      >
        <div className="">
          <div className="relative flex-auto px-6 pt-8">
            <div
              className={type === "Error" ? "text-red-600" : "text-green-600"}
            >
              {type === "Error" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-16 h-16 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-16 h-16 mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
              )}
            </div>
            <h5 className="mt-3 text-2xl font-semibold leading-relaxed text-center text-blueGray-500">
              {headLine || type}
            </h5>
            {message && (
              <p className="mt-2 text-sm leading-relaxed text-center text-gray-500 text-blueGray-500">
                {message}
              </p>
            )}
          </div>
          <div className="flex items-center justify-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
            {showCancel && (
              <button
                className="px-5 py-2 bg-red-500 text-base font-medium text-white transition-all duration-150 ease-linear rounded outline-none focus:outline-none"
                type="button"
                onClick={handleClose}
              >
                {" "}
                {cancelBtnText ? cancelBtnText : "Reject"}
              </button>
            )}
            {confirmButtonCallback && (
              <button
                // className="px-4 md:px-6 py-2 md:py-2 mb-1 mr-1 text-sm font-medium text-white transition-all duration-150 ease-linear bg-green-500 rounded outline-none focus:outline-none"
                className="px-5 ml-2 py-2 bg-green-500 text-base font-medium text-white transition-all duration-150 ease-linear rounded outline-none focus:outline-none"
                type="button"
                onClick={confirmHandler}
              >
                {" "}
                {"Confirm"}
              </button>
            )}

            {showOk && (
              <button
                className="px-4 md:px-6 py-2 md:py-2 mb-1 mr-1 text-sm font-medium text-white transition-all duration-150 ease-linear bg-green-500 rounded outline-none focus:outline-none"
                type="button"
                onClick={modalIsClose}
              >
                {" "}
                {showOkText ? showOkText : "OK"}
              </button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
