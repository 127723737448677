import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Stack,
  Box,
  Container,
  Card,
  CardContent,
  SvgIcon,
} from "@mui/material";
import BottomNav from "../components/bottomNav";
import HeaderFull from "../components/headerFull";
import { useCustomer } from "../context/customerContext";
import { currencyFormat } from "../utils/Common";
import Loader from "../components/Loader";
import moment from "moment-timezone";
import LayoutStyle from "../scss/base.module.scss";
import RequestIcon from "../Assets/Icons/requestIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getWithdrawalServices } from "../services/WithdrawalServices";
import { checkLogin } from "../components/helpers/checkLogin";

function WithdrawalRequestList({ customer }) {
  const navigate = useNavigate();
  const { token, setCurrentRequest } = useCustomer();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const Payment = (id) => {
    setCurrentRequest(id);
    navigate(`/goods-services/${id}`);
  };
  useEffect(() => {
    checkLogin(customer, navigate);
    if (customer?.customer_external_id) {
      setLoading(true);
      getWithdrawalServices(customer?.customer_external_id, token)
        .then((res) => {
          setLoading(false);
          setRequests(res?.contactLess || []);
        })
        .catch(() => setLoading(false));
    }
  }, [token]);
  return (
    <>
      {loading && <Loader show={loading} />}
      <HeaderFull />
      <Box
        component={"main"}
        className={`${LayoutStyle.contentPart} ${LayoutStyle.bodyContainer}`}
      >
        <Container>
          <Typography variant="h4" gutterBottom>
            Withdrawal requests
          </Typography>
          <ul className={LayoutStyle.requestList}>
            {requests?.length === 0 && (
              <Typography
                variant="h3"
                className={`!mt-10`}
                textAlign={"center"}
              >
                No requests found
              </Typography>
            )}
            {requests &&
              requests?.map((item, index) => (
                <li key={index}>
                  <Card
                    elevation={3}
                    sx={{ borderRadius: "0.9375rem!important" }}
                  >
                    <CardContent sx={{ pr: "2.5rem!important" }}>
                      <Stack spacing={3}>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Box className={`${LayoutStyle.requestIcon}`}>
                            <SvgIcon
                              viewBox="0 0 22 22"
                              sx={{ width: "1.25rem", height: "1.25rem" }}
                            >
                              <RequestIcon />
                            </SvgIcon>
                          </Box>
                          <Typography variant="h4" m={0}>
                            New Contactless Request
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"flex-end"}
                          justifyContent={"space-between"}
                          spacing={1}
                          onClick={() => Payment(item?.contactLess_external_id)}
                        >
                          <Box>
                            <Typography variant="h4" m={0} fontWeight={500}>
                              {item?.Store?.account_number || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {item?.Store?.city || ""} |{" "}
                              {item?.Store?.state || ""}
                            </Typography>
                            <Typography
                              variant="h6"
                              m={0}
                              sx={{ opacity: 0.5 }}
                            >
                              {moment(item?.createdAt || new Date()).format(
                                "ll",
                              )}
                            </Typography>
                          </Box>
                          <Box className={LayoutStyle.requestPrice}>
                            {currencyFormat(item?.amount || 0)}
                          </Box>
                        </Stack>
                      </Stack>
                      <Box
                        component={"div"}
                        className={`${LayoutStyle.requestPriceArrow}`}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </Box>
                    </CardContent>
                  </Card>
                </li>
              ))}
          </ul>
        </Container>
      </Box>
      <BottomNav />
    </>
  );
}
export default WithdrawalRequestList;
