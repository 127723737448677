import { toNumber } from "lodash";
import Config from "../config/index.json";

export const currencyFormat = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      "$" +
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")

    .replace(/^-+/, "")
    .replace(/-+$/, "");
};

export const filterAmount = (n, isTips, amountLimits = 1000) => {
  let result = 0;
  let number = ("" + n).slice(0, 6);
  if (toNumber(number)) {
    result = toNumber(number) / 100;
    if (isTips) {
      result = number / 10;
    }
    if (number.indexOf(".") === -1) {
      if (toNumber(number) <= 1000 && !isTips) {
        return toNumber(number);
      } else if (toNumber(number) <= toNumber(amountLimits) && !isTips) {
        return toNumber(number);
      } else if (toNumber(number) <= 100 && isTips) {
        return toNumber(number);
      } else {
        return result;
      }
    } else {
      return toNumber(number);
    }
  }
  return "";
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const toFixed = (decimalNumber) => {
  if (toNumber(decimalNumber || 0) % 1 === 0) {
    return decimalNumber + ".00";
  } else {
    return Math.floor(toNumber(decimalNumber || 0) * 100) / 100;
  }
};

export const getAPIUrl = () => {
  try {
    let URL;
    const hostname = window.location.hostname;
    if (hostname === "localhost") {
      URL = `${Config.LocalURL}/api/v2`;
    } else if (hostname === "192.168.1.101" || hostname === "192.168.0.120") {
      URL = `${Config.twisamLocal}/api/v2`;
    } else if (
      hostname === "www.gainfunds.com" ||
      hostname === "gainfunds.com"
    ) {
      URL = `${Config.ServerURL}/api/v2`;
    } else if (hostname === "dev-customer.gainfunds.com") {
      URL = `${Config.DevelopmentURL}/api/v2`;
    } else if (hostname === "mirror-customer.gainfunds.com") {
      URL = `${Config.MirrorURL}/api/v2`;
    } else if (hostname === "staging-customer.gainfunds.com") {
      URL = `${Config.StageURL}/api/v2`;
    } else if (hostname === "sandbox-customer.gainfunds.com") {
      URL = `${Config.SandboxURL}/api/v2`;
    } else {
      throw new Error(
        `Unsupported environment, hostname = '${hostname}', place = 'App'`,
      );
    }
    return URL;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const excludeOnlyMobilePath = (path) => {
  const excludedPath = [
    "/",
    "/receipt",
    "/receipt",
    "/terms-conditions",
    "/privacy-policy",
    "/contact",
    "/contact-new",
  ];
  return excludedPath?.includes(path);
};

export const roundUp = (value, decimalPlaces = 2) =>
  toNumber(
    Number(
      `${Math.round(
        parseFloat(`${value || 0}e${decimalPlaces}`),
      )}e-${decimalPlaces}`,
    ).toFixed(decimalPlaces),
  );

export const QRURL = (url) => {
  if (url?.includes("mirror")) {
    return "https://mirror-customer.gainfunds.com";
  }
  if (url?.includes("localhost")) {
    return "http://localhost:3331";
  }
  return "https://gainfunds.com";
};

export function cleanCustomerIDNumber(IdNumber) {
  return IdNumber.replace(/[^A-Z0-9-]/g, "");
}

export function onlyNumber(input) {
  if (!input) {
    return "";
  }
  let sanitizedInput = input?.toString()?.replace(/[^0-9.]/g, "");
  let parts = sanitizedInput.split(".");
  let integerPart = parts[0];
  let decimalPart = parts[1] ? parts[1].slice(0, 2) : "";
  let validNumberStr = integerPart + (decimalPart ? "." + decimalPart : "");
  const regex = /^\d+(\.\d{1,2})?$/;
  if (regex.test(validNumberStr)) {
    return parseFloat(validNumberStr);
  } else {
    return "";
  }
}

export const handleShare = async (customer, qrCodeData) => {
  try {
    if (navigator.share) {
      // Convert the base64 image to a Blob
      const response = await fetch(qrCodeData);
      const blob = await response.blob();

      // Create a File object from the Blob
      const file = new File([blob], "qr-code.png", { type: "image/png" });

      await navigator.share({
        files: [file],
        title: `Scan QR Code to transfer money to ${customer?.firstName} ${customer?.lastName}`,
        text: "Here's my QR code for money transfer",
      });
      return true;
    } else {
      return "Share is not supported in this browser. Please download the QR code and share it.";
    }
  } catch (error) {
    console.error("Error sharing:", error);
    return error.message;
  }
};
