import React from "react";
import { toNumber } from "lodash";
import { Badge, Box, SvgIcon } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import bottomNavStyle from "../scss/bottomNavStyle.module.scss";
import HomeIcon from "../Assets/Icons/homeIcon";
import DepositsIcon from "../Assets/Icons/depositsIcon";
import WithdrawalIcon from "../Assets/Icons/withdrawalIcon";
import PayIcon from "../Assets/Icons/payIcon";
import ListEditIcon from "../Assets/Icons/listEditIcon";
import { useCustomer } from "../context/customerContext";

export default function BottomNav(props) {
  const { notifications } = useCustomer();
  const navOptions = [
    {
      lable: "Home",
      classNames: "bottomNavBox",
      url: "/dashboard",
      icon: <HomeIcon />,
    },
    {
      lable: "Requests",
      classNames: `${toNumber(notifications) > 0 ? "Badge" : "bottomNavBox"}`,
      url: "/requests",
      icon: (
        <SvgIcon viewBox="0 0 512 512">
          <ListEditIcon />
        </SvgIcon>
      ),
      guidanceClass: "bottomContactless",
    },
    {
      lable: "Pay",
      classNames: "bottomNavRound",
      url: "/goods-services",
      icon: (
        <SvgIcon
          viewBox="0 0 468 468"
          sx={{ width: "1.5rem!important", height: "1.5rem!important" }}
        >
          <PayIcon />
        </SvgIcon>
      ),
    },
    {
      lable: "Deposits",
      classNames: "bottomNavBox",
      url: "/deposit-funds",
      icon: <DepositsIcon />,
      guidanceClass: "bottomDeposit",
    },
    {
      lable: "Withdrawal",
      classNames: "bottomNavBox",
      url: "/withdrawal",
      icon: <WithdrawalIcon />,
      guidanceClass: "bottomWithdrawal",
    },
  ];
  const location = useLocation();
  return (
    <>
      <Box className={`${bottomNavStyle.bottomNav}`}>
        {navOptions.map((nav) => (
          <Link
            key={nav.lable}
            className={` ${nav.guidanceClass || ""} ${
              nav.classNames === "bottomNavRound"
                ? props.payRound
                  ? `${bottomNavStyle.bottomNavRound}`
                  : location.pathname === nav.url
                  ? `${bottomNavStyle.bottomNavBoxActive} ${bottomNavStyle.bottomNavBox}`
                  : bottomNavStyle.bottomNavBox
                : location.pathname === nav.url
                ? `${bottomNavStyle.bottomNavBoxActive} ${bottomNavStyle.bottomNavBox}`
                : bottomNavStyle.bottomNavBox
            }`}
            to={nav.url}
          >
            {nav.classNames === "bottomNavRound" ? (
              !props.payRound && (
                <Box className={`${bottomNavStyle.bottomNavIcon}`}>
                  {nav.icon}
                </Box>
              )
            ) : nav.classNames === "Badge" ? (
              <Badge
                badgeContent={toNumber(notifications)}
                max={9}
                color="error"
              >
                <Box className={`${bottomNavStyle.bottomNavIcon}`}>
                  <SvgIcon viewBox="0 0 20 20">{nav.icon}</SvgIcon>
                </Box>
              </Badge>
            ) : (
              <Box className={`${bottomNavStyle.bottomNavIcon}`}>
                <SvgIcon viewBox="0 0 20 20">{nav.icon}</SvgIcon>
              </Box>
            )}
            <Box className={`${bottomNavStyle.bottomNavText} `}>
              {nav.lable}
            </Box>
          </Link>
        ))}
      </Box>
    </>
  );
}
