import React from "react";
import { Link } from "react-router-dom";
import  FooterStyle  from '../scss/footer.module.scss';

export default function Footer() {

  return (
    <>
      <footer className={`${FooterStyle.footerApp}`}>
        Powered by
        <a
          href="https://gainfcu.com/"
          target={`_blank`}
        >
          Gain Federal Credit Union
        </a>
        <br />
        <Link className={` `} to={"/terms-conditions"}>
          Terms & Conditions
        </Link>
        &
        <Link className={` `} to={"/privacy-policy"}>
          Privacy Policy
        </Link>
      </footer>
    </>
  );
}
