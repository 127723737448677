/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useCustomer } from "../context/customerContext";
import { useNavigate } from "react-router-dom";
// import Axios from "../config/Axios";
// import { useCustomer } from "../context/customerContext";
// import Loader from "./Loader";

function ContactLessReqModal({ openCLModal, setOpenCLModal }) {
  const { contactLessReqDetails } = useCustomer();
  const [isRequestIdCopied, setRequestIdCopied] = useState(false);
  const navigate = useNavigate();
  // const [loading, setLoading] = React.useState(false);

  const copyRequestId = () => {
    navigator.clipboard.writeText(
      contactLessReqDetails[0]?.contactLess_external_id,
    );
    setRequestIdCopied(true);
    setTimeout(() => setRequestIdCopied(false), 1000);
  };

  return (
    <>
      <Dialog open={openCLModal}>
        {/*{loading && <Loader show={loading} />}*/}
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="text-sm">Transfer request</p>
            <button
              onClick={() => {
                // setRequestIdCopied("");
                // setModalOpen(false);
                localStorage.setItem("isContactLessModalOpened", true);
                setOpenCLModal(false);
              }}
              className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 transition-colors flex items-center justify-center"
              aria-label="Close"
            >
              <CloseIcon
                sx={{
                  fontSize: "1rem",
                  color: "rgb(75, 85, 99)", // Tailwind's gray-600
                }}
              />
            </button>
          </div>

          <div
            className={
              "flex justify-between items-center mt-3 bg-gray-200 rounded-md p-2"
            }
          >
            <div className={"flex space-x-2 items-center"}>
              <AccountBoxIcon
                sx={{
                  fontSize: "2.5rem",
                }}
              />
              <div className={"flex-col "}>
                <p className={"text-sm font-semibold"}>
                  {/*{p2pDetails?.receiver_name || "N/A"}*/}
                  {/*N/A*/}
                  {contactLessReqDetails[0]?.Store?.account_number || "N/A"}
                </p>
                <p className={"text-xs"}>
                  {/*{p2pDetails?.receiver_name || "N/A"}*/}
                  {/*N/A*/}
                  {contactLessReqDetails[0]?.Store?.city || "N/A"}
                </p>
              </div>
            </div>
            <div>
              {/*<p>${p2pDetails?.amount}</p>*/}
              {`$${contactLessReqDetails[0]?.amount || "0.0"}`}
            </div>
          </div>
          <div className={"flex mt-3 justify-between items-center"}>
            <div className={"flex space-x-3 items-center"}>
              <ListAltIcon />
              <p className={"text-sm"}>Request details</p>
            </div>
          </div>
          <div className={"flex mt-3 justify-between items-center"}>
            <div className={"flex-col"}>
              <p className={"text-xs"}>Request ID</p>
              <p className={"text-xs"}>
                {contactLessReqDetails[0]?.contactLess_external_id || "N/A"}
                {isRequestIdCopied && <p className="text-green-500">Copied!</p>}
              </p>
            </div>
            <div onClick={copyRequestId}>
              <ContentCopyIcon sx={{ fontSize: "1rem" }} />
            </div>
          </div>
          <div className={"flex pb-2 w-full space-x-4 mt-3"}>
            <button
              className={"bg-blue-950 text-white p-2 rounded-[0.60rem] w-full"}
              onClick={() => {
                localStorage.setItem("isContactLessModalOpened", true);

                setOpenCLModal(false);
                navigate("/requests");
              }}
            >
              View Request
            </button>
            {/*<button*/}
            {/*  className={"bg-gray-500 text-white p-2 rounded-[0.60rem] w-1/2"}*/}
            {/*  // onClick={invalidateRequest}*/}
            {/*>*/}
            {/*  Invalidate*/}
            {/*</button>*/}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContactLessReqModal;
