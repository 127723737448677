import {Box, Button, Container, Dialog, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "react-otp-input";
import React, {useState} from "react";
import Axios from "../config/Axios";
import Loader from "./Loader";

export default function VerifyPINDrawer({token, showPinDrawer, onSubmit, onClose}){
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const verifyPin = () => {
    setLoading(true);
    Axios(token)
      .post("/verifyPin", {
        pin,
      })
      .then((res) => {
        setLoading(false);
        setPin("");
        setError("");
        if (onSubmit){
          onSubmit(res?.data?.response);
        }

      })
      .catch((e) => {
        setPin("");
        setLoading(false);
        setError(e?.response?.data?.message);
      });
  };


  return (
    <>
      {loading && <Loader show={loading} />}
      {(showPinDrawer) && (
        <Dialog open={showPinDrawer}>
          <IconButton
            color="primary"
            onClick={() => {
              onClose(false);
              setPin("");
              setError("");
            }}
            aria-label="close"
            size="small"
            sx={{
              position: "absolute",
              right: 5,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box component={"div"} py={5}>
            <Container>
              <Typography variant="h3">Enter PIN</Typography>
              <Box>
                <OtpInput
                  value={pin}
                  onChange={setPin}
                  numInputs={6}
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input {...props} type={"password"} />
                  )}
                  containerStyle={{
                    justifyContent: "space-between",
                  }}
                  inputStyle={{
                    backgroundColor: "#F7F7F7",

                    width: "3rem",
                    height: "3.375rem",
                    fontWeight: 700,
                    fontSize: "1rem",
                    borderRadius: "0.5rem",
                    border: "1px solid rgba(0,104,255,.15)",
                  }}
                  inputType={"number"}
                />
                <Box className="clear20" />
                {error && (
                  <Typography variant="body2" color={"error"}>
                    {error}
                  </Typography>
                )}
              </Box>
              <Box component={"div"} className="clear10" />
              <Button
                variant="contained"
                color="primary"
                onClick={verifyPin}
                fullWidth
                // onClick={enable2fa}
              >
                Continue
              </Button>
            </Container>
          </Box>
        </Dialog>
      )}
    </>
  )
}
