/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useCustomer } from "../context/customerContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import CardIcon from "../svg/card";
import HelpIcon from "../svg/help";
import SecurityIcon from "../svg/security";
import LogoutIcon from "../svg/logout";
import GainLogo from "../svg/Gainlogo";
import HeaderStyle from "../scss/header.module.scss";
import QrCodeIcon from "../Assets/Icons/qrCodeIcon";
import { getCustomerInfoServices } from "../services/CustomerServices";
import DepositsIcon from "../Assets/Icons/depositsIcon";
import ListIcon from "../Assets/Icons/listIcon";
import WithdrawalIcon from "../Assets/Icons/withdrawalIcon";
import { getRequestsServices } from "../services/ActivitiesServices";
import ContactLessReqModal from "./contactLessRequestModal";
import pusherSub from "../utils/Pusher";

export default function HeaderFull({ classes = "" }) {
  const [open, setOpen] = useState(false);
  const isContactLessModalOpened = localStorage.getItem(
    "isContactLessModalOpened",
  );
  const toggleDrawer = (val) => () => {
    setOpen(val);
  };

  const { pathname } = useLocation();
  const router = useNavigate();
  const {
    customer,
    load,
    logout,
    setCustomer,
    token,
    contactLessReqDetails,
    setContactLessReqDetails,
    setNotifications,
  } = useCustomer();
  const [name, setName] = React.useState("");
  const [requests, setRequests] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [openCLModal, setOpenCLModal] = useState(false);

  const navigate = useNavigate();
  const LoadFund = () => {
    navigate("/deposit-funds");
  };
  const Withdrawal = () => {
    navigate("/withdrawal");
  };
  useEffect(() => {
    if (customer?.customer_external_id) {
      getCustomerInfoServices(customer?.customer_external_id)
        .then((res) => {
          setLoading(false);
          if (res) {
            setCustomer((prev) => ({ ...prev, ...res }));
          }
        })
        .then(() => setLoading(false));
    }
  }, [load, open]);

  useEffect(() => {
    if (customer?.customer_external_id) {
      let splitFullName = customer?.fullName?.split(" ");
      let firstChar =
        customer?.firstName?.substring(0, 1) ||
        splitFullName[0]?.substring(0, 1);
      let lastChar =
        customer?.lastName?.substring(0, 1) ||
        splitFullName[splitFullName?.length - 1]?.substring(0, 1);
      let fullName = firstChar + lastChar;
      setName(fullName.toUpperCase());
    }
  }, [customer]);
  useEffect(() => {
    // if (!isContactLessModalOpened) {
    getRequestsServices(customer?.customer_external_id, token)
      .then((res) => {
        // console.log(res?.contactLess?.length);
        if (res?.contactLess?.length > 0) {
          setNotifications(res?.contactLess?.length);
          setContactLessReqDetails(res?.contactLess);
          if (!isContactLessModalOpened) {
            setOpenCLModal(true);
          }
        }
      })
      .then((e) => {
        console.log(e);
      });
    // }
  }, [isContactLessModalOpened]);

  useEffect(() => {
    if (customer?.phone) {
      pusherSub(customer?.phone, "contactless", () => {
        // console.log("insider pusher", customer?.phone);
        getRequestsServices(customer?.customer_external_id, token)
          .then((res) => {
            // console.log(res);
            setContactLessReqDetails(res?.contactLess);
            setNotifications(res?.contactLess?.length);
            setOpenCLModal(true);
            // localStorage.setItem("isContactLessModalOpened", "false");
            // if (!isContactLessModalOpened) {
            //   setOpenCLModal(true);
            // }
          })
          .then((e) => {
            console.log(e);
          });
      });
    }
  }, []);

  // console.log(customer?.phone);

  return (
    <>
      {(load || loading) && <Loader show={load || loading} />}
      <header className={classes}>
        <Box className={`${HeaderStyle.brand}`}>
          <Link to={`/dashboard`}>
            <GainLogo />
          </Link>
        </Box>
        <Stack direction={"row"} gap={1}>
          <Link to="/qr" className="QR">
            <Avatar className={HeaderStyle.iconBtn}>
              <SvgIcon viewBox="0 0 22 22">
                <QrCodeIcon />
              </SvgIcon>
            </Avatar>
          </Link>
          {name ? (
            <Link
              onClick={
                ((pathname === "/dashboard" ||
                  pathname === "/deposit-funds" ||
                  pathname === "/requests") &&
                  requests) > 0
                  ? ""
                  : toggleDrawer(true)
              }
            >
              <Avatar className={HeaderStyle.iconBtn}>{name}</Avatar>
            </Link>
          ) : (
            <Skeleton variant="circular" sx={{ bgcolor: "white.400" }}>
              <Avatar color="primary" />
            </Skeleton>
          )}
        </Stack>
      </header>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className="rightNav"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={"auto"}>
            <Avatar color="primary">{name}</Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h3" gutterBottom>
              {/* {customer?.fullName} */}
              <>
                Hi, {customer?.firstName.slice(0, 1)}
                <Box component={"span"} textTransform={"lowercase"}>
                  {customer?.firstName.slice(1)}
                </Box>
              </>
            </Typography>
            <Typography variant="h6" className="!mb-0">
              {customer?.phone}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 1 }} />
        <Typography variant="h5" textAlign={"center"} gutterBottom>
          Available balance is
        </Typography>
        <Typography
          variant="h2"
          textAlign={"center"}
          mb={0}
          color={"secondary"}
        >
          {`$${customer?.amount}`}
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Box className="sideMenu">
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton onClick={() => router("/activity")}>
              <ListItemIcon>
                <SvgIcon sx={{ width: "1.25rem", height: "1.25rem" }}>
                  <ListIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItemButton>
            <Divider />

            <ListItemButton onClick={LoadFund}>
              <ListItemIcon>
                <SvgIcon viewBox="0 0 22 22" sx={{ width: "1.25rem" }}>
                  <DepositsIcon fill="black" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Deposits" />
            </ListItemButton>
            <Divider />

            <ListItemButton onClick={Withdrawal}>
              <ListItemIcon>
                <SvgIcon viewBox="0 0 22 22" sx={{ width: "1.25rem" }}>
                  <WithdrawalIcon fill="black" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Withdrawal" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                router("/transfer-money", { state: "Request Money" });
              }}
            >
              <ListItemIcon>
                <SvgIcon viewBox="0 0 22 22" sx={{ width: "1.25rem" }}>
                  <WithdrawalIcon fill="black" />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Request Money" />
            </ListItemButton>
            <Divider />

            <ListItemButton onClick={() => router("/qr")}>
              <ListItemIcon>
                <SvgIcon viewBox="0 0 22 22" sx={{ width: "1.25rem" }}>
                  <QrCodeIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Pay with QR" />
            </ListItemButton>
            <Divider />

            <ListItemButton
              onClick={() => {
                setOpen(false);
                customer?.enable_2fa
                  ? router("/two-factor")
                  : router("/confirmation");
              }}
            >
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary="Security" />
            </ListItemButton>
            <Divider />

            <ListItemButton disabled>
              <ListItemIcon>
                <CardIcon />
              </ListItemIcon>
              <ListItemText primary="Loyalty card" />
            </ListItemButton>
            <Divider />
            <ListItemButton disabled>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Get Help" />
            </ListItemButton>
            <Divider />

            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      {openCLModal && (
        <ContactLessReqModal
          openCLModal={openCLModal}
          setOpenCLModal={setOpenCLModal}
        />
      )}
    </>
  );
}
