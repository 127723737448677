import React, { createContext, useContext, useEffect, useState } from "react";
import Axios from "../config/Axios";
import { getCustomerInfoServices } from "../services/CustomerServices";

const CustomerContext = createContext(null);
export function CustomerContextProvider({ children }) {
  const [customer, setCustomer] = useState(null);
  const [token, setToken] = useState(null);
  const [load, setload] = useState(true);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [loadFunActivities, setLoadFunActivities] = useState(null);
  const [invoiceData, setInvoiceData] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const [sigData, setSigData] = useState();
  const [amountArr, setAmountArr] = useState();
  const [sendOtp, setSendOtp] = useState(false);
  const [contactLessReqDetails, setContactLessReqDetails] = useState("");
  const [notifications, setNotifications] = useState("");
  // const router = useNavigate();
  const handleRefresh = async () => {
    try {
      const response = await Axios().post("/handle-refresh");
      setToken(response?.data?.response?.token);
      const customer = response?.data?.response?.response;
      setCustomer(customer);
      setload(false);
      return customer;
    } catch (error) {
      setload(false);
      if (
        error?.response?.data?.response?.message === "Forbidden" ||
        error?.response?.data?.response?.message === "Unauthorized" ||
        error?.response?.data?.response?.message === "Token Expired" ||
        error?.response?.data?.response?.message === "Token Invalid" ||
        error?.response?.data?.response?.message === "Token Blacklisted" ||
        error?.response?.data?.response?.message === "Token Revoked" ||
        error?.response?.data?.response?.message ===
          "Token has expired and can no longer be refreshed" ||
        error?.response?.data?.response?.message ===
          "The token has been blacklisted" ||
        error?.response?.data?.response?.message ===
          "The token has been revoked"
      ) {
        setToken(null);
        setCustomer(null);
        setIsInvalid(true);
      }
      return error;
    }
  };
  const logout = () => {
    Axios()
      .post("/logout")
      .then(() => {
        setToken(null);
        setCustomer(null);
        localStorage.removeItem("isContactLessModalOpened");
        window.location.href = "/";
      })
      .catch(() => console.log("error"));
  };

  const getCustomer = async (customerId) =>
    await getCustomerInfoServices(customerId);
  useEffect(() => {
    handleRefresh();
  }, []);

  const value = {
    token,
    setToken,
    customer,
    setCustomer,
    logout,
    currentRequest,
    setCurrentRequest,
    loadFunActivities,
    setLoadFunActivities,
    invoiceData,
    setInvoiceData,
    getCustomer,
    load,
    isInvalid,
    sigData,
    setSigData,
    handleRefresh,
    amountArr,
    setAmountArr,
    sendOtp,
    setSendOtp,
    contactLessReqDetails,
    setContactLessReqDetails,
    notifications,
    setNotifications,
  };
  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
}

export const useCustomer = () => useContext(CustomerContext);
