import React from "react";
import HeaderFull from "../components/headerFull";
import { Typography, Button } from "@mui/material";
import ConfirmPayment from "../Assets/Svgs/confirmPayment";
import westTownLogo from "../images/wtbt-logo.png";
import Footer from "../components/footer";

// eslint-disable-next-line no-unused-vars
function CompleteTransfer(props) {
  return (
    <>
      <HeaderFull />

      <div className={"bodyContent"}>
        <div className="flex flex-row justify-center">
          <Typography className="font-extrabold">
            Your available balance
          </Typography>
          <Typography variant="h4">$150.00</Typography>
        </div>
        <div className="flex justify-center">
          <div className="h-56 w-5/6 rounded-md shadow-lg shadow-slate-250">
            <div className={"flex justify-center"}>
              <div className="w-16 h-16">
                <ConfirmPayment />
              </div>
            </div>
            <Typography variant={"h3"} className={"text-center mt-2.5"}>
              Your load for $50 is successful{" "}
            </Typography>
            <div className={"flex justify-center"}>
              <img
                src={westTownLogo}
                alt={"bank-logo"}
                style={{ width: "122px", height: "19px" }}
              />
            </div>
            <div className={"p-4"}>
              <Typography>
                Your bank/card statement will display as InstantXfer
              </Typography>
            </div>
          </div>
        </div>
        <div className={"mt-8"}>
          <Typography className={"text-center"} variant={"h3"}>
            Please click below to complete your transfer
          </Typography>

          <Button variant="contained" color="primary" fullWidth>
            $165.00
          </Button>
          <div className={"clear20"}></div>
          <Typography variant={"body2"}>
            You will receive an SMS with your funding receipt to InstantXfer and can always view your history by clicking on activity{" "}
          </Typography>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CompleteTransfer;
