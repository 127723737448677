import React from "react";

export default function GainLogo() {
  return (
    <svg viewBox="0 0 538 100" fill="none">
      <path id="Layer" className="glc2" d="m9.1 87.5q8.7 9 22.9 9 14.2 0 22.9-9l-0.4-0.3 0.4 0.3q8.6-9 8.6-23.6v-14.6-0.5h-0.5-27.7-0.5v0.5 13 0.5h0.5 14.1v1.1q0 8.5-4.8 13.6-4.7 5-12.6 5-7.9 0-12.6-5-4.8-5.1-4.8-13.6v-28.8c0-5.7 1.6-10.1 4.8-13.5q4.7-5.1 12.6-5.1 7.9 0 12.6 5.1c3.2 3.4 4.8 7.8 4.8 13.5v6.3 0.5h0.5 13.1 0.5v-0.5-6.3c0-9.8-2.9-17.7-8.6-23.6q-8.7-9-22.9-9-14.2 0-22.9 9c-5.7 5.9-8.6 13.8-8.6 23.6v28.8q0 14.6 8.6 23.6z"/>
      <path id="Layer" fillRule="evenodd" className="glc2" d="m99.6 97.4q-8.1 0-14-4.2-6-4.1-9.4-11-3.2-7-3.2-15.4 0-8.6 3.7-15.6 3.8-7.1 10.8-11.1 7-4.1 16.8-4.1 9.9 0 16.7 4.1 6.8 4.1 10.3 11.2 3.6 7 3.6 15.5v29.2h-15.8v-9.8h-0.2q-1.7 3.1-4.4 5.7-2.5 2.5-6.2 4-3.6 1.5-8.7 1.5zm4.5-13.5q4.6 0 7.9-2.3 3.3-2.3 5.1-6.2 1.8-4 1.8-8.8 0-4.9-1.8-8.7-1.8-3.9-5.1-6.2-3.3-2.3-7.9-2.3-4.7 0-8.2 2.3-3.3 2.3-5.2 6.2-1.7 3.8-1.7 8.7 0 4.8 1.7 8.8 1.9 3.9 5.2 6.2 3.5 2.3 8.2 2.3zm41.1 12.1v-58.7h15.9v58.7zm7.9-65.7q-4 0-7-2.8-2.9-3-2.9-7.1 0-4 2.9-6.9 3-2.9 7-2.9 4 0 6.9 2.9 3 2.9 3 6.9 0 4.1-3 7.1-2.9 2.8-6.9 2.8zm18.1 65.7v-33.7q0-7.2 3.4-13.2 3.4-6 9.9-9.6 6.4-3.5 15.4-3.5 9.1 0 15.4 3.5 6.4 3.6 9.8 9.6 3.4 6 3.4 13.2v33.7h-15.7v-33.7q0-3.6-1.8-6.4-1.7-3-4.7-4.7-2.9-1.8-6.4-1.8-3.6 0-6.6 1.8-2.9 1.7-4.6 4.7-1.7 2.8-1.7 6.4v33.7z"/>
      <path id="Layer" fillRule="evenodd" className="glc2" d="m245.4 96v-45.2h-10.3l0.1-13.5h10.2v-8.6q0-7.8 4.4-12.2 4.4-4.4 12.3-4.5h16.6v13.5h-13.9q-1.5 0-2.6 1-1.1 1.1-1.1 2.7v8.1h15.1v13.5h-15.1v45.2zm63.7 1.4q-8.9 0-15.4-3.6-6.4-3.5-9.9-9.5-3.3-6-3.3-13.3v-33.7h15.8v33.7q0 3.6 1.7 6.6 1.7 2.9 4.6 4.6 3 1.7 6.5 1.7 3.6 0 6.5-1.7 3-1.7 4.7-4.6 1.7-3 1.7-6.6v-33.7h15.8v33.7q0 7.3-3.5 13.3-3.3 6-9.8 9.5-6.3 3.6-15.4 3.6zm39-1.4v-33.7q0-7.2 3.4-13.2 3.4-6 9.8-9.6 6.5-3.5 15.5-3.5 9 0 15.4 3.5 6.4 3.6 9.7 9.6 3.5 6 3.5 13.2v33.7h-15.8v-33.7q0-3.6-1.7-6.4-1.7-3-4.7-4.7-2.9-1.8-6.4-1.8-3.6 0-6.6 1.8-2.9 1.7-4.6 4.7-1.7 2.8-1.7 6.4v33.7zm95.1 1.4q-7.4 0-13.3-2.3-5.7-2.4-9.6-6.7-3.9-4.4-6-10.1-2.1-5.9-2.1-12.8 0-8.7 3.5-15.3 3.5-6.6 9.9-10.4 6.4-3.8 14.9-3.8 3.7 0 7 1 3.5 1 6.2 3 2.8 1.9 4.5 4.6h0.1v-32.6h15.8v53.5q0 9.7-3.8 16.9-3.8 7.2-10.7 11.1-6.9 3.9-16.4 3.9zm0-13.5q4.7 0 8-2.3 3.4-2.3 5.1-6.2 1.8-3.9 1.8-8.7 0-4.9-1.8-8.8-1.7-3.9-5.1-6.2-3.3-2.3-8-2.3-4.6 0-8.1 2.3-3.3 2.3-5.2 6.2-1.7 3.9-1.7 8.8 0 4.8 1.7 8.7 1.9 3.9 5.2 6.2 3.5 2.3 8.1 2.3zm40.9 12.1v-13.5h28.6q1.4 0 2.4-0.5 1.2-0.6 1.8-1.6 0.7-1.1 0.7-2.3 0-1.4-0.7-2.5-0.6-1-1.8-1.6-1-0.7-2.4-0.7h-11q-5.7 0-10.3-1.7-4.4-1.8-7.2-5.7-2.6-4.1-2.6-10.5 0-4.9 2.4-9 2.5-4.1 6.6-6.5 4.3-2.6 9.4-2.6h27.4v13.5h-25.9q-1.8 0-3.2 1.2-1.2 1.1-1.2 2.9 0 1.9 1.2 3.2 1.3 1.1 3.2 1.1h11q6.3 0 10.9 2 4.6 1.8 7.1 5.8 2.6 4 2.6 10.6 0 5.1-2.6 9.3-2.5 4.2-6.7 6.7-4.3 2.4-9.6 2.4z"/>
      <path id="Layer" className="glc1" d="m153.1 32.3c6.8 0 12.2-5.4 12.2-12.2 0-6.7-5.4-12.1-12.2-12.1-6.7 0-12.1 5.4-12.1 12.1 0 6.8 5.4 12.2 12.1 12.2z"/>
    </svg>
  );
}
