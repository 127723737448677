/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputAdornment,
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
  IconButton,
  FormControlLabel,
  Divider,
  TextField,
  Box,
  Drawer,
  Stack,
  Container,
  styled,
} from "@mui/material";
import { Switch } from "@headlessui/react";

import Footer from "../components/footer";
import HeaderFull from "../components/headerFull";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useCustomer } from "../context/customerContext";
import Axios from "../config/Axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import OtpInput from "react-otp-input";
import { startsWith } from "lodash";
import PhoneInput from "react-phone-input-2";
import CustomSwitch from "../components/customSwitch";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PinComponent from "../components/pinComponent";
import LayoutStyle from "../scss/base.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../components/Loader";
import BottomNav from "../components/bottomNav";
import Tour from "../components/projectGuide/tour";
function TwoFactor() {
  const { customer, token, handleRefresh } = useCustomer();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [error, setError] = useState("");
  const [enablePin, setEnablePin] = useState(customer?.enable_pin);
  const [loading, setLoading] = useState(false);
  const [enable2fa, setEnable2fa] = useState(customer?.enable_2fa);
  const [otp, setOtp] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(false);
  const [addPhDrawer, setAddPhDrawer] = useState(false);
  const [phone, setPhone] = useState(null);
  const [dileCode, setDileCode] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [openPinDrawer, setOpenPinDrawer] = useState(false);
  const [isResetPin, setIsResetPin] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (customer?.show_guidance_first_time === true) {
      setRunTour(customer?.show_guidance_first_time);
    }
    setEnable2fa(customer?.enable_2fa);
    setEnablePin(customer?.enable_pin);
  }, [customer,customer?.enable_2fa, customer?.enable_pin]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResendEnabled(true);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearTimeout(timer);
  }, []);

  const handlePinDrawerClose = () => {
    if (!isResetPin) {
      setEnablePin(false);
    }
    setPin("");
    setConfirmPin("");
    setError("");
    setConfirm(false);
    setIsResetPin(false);
    setOpenPinDrawer(false);
  };
  const handleSubmit = () => {
    if (pin !== confirmPin) {
      setError("PIN doesnt match");
    } else {
      setLoading(true);
      if (enablePin) {
        Axios(token)
          .post(`/setCustPin/${customer?.customer_external_id}`, {
            pin: pin,
            enable_pin: true,
          })
          .then((res) => {
            setLoading(false);
            setIsResetPin(false);
            setOpenPinDrawer(false);
            handleRefresh();
          })
          .catch((e) => {
            setLoading(false);
            setError(e?.response?.data?.response?.message);
          });
      } else {
        Axios(token)
          .get(`/disablePin/${customer?.customer_external_id}`)
          .then((res) => {
            setLoading(false);
            setIsResetPin(false);
            setOpenPinDrawer(false);
          })
          .catch((e) => {
            setLoading(false);
            setError(e?.response?.data?.response?.message);
          });
      }
    }
  };

  const handleSendOtp = () => {
    setLoading(true);
    Axios(token)
      .post("/createOtp", {
        ph: customer?.phone,
      })
      .then((res) => {
        setLoading(false);
        setDrawer(true);
      })
      .catch((e) => {
        setLoading(false);
        if (e?.response?.data?.response?.message === "Phone number missing") {
          setAddPhDrawer(true);
        }
      });
    // setDrawer(true);
  };

  const handleAddPh = () => {
    setLoading(true);
    Axios(token)
      .post("/createOtp", {
        ph: phone,
      })
      .then((res) => {
        setLoading(false);
        setDrawer(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(
          e?.data?.response?.message || e?.message || "Something went wrong.",
        );
      });
  };

  const toggle2fa = () => {
    setLoading(true);
    if (enable2fa) {
      Axios(token)
        .get("/disable2fa")
        .then((res) => {
          setEnable2fa(false);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(e?.response?.data?.response?.message);
        });
    } else {
      handleSendOtp();
    }
  };
  const handleResendClick = () => {
    // Disable the button and set a timeout to re-enable it after 1 minute
    setResendEnabled(false);
    setResendTimeout(true);

    setTimeout(() => {
      setResendEnabled(true);
      setResendTimeout(false);
    }, 60000);
    handleSendOtp();
  };

  const handleEnable2fa = () => {
    setLoading(true);
    Axios(token)
      .post("/enable-2fa", {
        otp,
      })
      .then((res) => {
        setLoading(false);
        setEnable2fa(true);
        setDrawer(false);
        setAddPhDrawer(false);
        setOtp("");
        setError("");
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.data?.response?.message);
        // console.log(e?.response?.data?.response?.message);
      });
  };
  const handleChanges = (p, a) => {
    setPhone(p);
    setDileCode(a.dialCode);
  };

  const handleDisablePin = () => {
    setLoading(true);
    Axios(token)
      .get("/disablePin")
      .then((res) => {
        setLoading(false);
        setEnablePin(false);
        navigate("/confirmation");
        // handleRefresh();
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.data?.response?.message);
      });
  };

  const handleTogglePin = () => {
    if (!enablePin) {
      setPin("");
      setConfirmPin("");
      setError("");
      setEnablePin(true);
      setOpenPinDrawer(true);
    } else {
      setLoading(true);
      Axios(token)
        .get("/disablePin")
        .then((res) => {
          setLoading(false);
          handleRefresh();
        })
        .catch((e) => {
          setLoading(false);
          setError(e?.response?.data?.response?.message);
        });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Tour runTour={runTour} />
      <HeaderFull />
      <Box component={"main"} className={`${LayoutStyle.contentPart} !mt-8`}>
        <Container>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" gutterBottom>
              PIN
            </Typography>
            <Switch
              checked={enablePin}
              onChange={handleTogglePin}
              className={`${
                enablePin ? "bg-blue-600" : "bg-gray-400"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span
                className={`${
                  enablePin ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white `}
              />
            </Switch>
          </Stack>
          <Typography variant="h6" gutterBottom marginTop={1}>
            Enabling a PIN enhances security by adding an extra layer of
            verification helping prevent unauthorized access to your account.
          </Typography>
          {customer?.enable_pin && (
            <Typography
              variant="body1"
              color={"error"}
              onClick={() => {
                setPin("");
                setConfirmPin("");
                setError("");
                setIsResetPin(true);
                setOpenPinDrawer(true);
              }}
              sx={{ cursor: "pointer" }}
              textAlign={`right`}
              fontWeight={500}
            >
              Reset PIN
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />
          <Box className="security_2fa">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {enablePin ? (
                <Typography variant="h4" mb={0}>
                  2FA OTP
                </Typography>
              ) : (
                <Typography variant="h4" mb={0} sx={{ color: "grey" }}>
                  2FA OTP
                </Typography>
              )}
              <Switch
                disabled={!enablePin}
                checked={enable2fa}
                onChange={toggle2fa}
                className={`${
                  enable2fa ? "bg-blue-600" : "bg-gray-400"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    enable2fa ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white `}
                />
              </Switch>
            </Stack>
            <Typography variant="h6" gutterBottom marginTop={1}>
              Enabling 2FA OTP enhances security by requiring a unique,
              time-sensitive code for each login, making unauthorized access
              significantly more difficult .
            </Typography>
            <Typography
              variant="h6"
              fontWeight={500}
              sx={{ opacity: 0.5 }}
              gutterBottom
            >
              {customer?.phone
                ? `SMS will be delivered to ${customer.phone.replace(
                    /\d(?=\d{4})/g,
                    "*",
                  )}`
                : "Phone number not available, please enter phone number first"}
            </Typography>
            {!customer?.enable_pin && (
              <Typography variant="h6" sx={{ opacity: 0.5 }}>
                To enable 2FA OTP first set the PIN
              </Typography>
            )}
          </Box>
          <BottomNav />
        </Container>
        <PinComponent
          openPinDrawer={openPinDrawer}
          setPin={setPin}
          setConfirmPin={setConfirmPin}
          setError={setError}
          setConfirm={setConfirm}
          setOpenPinDrawer={setOpenPinDrawer}
          error={error}
          pin={pin}
          confirm={confirm}
          confirmPin={confirmPin}
          handleSetPin={handleSubmit}
          isDisableButton={!!customer?.enablePin}
          handleDisablePin={handleDisablePin}
          handleRefresh={handleRefresh}
          setEnablePin={setEnablePin}
          handlePinDrawerClose={handlePinDrawerClose}
        />
      </Box>
      <Drawer anchor="bottom" open={drawer}>
        <IconButton
          color="primary"
          onClick={() => {
            setError("");
            setOtp("");
            setDrawer(false);
            handleRefresh();
            setResendEnabled(false);
            setResendTimeout(true);
            // window.location.reload();
          }}
          aria-label="close"
          size="small"
          sx={{
            position: "absolute",
            right: 5,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box component={"div"} px={2} py={5}>
          <Typography variant="h3">2FA OTP</Typography>
          <Typography variant={"body1"}>
            {`Your OTP will be delivered to ${customer?.phone.replace(
              /\d(?=\d{4})/g,
              "*",
            )}`}
          </Typography>
          <Box component={"div"} className="clear10" />
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} type={"password"} />}
            containerStyle={{
              justifyContent: "space-between",
            }}
            inputStyle={{
              backgroundColor: "#F7F7F7",

              width: "3.5rem",
              height: "3.375rem",
              fontWeight: 700,
              fontSize: "1rem",
              borderRadius: "0.5rem",
              border: "1px solid rgba(0,104,255,.15)",
            }}
            inputType={"number"}
          />
          <Box component={"div"} className="clear10" />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {error && (
              <>
                <Typography variant="body2" color={"error"}>
                  {error}
                </Typography>
              </>
            )}

            {resendEnabled && (
              <Button
                // variant={"outlined"}
                size="small"
                color="primary"
                onClick={handleResendClick}
              >
                Resend OTP
              </Button>
            )}
          </Stack>
          <Box component={"div"} className="clear20" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleEnable2fa}
            fullWidth
          >
            Continue
          </Button>
        </Box>
      </Drawer>
      {addPhDrawer && (
        <Drawer anchor="bottom" open={addPhDrawer}>
          <IconButton
            color="primary"
            onClick={() => {
              setAddPhDrawer(false);
              handleRefresh();
              // window.location.reload();
            }}
            aria-label="close"
            size="small"
            sx={{
              position: "absolute",
              right: 5,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box component={"div"} px={2} py={5}>
            <Typography variant="hzz" className={"text-center"}>
              Enter Phone Number
            </Typography>
          </Box>
          <PhoneInput
            isValid={(inputNumber, country, countries) => {
              return countries.some((country) => {
                return (
                  startsWith(inputNumber, country.dialCode) ||
                  startsWith(country.dialCode, inputNumber)
                );
              });
            }}
            inputClass={`w-100`}
            containerClass={`phone-validator`}
            inputProps={{
              name: "phone number",
              required: true,
              autoFocus: true,
            }}
            value={phone || ""}
            placeholder={"Enter your phone number"}
            onChange={(phone, country) => handleChanges(phone, country)}
            onlyCountries={["us"]}
            disableDropdown={true}
            enableLongNumbers={true}
            country="us"
            autoFormat={true}
            countryCodeEditable={false}
            enableSearch={true}
          />

          <Box component={"div"} className="clear20" />
          <Button variant="contained" color="primary" onClick={handleAddPh}>
            Continue
          </Button>
        </Drawer>
      )}
    </>
  );
}
export default TwoFactor;
