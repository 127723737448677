import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ErrorIcon from "../Assets/Icons/errorIcon";
import SuccessIcon from "../Assets/Icons/successIcon";

export default function CustomerConfirmModal({
  isModalOpen,
  message,
  type,
  modalCallBack,
  showOk,
  showCancel,
  cancelBtnText,
  showOkText,
  CloseOnClick,
  headLine,
  confirmHandler,
  confirmButtonCallback,
  noHeadline = false,
  // showTransition,
}) {
  const [showModal, setShowModal] = useState(isModalOpen);
  useEffect(() => {
    if (isModalOpen) {
      setShowModal(true);
    }
  }, [isModalOpen]);
  const modalIsClose = () => {
    setShowModal(false);
    if (modalCallBack) {
      modalCallBack(false);
    }
  };
  const CloseOnClicks = () => {
    setShowModal(false);
    if (CloseOnClicks) {
      CloseOnClick(false);
    }
  };

  return (
    <Dialog aria-describedby="alert-dialog-slide-description" open={showModal}>
      <DialogContent>
        <Box
          component={"div"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={10}
          py={3}
        >
          {type === "Error" ? (
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ width: "5rem", height: "5rem" }}
              stroke="#f44336"
            >
              <ErrorIcon fill={"none"} />
            </SvgIcon>
          ) : (
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ width: "5rem", height: "5rem" }}
              stroke="#008626"
            >
              <SuccessIcon fill={"none"} />
            </SvgIcon>
          )}
        </Box>
        {!noHeadline && (
          <Typography
            variant="h3"
            className={type === "Error" ? "text-error" : "text-success"}
            textAlign={"center"}
          >
            {headLine || type}
          </Typography>
        )}

        {message && (
          <Typography variant="body1" textAlign={"center"} mb={3}>
            {message}
          </Typography>
        )}
        <Stack
          alignItems={"center"}
          className={`justify-center !flex-row gap-5`}
        >
          {showCancel && (
            <Button
              variant="contained"
              color={"error"}
              type="button"
              onClick={CloseOnClicks}
              size="small"
            >
              {" "}
              {cancelBtnText ? cancelBtnText : "Close"}
            </Button>
          )}
          {confirmButtonCallback && (
            <Button
              variant="contained"
              color={"success"}
              type="button"
              size="small"
              onClick={confirmHandler}
            >
              {"Agree"}
            </Button>
          )}

          {showOk && (
            <Button
              variant="contained"
              color={"primary"}
              type="button"
              size="small"
              onClick={modalIsClose}
            >
              {" "}
              {showOkText ? showOkText : "OK"}
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
