import React from "react";
import { Button, SvgIcon } from "@mui/material";
import QrCodeIcon from "../svg/qr";

function QrButtonPair({
  handleFileChange,
  handleClickOpen,
  VisuallyHiddenInput,
}) {
  return (
    <>
      <Button
        variant="contained"
        component="label"
        color="primary"
        tabIndex={-1}
        sx={{
          paddingInline: "1.25rem!important",
          width: "13.75rem",
        }}
      >
        Upload from Gallery
        <VisuallyHiddenInput
          onChange={(e) => {
            handleFileChange(e);
          }}
          type="file"
        />
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{
          paddingInline: "1.25rem!important",
          width: "13.75rem",
        }}
        onClick={handleClickOpen}
        startIcon={
          <SvgIcon viewBox="0 0 22 22">
            <QrCodeIcon />
          </SvgIcon>
        }
      >
        Share QR Code
      </Button>
    </>
  );
}

export default QrButtonPair;
